import { AxiosResponse, CancelToken } from 'axios'
import { IApiBooleanType, IApiResource, IBasePaginationParameters, IPaginationApiResponse } from '../../types'
import { IProductList } from '../products/types'
import { ICategory, IDepartment, IFamily, IShippingLocationCategoryTreeType } from '../classification/types'

/*******************************
 * SEARCH
 * ****************************/
export enum SearchSuggestionType {
    Product = 'product',
    Family = 'family',
    Category = 'category',
}

export type ISearchSuggestionParameters = IBasePaginationParameters & {
    listed_only?: IApiBooleanType
}

export type ISearchSuggestionProduct = IProductList & {
    family?: IFamily
    sub_family?: IFamily
    department?: IDepartment
    main_category_id?: null | string
    main_categories?: Array<IShippingLocationCategoryTreeType>
}
export type ISearchSuggestionFamily = Pick<IFamily, 'id' | 'label'>
export type ISearchSuggestionCategory = Pick<ICategory, 'id' | 'label' | 'type'>
export interface ISearchSuggestion extends IApiResource {
    readonly type: SearchSuggestionType
    readonly data: ISearchSuggestionProduct | ISearchSuggestionFamily | ISearchSuggestionCategory
}

export type ISearchSuggestionProductCollection = Array<ISearchSuggestionProduct>
export type ISearchSuggestionFamilyCollection = Array<ISearchSuggestionFamily>
export type ISearchSuggestionCategoryCollection = Array<ISearchSuggestionCategory>
export type ISearchSuggestionCollection = Array<ISearchSuggestion>

export type ISearchSuggestionPagination = IPaginationApiResponse<ISearchSuggestionCollection>
export type IApiSearchSuggestionResponse = AxiosResponse<ISearchSuggestionPagination>

export interface ISearchSuggestionService {
    search(parameters: ISearchSuggestionParameters, cancelToken?: CancelToken): Promise<IApiSearchSuggestionResponse>
}
