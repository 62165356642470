import React, { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import FamilyTree from '../components/Products/Sidebar/Type/FamilyTree'
import Breadcrumb, { BreadcrumbItemObject } from '../containers/Breadcrumb/Breadcrumb'
import CmsBlock from '../containers/CmsBlock/CmsBlock'
import { getPath } from '../routes'
import {
    ClassificationType,
    ICategoryTreeCollection,
    IClassificationTypes,
    IFamilyTreeCollection,
    IShippingLocationCategoryTreeType,
    TreeMode,
} from '../services/api/service/classification/types'
import { CmsBlockCodeList } from '../services/api/service/cms/types'
import { IApplicationRootState } from '../store'
import { cartsBannerShowAction } from '../store/carts/actions'
import {
    makeSelectClassificationCategoryTreeByMode,
    makeSelectClassificationFamilyTreeByMode,
} from '../store/classification/selectors'
import {
    findFamilyTreeItemBy,
    formatDisplayableFamilyTree,
    formatCatalogListFilterTree,
    findCategoryTreeItemBy,
    formatDisplayableCategoryTree,
} from '../store/classification/utils'
import rtrim from '../utils/rtrim'
import { Undefinable } from 'tsdef'
import { makeSelectCustomer } from '../store/customers/selectors'
import { ICustomer } from '../services/api/service/customers/types'
import Sidebar from '../containers/Planograms/Partial/Sidebar'
import { ICatalogListFilterTree } from '../services/api/service/core/types'
import { planogramsListResetAction } from '../store/planograms/actions'
import { removePlanogramPathToPathName } from '../store/planograms/utils'
import { PlanogramsListMode } from '../services/api/service/planograms/types'
import classNames from 'classnames'
import Planograms from '../containers/Planograms/Planograms'
import { makeSelectTreeMode } from '../store/config/selectors'
import CategoryTree from '../components/Products/Sidebar/Type/CategoryTree'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    treeMode: makeSelectTreeMode(),
})

function PlanogramsPage(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const { pathname, search } = useLocation()

    useEffect(() => {
        dispatch(cartsBannerShowAction())
        return () => {
            dispatch(planogramsListResetAction())
        }
    }, [dispatch])

    const { customer, treeMode } = useSelector<
        IApplicationRootState,
        {
            customer?: ICustomer
            treeMode: TreeMode
        }
    >(stateSelector)

    useEffect(() => {
        dispatch(cartsBannerShowAction())
    }, [dispatch])

    const selectFamilyTreeWithMode = useMemo(makeSelectClassificationFamilyTreeByMode, [])
    const currentFamilyTree: Undefinable<IFamilyTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (treeMode === TreeMode.Families) {
            return selectFamilyTreeWithMode(state, PlanogramsListMode.Default)
        }
    })

    const selectCategoryTreeWithMode = useMemo(makeSelectClassificationCategoryTreeByMode, [])
    const currentCategoryTree: Undefinable<ICategoryTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (treeMode === TreeMode.Categories) {
            return selectCategoryTreeWithMode(state, PlanogramsListMode.Default)
        }
        return undefined
    })

    const basePath: string | undefined = useMemo(() => {
        const basePathName = 'planograms'
        return generatePath(getPath(basePathName, locale), { lang: locale })
    }, [locale])

    const planogramsPageSlug: string | undefined = useMemo(() => {
        if (!basePath || !currentFamilyTree) {
            return
        }
        // récupération du familyItem
        let itemUrl = removePlanogramPathToPathName(pathname)
        itemUrl = rtrim(itemUrl.replace(basePath, ''), '/')
        return itemUrl.replace('/', '').length > 0 ? itemUrl : undefined
    }, [currentFamilyTree, basePath, pathname])

    const familyItem: IClassificationTypes | undefined = useMemo(() => {
        if (!planogramsPageSlug || planogramsPageSlug.length === 0 || !currentFamilyTree) {
            return
        }
        // récupération du familyItem
        return findFamilyTreeItemBy(currentFamilyTree, 'url', planogramsPageSlug)
    }, [currentFamilyTree, planogramsPageSlug])

    const categoryTreeItem: IShippingLocationCategoryTreeType | undefined = useMemo(() => {
        if (!planogramsPageSlug || planogramsPageSlug.length === 0 || !currentCategoryTree) {
            return
        }

        // récupération du familyItem
        return findCategoryTreeItemBy(currentCategoryTree, 'url', planogramsPageSlug)
    }, [currentCategoryTree, planogramsPageSlug])

    const tree: ICatalogListFilterTree | undefined = useMemo(() => {
        return formatCatalogListFilterTree(
            treeMode === TreeMode.Families ? familyItem || undefined : categoryTreeItem || undefined,
            treeMode === TreeMode.Families ? currentFamilyTree : currentCategoryTree,
            'id',
            treeMode
        )
    }, [treeMode, familyItem, categoryTreeItem, currentFamilyTree, currentCategoryTree])

    let seoPageTitle = formatMessage({ id: 'seo.planograms.title' })
    if (familyItem) {
        seoPageTitle = familyItem!.label
    }

    const items = useMemo(() => {
        let arr: Array<BreadcrumbItemObject> = [
            {
                label: formatMessage({ id: 'planogram.marketing_title' }),
                href: generatePath(getPath('planograms', locale), { lang: locale }),
                localize: false,
            },
        ]

        const classificationItems: Array<BreadcrumbItemObject> = []

        if (categoryTreeItem) {
            categoryTreeItem.parent_ids.forEach((parentId) => {
                const s = findCategoryTreeItemBy(currentCategoryTree!, '@id', parentId)
                if (s && s['@type'] !== ClassificationType.ShippingLocation) {
                    classificationItems.push({
                        label: s.label,
                        href: `${basePath}${s.url}`,
                        localize: false,
                    })
                }
            })
            classificationItems.push({
                label: categoryTreeItem.label,
                href: `${categoryTreeItem.url}`,
                localize: false,
            })
        }

        if (currentFamilyTree) {
            if (tree?.department) {
                const itm = findFamilyTreeItemBy(currentFamilyTree, 'id', tree.department)
                if (itm) {
                    classificationItems.push({
                        label: itm.label,
                        href: `${basePath}${itm.url}`,
                        localize: false,
                    })
                }
            }

            if (tree?.family) {
                const itm = findFamilyTreeItemBy(currentFamilyTree, 'id', tree.family)
                if (itm) {
                    classificationItems.push({
                        label: itm.label,
                        href: `${basePath}${itm.url}`,
                        localize: false,
                    })
                }
            }

            if (tree?.sub_family) {
                const itm = findFamilyTreeItemBy(currentFamilyTree, 'id', tree.sub_family)
                if (itm) {
                    classificationItems.push({
                        label: itm.label,
                        href: `${basePath}${itm.url}`,
                        localize: false,
                    })
                }
            }
        }

        // merge des tableaux
        arr = [...arr, ...classificationItems]

        return arr
    }, [formatMessage, locale, categoryTreeItem, currentFamilyTree, currentCategoryTree, basePath, tree])

    const familyTreeTitle = useMemo(() => {
        return formatMessage({ id: 'planogram.marketing_title' })
    }, [formatMessage])

    const handleClassificationTreeTitleClick = useCallback(() => {
        history.push(basePath)
    }, [basePath, history])

    const currentFamilyTreeDisplayable = useMemo(() => {
        if (treeMode === TreeMode.Families) {
            return formatDisplayableFamilyTree(currentFamilyTree || [], customer?.has_virtual_cart)
        }

        return []
    }, [currentFamilyTree, customer, treeMode])

    const currentCategoryTreeDisplayable = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return formatDisplayableCategoryTree(currentCategoryTree || [], customer?.has_virtual_cart)
        }

        return []
    }, [currentCategoryTree, customer, treeMode])

    return (
        <>
            <Helmet>
                <title>{seoPageTitle}</title>
            </Helmet>
            <div className={classNames('catalog-list-page', 'planograms-page')} id={'planograms-page'}>
                <Breadcrumb items={items} />
                <Container fluid>
                    <div className="catalog-list-page-container">
                        <Row noGutters className={'catalog-list-page-content'}>
                            <Sidebar as={Col} className="col-sidebar">
                                {treeMode === TreeMode.Families && currentFamilyTreeDisplayable ? (
                                    <FamilyTree
                                        title={familyTreeTitle}
                                        basePath={basePath}
                                        tree={currentFamilyTreeDisplayable}
                                        onTitleClick={handleClassificationTreeTitleClick}
                                    />
                                ) : undefined}

                                {treeMode === TreeMode.Categories && currentCategoryTreeDisplayable ? (
                                    <CategoryTree
                                        title={familyTreeTitle}
                                        basePath={basePath}
                                        tree={currentCategoryTreeDisplayable}
                                        onTitleClick={handleClassificationTreeTitleClick}
                                    />
                                ) : undefined}
                                <CmsBlock identifier={CmsBlockCodeList.SidebarHighlight} lazy />
                            </Sidebar>
                            <Col className={'col-catalog-list'}>
                                <Planograms baseUrl={basePath} tree={tree} />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default PlanogramsPage
