/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { ChangeEvent, memo, MouseEvent, useCallback, useMemo, useRef } from 'react'
import { Col, FormCheck, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import ShippingLocation from '../../../containers/ShippingLocation/ShippingLocation'
import { IProductCatalog } from '../../../services/api/service/products/types'
import { ProductBadgeType, ShowBadgeType } from '../../../types/productBadge'
import {
    ProductClickCallback,
    ProductCustomerStockClickCallback,
    ProductFavoriteCallback,
    ProductPickerChangeCallback,
    ProductQuantityChangeCallback,
    ProductQuantityMultipleAskedCallback,
    ProductRestockSubscriptionAlertChangeCallback,
} from '../../../types/productCallback'
import Favorite from '../../Favorite/Favorite'
import QuantitySelector, { QuantityAlertDisplayMode } from '../../QuantitySelector/QuantitySelector'
import Card from '../Card/Card'
import { CustomerInfo, Heading as ProductHeading, Packing as ProductPacking, Price as ProductPrice } from '../Partial'
import Attribute from '../Partial/Attribute'
import { default as ProductReference } from '../Partial/Reference'
import SalesmanInfo from '../Partial/SalesmanInfo'
import { CustomerInfoPart } from '../Partial/CustomerInfo'
import GeneralPriceBadge from '../../GeneralPrice/Badge'
import Brand from '../Partial/Brand'
import isUndefined from 'lodash/isUndefined'
import ArrivalInfo from '../Partial/CustomerInfo/ArrivalInfo'

export enum ProductListMode {
    Default = 'default',
    Picker = 'picker',
    Light = 'light',
    Expanded = 'expanded',
}

export interface IProps {
    tabIndex?: number
    product: IProductCatalog
    mode?: ProductListMode
    className?: string
    onProductClick?: ProductClickCallback
    onProductFavorite?: ProductFavoriteCallback
    onProductPickerChange?: ProductPickerChangeCallback
    onProductQuantityChange?: ProductQuantityChangeCallback
    onProductQuantityMultipleAsked?: ProductQuantityMultipleAskedCallback
    onProductCustomerStockClick?: ProductCustomerStockClickCallback
    onProductQuantityRestockAlertSubscriptionChange?: ProductRestockSubscriptionAlertChangeCallback
    quantitySelectorCurrentValue?: number
    quantitySelectorSaving?: boolean
    showGeneralPriceBadge?: boolean
    productCustomerInfoParts?: Array<CustomerInfoPart>
    showProductSalesmanInfo?: boolean
    quantitySelectorMultiple?: boolean
    restockAlertOutOfStockButtonOnly?: boolean
    quantitySelectorErrors?: Array<string>
    quantityCanIncrement?: boolean
    quantityCartLocked?: boolean
    showBadges?: ShowBadgeType
    excludeBadges?: Array<ProductBadgeType>
    showQuantity?: boolean
    showFavorite?: boolean
    showDetailButton?: boolean
    showPrices?: boolean
    showNotOrderableAlert?: boolean
    picked?: boolean
    disabledPicker?: boolean
}

function List({
    product,
    className,
    quantitySelectorCurrentValue,
    quantitySelectorSaving,
    quantitySelectorMultiple,
    quantitySelectorErrors,
    quantityCanIncrement,
    quantityCartLocked,
    restockAlertOutOfStockButtonOnly,
    showQuantity,
    showFavorite,
    showDetailButton,
    productCustomerInfoParts,
    showProductSalesmanInfo,
    showNotOrderableAlert,
    showPrices,
    showBadges,
    excludeBadges,
    onProductClick,
    onProductQuantityChange,
    onProductQuantityMultipleAsked,
    onProductFavorite,
    onProductCustomerStockClick,
    onProductQuantityRestockAlertSubscriptionChange,
    onProductPickerChange,
    picked,
    mode,
    disabledPicker,
    showGeneralPriceBadge,
    tabIndex,
}: IProps): JSX.Element {
    const cardRef = useRef() as React.MutableRefObject<HTMLDivElement>
    const pickerInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const handleOnQuantityChange = useCallback(
        (quantity: number, oldQuantity: number) => {
            if (onProductQuantityChange) {
                onProductQuantityChange(product, quantity, oldQuantity)
            }
        },
        [product, onProductQuantityChange]
    )

    const handleOnRestockAlertSubscriptionChange = useCallback(
        (active: boolean) => {
            if (onProductQuantityRestockAlertSubscriptionChange) {
                onProductQuantityRestockAlertSubscriptionChange(product, active)
            }
        },
        [product, onProductQuantityRestockAlertSubscriptionChange]
    )

    const handleOnQuantitySelectorClick = useCallback(
        (enabled: boolean, saving: boolean, multiple: boolean) => {
            if (multiple && quantitySelectorMultiple) {
                console.log({ multiple, quantitySelectorMultiple })
                if (onProductQuantityMultipleAsked) {
                    onProductQuantityMultipleAsked(
                        product,
                        quantitySelectorCurrentValue || 0,
                        quantitySelectorCurrentValue || 0
                    )
                }
            }
        },
        [product, quantitySelectorMultiple, onProductQuantityMultipleAsked, quantitySelectorCurrentValue]
    )

    const handlePickChange = useCallback(
        (target: HTMLInputElement) => {
            // get value
            const currentTargetValue = target.value
            const currentTargetChecked = target.checked
            if (onProductPickerChange) {
                onProductPickerChange(product, currentTargetChecked, currentTargetValue)
            }
        },
        [product, onProductPickerChange]
    )

    const handleFavoriteChange = useCallback(
        (favorite: boolean) => {
            if (onProductFavorite) {
                onProductFavorite(product, favorite)
            }
        },
        [onProductFavorite, product]
    )

    const dispatchProductEvent = useCallback(() => {
        if (mode === ProductListMode.Picker) {
            if (disabledPicker && !pickerInputRef.current.checked) {
                return
            }
            pickerInputRef.current.checked = !pickerInputRef.current.checked
            handlePickChange(pickerInputRef.current)
            return
        }

        if (onProductClick) {
            onProductClick(product)
        }
    }, [product, onProductClick, mode, pickerInputRef, handlePickChange, disabledPicker])

    const handleProductClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            dispatchProductEvent()
        },
        [dispatchProductEvent]
    )

    const handleProductExtraClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            dispatchProductEvent()
        },
        [dispatchProductEvent]
    )

    const handleProductCustomerStockClick = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            if (onProductCustomerStockClick) {
                onProductCustomerStockClick(product)
            }
        },
        [onProductCustomerStockClick, product]
    )

    const handlePickerInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            handlePickChange(e.currentTarget)
        },
        [handlePickChange]
    )

    const Attributes = useMemo(() => {
        if (!product.frontend_attributes || Object.keys(product.frontend_attributes).length === 0) {
            return <></>
        }

        return (
            <div className={'product-attributes-section'}>
                <Attribute
                    childAs={'span'}
                    parentAs={'p'}
                    attributes={product.frontend_attributes}
                    attributekey={'brand'}
                    key={'brand'}
                />
                <Attribute
                    childAs={'span'}
                    parentAs={'p'}
                    attributes={product.frontend_attributes}
                    attributekey={'size'}
                    key={'size'}
                />
                <Attribute
                    childAs={'span'}
                    parentAs={'p'}
                    attributes={product.frontend_attributes}
                    attributekey={'collection'}
                    key={'collection'}
                />
            </div>
        )
    }, [product])

    const showArrivalDate = useMemo(() => {
        return productCustomerInfoParts && productCustomerInfoParts.indexOf(CustomerInfoPart.Arrival) > -1
    }, [productCustomerInfoParts])

    // On exclut la date d'arrivage des infos à afficher en bas. On le met déjà dans l'overlay
    const customerInfoParts = useMemo(() => {
        if (!productCustomerInfoParts) {
            return productCustomerInfoParts
        }

        const arrivalIndex = productCustomerInfoParts.indexOf(CustomerInfoPart.Arrival)

        if (arrivalIndex === -1) {
            return productCustomerInfoParts
        }

        const parts = Array.from(productCustomerInfoParts)
        delete parts[arrivalIndex]

        return parts
    }, [productCustomerInfoParts])

    const OverlayOutOfStock = useMemo(() => {
        if (!product.out_of_stock || !product.can_add_stock_alert) {
            return <></>
        }

        return (
            <div className="product-layer product-layer-out-of-stock" onClick={handleProductClick}>
                <div className="product-layer-inner">
                    <p>
                        <FormattedMessage id="default.coming_soon" />
                    </p>
                    {showArrivalDate && <ArrivalInfo arrivalDate={product.arrival_date} />}
                </div>
            </div>
        )
    }, [product.out_of_stock, product.can_add_stock_alert, product.arrival_date, showArrivalDate, handleProductClick])

    const OverlayPicker = useMemo(() => {
        if (mode !== ProductListMode.Picker) {
            return <></>
        }

        return (
            <div className="product-pick">
                <FormCheck custom type={'checkbox'} id={`check-product-${product.id}`}>
                    <FormCheck.Input
                        type="checkbox"
                        onChange={handlePickerInputChange}
                        value={product.id}
                        checked={picked}
                        ref={pickerInputRef}
                        disabled={disabledPicker && !picked}
                    />
                    <FormCheck.Label htmlFor={`check-product-${product.id}`} />
                </FormCheck>
            </div>
        )
    }, [mode, product.id, handlePickerInputChange, picked, disabledPicker])

    const OverlayFavorite = useMemo(() => {
        if (!showFavorite) {
            return <></>
        }

        return <Favorite favorite={product.favorite} onFavoriteChange={handleFavoriteChange} />
    }, [product.favorite, showFavorite, handleFavoriteChange])

    const OverlayGeneralPrice = useMemo(() => {
        if (product.general_price || !showGeneralPriceBadge) {
            return <></>
        }

        return <GeneralPriceBadge className={classNames({ 'with-favorite': showFavorite })} />
    }, [showGeneralPriceBadge, product.general_price, showFavorite])

    const OverlayResume = useMemo(() => {
        if (mode === ProductListMode.Expanded) {
            return <></>
        }

        const showExtra = mode === ProductListMode.Light

        return (
            <div className={classNames('product-content', 'product-overlay')} onClick={handleProductClick}>
                <div className={'product-overlay-inner'}>
                    <ProductHeading
                        product={product}
                        showEan={showExtra}
                        showEanLabel={showExtra}
                        showReference={showExtra}
                        showReferenceLabel={showExtra}
                    />
                    {Attributes}
                    <ShippingLocation shippingLocationId={product.shipping_location} showIcon />
                    {showDetailButton && (
                        <button className={'show-detail'}>
                            <FormattedMessage id="default.see_detail" />
                        </button>
                    )}
                </div>
            </div>
        )
    }, [product, Attributes, showDetailButton, handleProductClick, mode])

    const Overlay = useMemo(() => {
        return (
            <>
                {OverlayOutOfStock}
                {OverlayPicker}
                {OverlayGeneralPrice}
                {OverlayFavorite}
                {OverlayResume}
            </>
        )
    }, [OverlayPicker, OverlayOutOfStock, OverlayFavorite, OverlayGeneralPrice, OverlayResume])

    return (
        <Card
            ref={cardRef}
            product={product}
            className={classNames(className, `product-card-mode-${mode}`, 'product-list-item', {
                'out-of-stock': product.out_of_stock,
                picked,
            })}
            overlay={Overlay}
            showBadges={showBadges}
            excludeBadges={excludeBadges}
            onImageClick={dispatchProductEvent}
        >
            <div className={'product-content'}>
                {mode === ProductListMode.Expanded && !isUndefined(product.frontend_attributes.brand) && (
                    <Brand brand={product.frontend_attributes.brand} />
                )}
                <ProductHeading
                    product={product}
                    showPacking={false}
                    showReferenceLabel={mode === ProductListMode.Expanded}
                    showReference={mode === ProductListMode.Expanded}
                    showEanLabel={mode === ProductListMode.Expanded}
                    showEan={mode === ProductListMode.Expanded}
                    showShippingLocation={mode === ProductListMode.Expanded}
                    showShippingLocationLabel={mode === ProductListMode.Expanded}
                    showAlreadyOrdered={mode !== ProductListMode.Light}
                    onProductClick={onProductClick}
                />
                <Row noGutters className={'align-items-end'}>
                    <Col xs={mode === ProductListMode.Expanded ? 12 : showQuantity ? 10 : true}>
                        <div className={'product-extra'} onClick={handleProductExtraClick}>
                            {mode !== ProductListMode.Expanded && (
                                <ProductReference reference={product.reference} showLabel labelAbbr />
                            )}
                            {showPrices && (
                                <ProductPrice
                                    price={product.price}
                                    strikePrice={product.strike_price}
                                    unitOfSale={product.unit_of_sale}
                                    retailPrice={product.retail_price}
                                    abbreviateLabels={mode !== ProductListMode.Expanded}
                                />
                            )}
                            <ProductPacking
                                packing={product.packing}
                                unitOfSale={product.unit_of_sale}
                                abbreviateLabels={mode !== ProductListMode.Expanded}
                            />
                        </div>
                    </Col>
                    {showQuantity && product.orderable && (
                        <Col xs={mode === ProductListMode.Expanded ? 24 : true}>
                            <QuantitySelector
                                unitPrice={product.price}
                                showTotalPrice={mode === ProductListMode.Expanded}
                                showPacking={mode === ProductListMode.Expanded}
                                onQuantityChange={handleOnQuantityChange}
                                onSelectorClick={handleOnQuantitySelectorClick}
                                packing={product.packing}
                                currentValue={quantitySelectorCurrentValue}
                                saving={quantitySelectorSaving}
                                multiple={quantitySelectorMultiple}
                                outOfStock={product.out_of_stock}
                                outOfStockLabel={product.coming_soon ? 'default.coming_soon' : 'product.out_of_stock'}
                                multipleButtonLabel={'product.update_quantity'}
                                arrivalStock={product.arrival_stock}
                                remainingStock={product.stock}
                                orderInProgressQuantity={product.order_in_progress_quantity}
                                canIncrement={quantityCanIncrement}
                                errors={quantitySelectorErrors}
                                popoverContainerRef={cardRef}
                                uniqId={product.id}
                                tabIndex={tabIndex}
                                quantityAlertDisplayMode={QuantityAlertDisplayMode.Tooltip}
                                restockAlertOutOfStockButtonOnly={restockAlertOutOfStockButtonOnly}
                                onRestockAlertSubscriptionChange={handleOnRestockAlertSubscriptionChange}
                                restockAlertEnabled={product.can_add_stock_alert}
                                restockAlertSubscribed={product.has_stock_alert}
                                disabled={mode === ProductListMode.Picker}
                                locked={quantityCartLocked}
                                showPrices={showPrices}
                            />
                        </Col>
                    )}
                    {showNotOrderableAlert && !product?.orderable && (
                        <p className={'text-info not-orderable-message'}>
                            <FormattedMessage id={'product.not_available_in_catalog'} />
                        </p>
                    )}
                </Row>
                {customerInfoParts && customerInfoParts.length > 0 && (
                    <CustomerInfo
                        parts={customerInfoParts}
                        arrivalStockDate={product.arrival_date}
                        customerStock={product.customer_stock}
                        customerStockDate={product.customer_stock_date}
                        onCustomerStockClick={handleProductCustomerStockClick}
                        customerLastOrderQuantity={product.last_order_quantity}
                        customerLastOrderDate={product.last_order_date}
                        customerLastOrderState={product.last_order_state}
                        orderInProgressQuantity={product.order_in_progress_quantity}
                        productId={product.id}
                    />
                )}
                {showProductSalesmanInfo && (
                    <SalesmanInfo
                        stats={true}
                        productId={product.id}
                        arrivalStock={product.arrival_stock}
                        arrivalStockDate={product.arrival_date}
                        stock={product.stock}
                        currentValue={quantitySelectorCurrentValue}
                    />
                )}
            </div>
        </Card>
    )
}

List.defaultProps = {
    cartItem: null,
    quantitySelectorCurrentValue: 0,
    quantitySelectorSaving: false,
    quantitySelectorMultiple: false,
    quantityCanIncrement: false,
    quantityCartLocked: false,
    productCustomerInfoParts: undefined,
    showProductSalesmanInfo: false,
    showBadges: true,
    showQuantity: true,
    showFavorite: true,
    showPrices: true,
    showDetailButton: true,
    picked: false,
    mode: ProductListMode.Default,
    disabledPicker: false,
    showGeneralPriceBadge: false,
    showNotOrderableAlert: false,
    restockAlertOutOfStockButtonOnly: false,
} as Partial<IProps>

export default memo(List)
