import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { ContainerState as ProductState, IProductGridPublicDetailState } from './types'

const selectProductGridPublicDomain = (state: IApplicationRootState): ProductState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.productGridPublic || initialState
}

const selectProductGridPublicDetail = (state: IApplicationRootState): IProductGridPublicDetailState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.productGridPublic.detail || initialState.detail
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Product
 */

const makeSelectProductGridPublic = () =>
    createSelector(selectProductGridPublicDomain, (substate) => {
        return substate
    })

const makeSelectProductGridPublicId = () =>
    createSelector(selectProductGridPublicDomain, (substate) => {
        return substate.publicGridId
    })

const makeSelectProductGridPublicDetailFetching = () =>
    createSelector(selectProductGridPublicDomain, (substate) => {
        return substate.detail.fetching
    })

const makeSelectProductGridPublicDetailError = () =>
    createSelector(selectProductGridPublicDomain, (substate) => {
        return substate.detail.error
    })

const makeSelectProductGridPublicDetail = () =>
    createSelector(selectProductGridPublicDetail, (substate) => {
        return substate.detail
    })

export default makeSelectProductGridPublic
export {
    selectProductGridPublicDomain,
    makeSelectProductGridPublicId,
    makeSelectProductGridPublicDetail,
    makeSelectProductGridPublicDetailFetching,
    makeSelectProductGridPublicDetailError,
}
