/*
 *
 * Public Grid actions
 *
 */

import { action } from 'typesafe-actions'

import { IProductGridPublicProcessPayload } from './types'

import ActionTypes from './constants'

import { IAppErrorTypes } from '../app/types'
import { IApiProductGridPublicResponse } from '../../services/api/service/product-grid-publics/types'

// GLOBAL
export const productGridPublicResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

// DETAIL
export const productGridPublicDetailProcessAction = (publicGridId: string) =>
    action<ActionTypes.DETAIL_PROCESS_ACTION, IProductGridPublicProcessPayload>(ActionTypes.DETAIL_PROCESS_ACTION, {
        publicGridId: publicGridId,
    })
export const productGridPublicDetailSuccessAction = (response: IApiProductGridPublicResponse) =>
    action<ActionTypes.DETAIL_SUCCESS_ACTION, { response: IApiProductGridPublicResponse }>(
        ActionTypes.DETAIL_SUCCESS_ACTION,
        {
            response,
        }
    )
export const productPublicGridDetailFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.DETAIL_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.DETAIL_FAILURE_ACTION, { error })
export const productGridPublicDetailResetAction = () =>
    action<ActionTypes.DETAIL_RESET_ACTION>(ActionTypes.DETAIL_RESET_ACTION)
