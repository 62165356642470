import { CollectionMap } from '../../../../types/common'
import { ApiListOrderDirection, IApiBooleanType, IApiResource, IBasePaginationParameters } from '../../types'
import { IProductListOrder } from '../products/types'

export interface IBaseCatalogImage {
    readonly placeholder: string
}

export interface ICatalogListImage extends IBaseCatalogImage {
    readonly list: string
}

export interface ICatalogDetailImage extends IBaseCatalogImage {
    readonly thumb: string
    readonly large: string
    readonly zoom: string
}

export type ICatalogImageCollection<T extends IBaseCatalogImage> = Array<T>

/*******************************
 * CATALOG Attributes
 * ****************************/
export interface ICatalogAttributeValue {
    readonly id: number
    readonly label?: string | null
    readonly color?: string | null
    readonly image?: string | null
}

export interface ICatalogGroupAttributeValue {
    readonly id: number
    readonly label: string
    readonly products: Array<string>
    readonly color?: string | null
    readonly image?: string | null
}

export type ICatalogGroupAttributeValueIdentifiers = CollectionMap<number>

export interface ICatalogGroupAttribute<T extends ICatalogAttributeValue = ICatalogAttributeValue> {
    readonly attribute: string
    readonly name: string
    readonly values: Array<T>
}

export type ICatalogGroupAttributeCollection<T extends ICatalogAttributeValue = ICatalogAttributeValue> = Array<T>

export interface ICatalogCustomAttribute {
    readonly label: string
    readonly value: string
}

export type ICatalogCustomAttributeCollection<T extends ICatalogCustomAttribute = ICatalogCustomAttribute> = Array<T>

/*******************************
 * CATALOG LIST PARAMS & RESPONSE
 * ****************************/
export enum CatalogListQueryName {
    Page = 'page',
    ItemsPerPage = 'itemsPerPage',
    WithFilters = 'with_filters',
}

/*******************************
 * CATALOG FILTERS
 * ****************************/
export enum CatalogListFilterMode {
    Option = 'option',
    Order = 'order',
    Input = 'option',
    Range = 'range',
    Boolean = 'boolean',
}

export enum CatalogUnitType {
    Meter = 'meter',
    Unit = 'unit',
}

export type ICatalogListFilterOption = {
    readonly id: number | string
    readonly label: string
    readonly image?: string
    readonly color?: string
}

export type ICatalogListFilterOptionCollection = Array<ICatalogListFilterOption>

export type ICatalogListFilterRangeValue = {
    min?: number | string
    max?: number | string
}

export interface ICatalogListFilter extends Omit<IApiResource<'Filter'>, '@context'> {
    readonly code: string
    readonly label: string
    readonly options: ICatalogListFilterOptionCollection
    readonly mode: CatalogListFilterMode
    readonly public: boolean
    readonly static: boolean
    readonly multiple?: boolean
    readonly searchable?: boolean
    readonly translatable: boolean
    readonly autoValidate: boolean
    readonly position: number
}

export type IApiCatalogListFilterCollection = Array<ICatalogListFilter>
export type ICatalogListFilterCollection = CollectionMap<ICatalogListFilter>

/*******************************
 * CATALOG LIST PARAMS & RESPONSE
 * ****************************/
export type ICatalogListUserFilterValue = string | undefined | number | object | Array<string>
export type ICatalogListUserFilterCollection = CollectionMap<ICatalogListUserFilterValue>
export type ICatalogListUserSelectionCollection = Array<string>

export interface ICatalogListFilterTree {
    family?: string
    sub_family?: string
    department?: string
    shipping_location?: string
    category?: string
    tree_category?: string
}

// objet de tri
export interface ICatalogListOrder<T = string> {
    direction: ApiListOrderDirection
    type: T
}

export interface ICatalogListParameters extends IBasePaginationParameters {
    tree?: ICatalogListFilterTree
    filters?: ICatalogListUserFilterCollection | undefined
    with_filters?: IApiBooleanType | undefined
}

export type CatalogListFilterProps = {
    filters?: IApiCatalogListFilterCollection
    state?: {
        filters: CollectionMap<string | undefined | number | object | Array<string>>
        order?: IProductListOrder
    }
}
