/**
 *
 * MegaItem
 *
 */
import classNames from 'classnames'
import chunk from 'lodash/chunk'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
    ClassificationType,
    ICategoryTree,
    IShippingLocation,
} from '../../../../services/api/service/classification/types'
import { LinkItemCallbacks } from '../../type'
import LinkItem from '../LinkItem'

type IProps = LinkItemCallbacks & {
    item: IShippingLocation
    basePath?: string
    selectedCategorySetter: (category?: ICategoryTree) => void
}

function ShippingLocationItem({
    item,
    basePath,
    selectedCategorySetter,
    onMouseEnter,
    onClick,
    onMouseLeave,
}: IProps): JSX.Element {
    const [active, setActive] = useState<boolean>(false)
    const [entered, setEntered] = useState<boolean>(false)
    const location = useLocation()
    useEffect(() => {
        setActive(location.pathname.indexOf(item.url) > -1)
    }, [location.pathname, item, setActive])

    const handleMouseClick = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(false)
            const category = item as ICategoryTree
            selectedCategorySetter(category)
            if (onClick) {
                onClick(e, item, itemType)
            }
        },
        [selectedCategorySetter, onClick]
    )

    const handleMouseEnter = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(true)
            const category = item as ICategoryTree
            selectedCategorySetter(category)
            if (onMouseEnter) {
                onMouseEnter(e, item, itemType)
            }
        },
        [selectedCategorySetter, onMouseEnter]
    )

    const handleMouseLeave = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(false)
            selectedCategorySetter(undefined)
            if (onMouseLeave) {
                onMouseLeave(e, item, itemType)
            }
        },
        [selectedCategorySetter, onMouseLeave]
    )

    const isShippingLocationChildren =
        item.category_tree!.filter((subItem) => subItem['@type'] === ClassificationType.ShippingLocation).length > 0

    // on eclate les enfants
    const subShippingLocations: Array<IShippingLocation> = item.category_tree!.filter(
        (subItem): subItem is IShippingLocation => subItem['@type'] === ClassificationType.ShippingLocation
    )
    const subCategories: Array<ICategoryTree> = item.category_tree!.filter(
        (subItem): subItem is ICategoryTree => subItem['@type'] === ClassificationType.Category
    )
    const formattedSubCategories = chunk(subCategories, 4)

    return (
        <div
            className={classNames('section-item', `section-item-shipping-location`, {
                'has-children': item.category_tree && item.category_tree.length > 0,
                'has-uniq-child': item.category_tree && item.category_tree.length === 1,
                'has-multiple-child': item.category_tree && item.category_tree.length > 1,
                active: active,
                entered: entered,
            })}
        >
            <div className={'section-item-heading'}>
                <LinkItem
                    basePath={basePath}
                    item={item}
                    itemType={'location'}
                    className={'section-nav-item'}
                    onClick={handleMouseClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                />
            </div>
            <div
                className={classNames('section-item-content', {
                    'section-item-shipping-location': isShippingLocationChildren,
                })}
            >
                {subShippingLocations &&
                    subShippingLocations.map((subItem) => (
                        <ShippingLocationItem
                            basePath={basePath}
                            key={subItem['@id']}
                            item={subItem as IShippingLocation}
                            selectedCategorySetter={selectedCategorySetter}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleMouseClick}
                        />
                    ))}
                {formattedSubCategories &&
                    formattedSubCategories.map((subItems, index) => {
                        const SubSubItems = subItems.map((subItem) => (
                            <LinkItem
                                basePath={basePath}
                                item={subItem}
                                showSubItems={false}
                                key={subItem['@id']}
                                onClick={handleMouseClick}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            />
                        ))
                        return (
                            <div key={`sp_sub_group_${item['@id']}_${index}`} className={'sub-families-group'}>
                                {SubSubItems}
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

ShippingLocationItem.defaultProps = {
    basePath: '',
    selectedCategorySetter: () => {},
} as Partial<IProps>

export default memo(ShippingLocationItem)
