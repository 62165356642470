import { AxiosResponse } from 'axios'
import { IApiBooleanType, IApiResource, ICollectionApiResponse } from '../../types'
import { CollectionMap } from '../../../../types/common'
import { IProductList, IProductListPersistParameters } from '../products/types'

/************************************************************************************
 * Shipping Location / Department / Family
 *************************************************************************************/
export interface IAllowMassAddToCart {
    allow_mass_add_to_cart?: boolean
}

export enum ParentFamilyType {
    ShippingLocation = 'shipping_location',
}

export enum FamilyType {
    Department = 'department',
    Family = 'family',
    SubFamily = 'sub_family',
}

export enum ClassificationType {
    ShippingLocation = 'ShippingLocation',
    Family = 'Family',
    Category = 'Category',
}

export enum TreeMode {
    Families = 'families',
    Categories = 'categories',
}

export interface IBaseClassification extends Omit<IApiResource<ClassificationType>, '@context'> {
    readonly id: string
    readonly label: string
    readonly full_name?: string
    readonly slug: string
    readonly url: string // ajouté par nos soins
    readonly parent_ids: Array<string> // ajouté par nos soins
    readonly parent_labels: Array<string> // ajouté par nos soins
}

export interface IBaseClassificationFamily extends IBaseClassification {
    readonly type: FamilyType
    readonly sub_families: Array<IFamily | ISubFamily>
}

export interface IFamilyChild extends IBaseClassificationFamily {
    readonly type: FamilyType.Family | FamilyType.SubFamily
}

export interface ISubFamily extends IFamilyChild {
    readonly type: FamilyType.SubFamily
}

export interface IFamily extends IFamilyChild {
    readonly type: FamilyType.Family
}

export interface IDepartmentPicture {
    readonly placeholder: string
    readonly normal: string
}

export interface IDepartment extends IBaseClassificationFamily {
    readonly type: FamilyType.Department
    readonly inspiration_image?: IDepartmentPicture
}

export type IShippingLocationTreeItemType = IDepartment | IShippingLocation
export type IShippingLocationTree = Array<IShippingLocationTreeItemType>
export interface IShippingLocationPicture {
    readonly placeholder: string
    readonly default: string
}

export type ICategoryTree = IApiResource & {
    readonly id: string
    readonly label: string
    readonly type: string
    readonly url: string // ajouté par nos soins
    readonly parent_ids: Array<string> // ajouté par nos soins
    readonly parent_labels: Array<string> // ajouté par nos soins
    readonly slug: string
    readonly sub_categories: ICategoryTree[]
    readonly inspiration_image?: {
        readonly placeholder: string
        readonly normal: string
    }
}
export type IShippingLocationCategoryTreeType = ICategoryTree | IShippingLocation
export type IShippingLocationCategoryTree = Array<IShippingLocationCategoryTreeType>

export interface IShippingLocation extends IBaseClassification {
    readonly '@type': ClassificationType.ShippingLocation
    readonly full_name: string
    readonly tree: IShippingLocationTree // TODO: passer en optional puisque dépend du mode envoyé par l'API
    readonly category_tree?: IShippingLocationCategoryTree
    readonly image?: IShippingLocationPicture
    readonly best_sellers?: Array<IProductList>
    readonly virtual?: boolean
}

export type IShippingLocationLight = Omit<
    IShippingLocation,
    'tree' | 'image' | 'best_sellers' | 'slug' | 'url' | 'parent_ids'
>

export type IShippingLocationCollection = Array<IShippingLocation>
export type IFamilyTypes = IShippingLocation | IDepartment | IFamily | ISubFamily
export type IFamilyTreeCollection = Array<IShippingLocation>
export type IFamiliesTreeResponse = ICollectionApiResponse<IFamilyTreeCollection>
export type IApiFamiliesTreeResponse = AxiosResponse<IFamiliesTreeResponse>

export type ICategoryTreeCollection = Array<IShippingLocation>
export type ICategoryTreesTreeResponse = ICollectionApiResponse<ICategoryTreeCollection>
export type IApiCategoryTreesResponse = AxiosResponse<ICategoryTreesTreeResponse>

export type IClassificationParameters = Pick<IProductListPersistParameters, 'listed_only'> &
    CollectionMap<IApiBooleanType | undefined>

/************************************************************************************
 * Attributes
 *************************************************************************************/
export enum AttributeDefinitionType {
    Select = 'select',
    Integer = 'integer',
}

export interface IFamilyAttributeDefinition extends IApiResource, IAllowMassAddToCart {
    code: string
    name: string
    swatch: boolean
    show_preview: boolean
    type: AttributeDefinitionType
}

export type IAttributeDefinitionCollection = Array<IFamilyAttributeDefinition>
export type IAttributeDefinitionResponse = ICollectionApiResponse<IAttributeDefinitionCollection>
export type IApiAttributeDefinitionResponse = AxiosResponse<IAttributeDefinitionResponse>

/************************************************************************************
 * Category
 *************************************************************************************/
export interface ICategory extends IBaseClassification, IAllowMassAddToCart {
    readonly urls: CollectionMap<string> | null
    readonly type: string
}

export type ICategoryResponse = AxiosResponse<ICategory>
export type IApiCategoryResponse = ICategoryResponse
/************************************************************************************
 * Menu Item
 *************************************************************************************/
export enum MenuItemType {
    Tree = 'tree',
    Category = 'category',
}

export enum MenuItemResourceType {
    Tree = 'TreeMenuItem',
    Category = 'CategoryMenuItem',
    ArrivalStocks = 'ArrivalStocksMenuItem',
}

export interface IMenuItem extends Omit<IApiResource<MenuItemResourceType>, '@context'> {
    readonly type: MenuItemType
    readonly parent?: IMenuItem
    readonly children?: Array<IMenuItem>
    readonly label: string
    readonly badge?: string
    readonly category: string
    readonly urls: CollectionMap<string>
    readonly url: string // ajouté par nos soins
    readonly parent_ids: Array<string> // ajouté par nos soins
}

export type IMenuItemCollection = Array<IMenuItem>
export type IMenuResponse = ICollectionApiResponse<IMenuItemCollection>
export type IApiMenuResponse = AxiosResponse<IMenuResponse>
export type IClassificationBestSellerResponse = ICollectionApiResponse<IShippingLocationCollection>
export type IApiClassificationBestSellerResponse = AxiosResponse<IClassificationBestSellerResponse>
export type IClassificationTypes = IShippingLocation | IDepartment | IFamily | ISubFamily | ICategory
/************************************************************************************
 * Global
 *************************************************************************************/

export interface IClassificationService {
    categoriesTree(params?: IClassificationParameters): Promise<IApiCategoryTreesResponse>
    familiesTree(params?: IClassificationParameters): Promise<IApiFamiliesTreeResponse>
    menu(params?: IClassificationParameters): Promise<IApiMenuResponse>
    attributes(): Promise<IApiAttributeDefinitionResponse>
    getCategoryBySlug(slug: string): Promise<ICategoryResponse>
    /**
     * @deprecated Deprecated since 04/09/2020
     */
    getBestSellers(params?: IClassificationParameters): Promise<IApiClassificationBestSellerResponse>
}
