/*
 *
 * CreateOrder
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Modal, Overlay, Popover } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert/dist'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Button from '../../components/Buttons/Button'
import CreateOrderForm from '../../components/Carts/CreateOrderForm'
import FlatIcon from '../../components/Icon/FlatIcon'
import {
    ICartCollection,
    ICartLight,
    ICartLightCollection,
    ICartMode,
    ICartValidationMode,
} from '../../services/api/service/carts/types'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    TreeMode,
} from '../../services/api/service/classification/types'
import { ICustomer, ICustomerMinimumAmountMode } from '../../services/api/service/customers/types'
import { IApplicationRootState } from '../../store'
import {
    cartLoadCartProcessAction,
    cartLockerProcessAction,
    cartLockerResetAction,
    cartSaveToOrderProcessAction,
    cartSaveToOrdersProcessAction,
    cartSaveToOrdersResetAction,
    cartsLockerProcessAction,
    cartsLockerResetAction,
} from '../../store/carts/actions'
import {
    makeSelectCartMode,
    makeSelectCartsCreateOrdersData,
    makeSelectCartsCreateOrdersErrors,
    makeSelectCartsCreateOrdersFetching,
    makeSelectCartsList,
    makeSelectCartsLockerErrors,
    makeSelectCartsLockerFetching,
    makeSelectCartsLockerSuccess,
    makeSelectCartValidationMode,
    makeSelectMainCartsList,
} from '../../store/carts/selectors'
import {
    ICartsLockerErrorsType,
    ICreateOrderDataCollectionType,
    ICreateOrderErrorsType,
    StrictCartMode,
} from '../../store/carts/types'

import { CollectionMap } from '../../types/common'
import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../../store/classification/selectors'

import { makeSelectCustomer } from '../../store/customers/selectors'

import {
    cartHasErrors,
    cartIsEmpty,
    cartsAllEmpties,
    cartsHasErrors,
    getMinimumOrderAmount,
    isMinimumAmountActive,
    isMinimumAmountReached,
    isMinimumAmountRequired,
} from '../../store/carts/utils'

import { findCustomerStore } from '../../store/customers/utils'

import AlertMessages from './AlertMessages'
import CreateOrderErrors from './CreateOrderErrors'
import Portal from '../../components/Portal/Portal'
import { IOrderMode } from '../../services/api/service/orders/types'
import { makeSelectOrdersMode } from '../../store/orders/selectors'
import { makeSelectPaymentModes } from '../../store/payment-modes/selectors'
import { IPaymentModeList } from '../../services/api/service/payment-modes/types'
import { makeSelectTreeMode } from '../../store/config/selectors'

interface IProps {
    cartId?: string
    child?: ICartLight
    mainCart: boolean
    customer: ICustomer
    minimumAmountMode: ICustomerMinimumAmountMode
    onCartEditClick?: () => void
    global?: boolean
    cartNumber?: number
    store?: ICustomer
    buttonLabel?: string
    buttonSize?: 'sm' | 'lg'
}

const stateSelector = createStructuredSelector<any, any>({
    createOrdersData: makeSelectCartsCreateOrdersData(),
    createOrdersFetching: makeSelectCartsCreateOrdersFetching(),
    createOrdersErrors: makeSelectCartsCreateOrdersErrors(),
    lockersFetching: makeSelectCartsLockerFetching(),
    lockersErrors: makeSelectCartsLockerErrors(),
    lockersSuccess: makeSelectCartsLockerSuccess(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    carts: makeSelectCartsList(),
    mainCarts: makeSelectMainCartsList(),
    customer: makeSelectCustomer(),
    cartMode: makeSelectCartMode(),
    cartValidationMode: makeSelectCartValidationMode(),
    orderMode: makeSelectOrdersMode(),
    paymentModes: makeSelectPaymentModes(),
    treeMode: makeSelectTreeMode(),
})

function CreateOrder({
    cartId,
    store,
    customer,
    mainCart,
    cartNumber,
    buttonSize,
    buttonLabel,
    minimumAmountMode,
    global,
    child,
    onCartEditClick,
}: IProps): JSX.Element {
    const { formatMessage, formatNumber } = useIntl()
    const dispatch = useDispatch()
    const target = useRef<HTMLButtonElement>(null)
    const [show, setShow] = useState<boolean>(false)
    const [showLockSweetAlert, setShowLockSweetAlert] = useState<boolean>(false)
    const [showLockSweetAlertSuccess, setShowLockSweetAlertSuccess] = useState<boolean>(false)
    const [showLockSweetAlertError, setShowLockSweetAlertError] = useState<boolean>(false)
    const [showAfterLoad, setShowAfterLoad] = useState<boolean>(false)
    const [showAfterLoadHasErrors, setShowAfterLoadHasErrors] = useState<boolean>(false)
    const [showTooltip, setShowTooltip] = useState<boolean>(false)
    const {
        createOrdersData,
        createOrdersErrors,
        createOrdersFetching,
        familyTree,
        categoryTree,
        treeMode,
        carts,
        mainCarts,
        cartMode,
        orderMode,
        cartValidationMode,
        lockersErrors,
        lockersFetching,
        lockersSuccess,
        paymentModes,
    } = useSelector<
        IApplicationRootState,
        {
            createOrdersData: ICreateOrderDataCollectionType
            createOrdersFetching: CollectionMap<boolean>
            createOrdersErrors: ICreateOrderErrorsType
            familyTree: IFamilyTreeCollection
            categoryTree: ICategoryTreeCollection
            treeMode: TreeMode
            carts: ICartCollection
            mainCarts: ICartCollection
            cartMode: StrictCartMode
            orderMode: IOrderMode
            cartValidationMode: ICartValidationMode
            lockersFetching: CollectionMap<boolean>
            lockersSuccess: CollectionMap<boolean>
            lockersErrors: ICartsLockerErrorsType
            paymentModes: Array<IPaymentModeList>
        }
    >(stateSelector)
    const loading = useSelector<IApplicationRootState, boolean>((state) => {
        return typeof state.carts.load[cartId] === 'undefined' ? false : state.carts.load[cartId].fetching
    })
    // récupération des paniers
    const selectedCarts = useMemo(() => {
        let selected: ICartCollection | ICartLightCollection = []

        if (cartId) {
            const selectedCart = carts.find((cart) => cart['@id'] === cartId)
            if (selectedCart) {
                selected = [selectedCart]
            } else if (child) {
                selected = [child]
            }
        } else if (mainCart) {
            selected = mainCarts
        } else {
            const mainCartIds = mainCarts.map((main) => main['@id'])
            carts.forEach((c) => {
                if (mainCartIds.indexOf(c['@id']) === -1) {
                    selected.push(c)
                }
            })
        }

        if (selected.length > 0) {
            return selected
        }

        return undefined
    }, [carts, cartId, mainCart, mainCarts, child])

    const tree = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return categoryTree
        }

        return familyTree
    }, [categoryTree, familyTree, treeMode])

    // on filtre et ne récupère que les paniers valides
    const validCarts = useMemo(() => {
        if (selectedCarts && selectedCarts.length > 0) {
            return selectedCarts.filter((select) => {
                return (
                    (!select.locked || customer.cart_validation_mode === ICartValidationMode.Default) &&
                    !cartHasErrors(select) &&
                    !cartIsEmpty(select) &&
                    (!isMinimumAmountActive(select, customer, cartMode, minimumAmountMode) ||
                        isMinimumAmountReached(select, customer, cartMode, minimumAmountMode) ||
                        !isMinimumAmountRequired(select, customer, cartMode, minimumAmountMode))
                )
            }) as ICartCollection
        }
        return undefined
    }, [selectedCarts, customer, cartMode, minimumAmountMode])

    // est ce que l'on a un panier avec des erreurs
    const hasErrors: boolean = useMemo(() => {
        if (!selectedCarts) {
            return false
        }
        return cartsHasErrors(selectedCarts)
    }, [selectedCarts])

    // est ce que l'on a tous les paniers de vide
    const allIsEmpty = useMemo(() => {
        return !selectedCarts ? true : cartsAllEmpties(selectedCarts)
    }, [selectedCarts])

    // est-ce que au moins l'un des paniers
    const hasReachedCarts = useMemo(() => {
        // if (!selectedCarts || cartsAllEmpties(selectedCarts)) {
        if (!selectedCarts) {
            return true
        }
        let active = false
        for (const idx1 in selectedCarts) {
            const selectedCart = selectedCarts[idx1]
            if (isMinimumAmountActive(selectedCart, customer, cartMode, minimumAmountMode)) {
                active = true
                break
            }
        }

        if (!active) {
            return true
        }

        let reached = false
        for (const idx1 in selectedCarts) {
            const selectedCart = selectedCarts[idx1]
            if (isMinimumAmountReached(selectedCart, customer, cartMode, minimumAmountMode)) {
                reached = true
                break
            }
        }

        return reached
    }, [selectedCarts, customer, minimumAmountMode, cartMode])

    // est ce que les paniers ont tous atteint le minimum d'achat (or mode client)
    const allReachedCarts = useMemo(() => {
        // if (!selectedCarts || cartsAllEmpties(selectedCarts)) {
        if (!selectedCarts) {
            return true
        }
        let active = false
        for (const idx1 in selectedCarts) {
            const selectedCart = selectedCarts[idx1]
            if (isMinimumAmountActive(selectedCart, customer, cartMode, minimumAmountMode)) {
                active = true
                break
            }
        }

        if (!active) {
            return true
        }

        let reached = true
        for (const idx1 in selectedCarts) {
            const selectedCart = selectedCarts[idx1]
            if (!isMinimumAmountReached(selectedCart, customer, cartMode, minimumAmountMode)) {
                reached = false
                break
            }
        }

        return reached
    }, [selectedCarts, customer, cartMode, minimumAmountMode])

    const buttonVariant = useMemo(() => {
        return validCarts && validCarts.length > 0 ? 'success' : 'secondary'
    }, [validCarts])

    const buttonDisabled = useMemo(() => {
        return !validCarts || validCarts.length === 0
    }, [validCarts])

    const createOrderFetching = useMemo(() => {
        if (cartId) {
            return typeof createOrdersFetching[cartId] === 'boolean' ? createOrdersFetching[cartId] : false
        }
        return Object.values(createOrdersFetching).reduce((sum, next) => sum || next, false)
    }, [cartId, createOrdersFetching])

    const cartLockerFetching = useMemo(() => {
        if (cartId) {
            return typeof lockersFetching[cartId] === 'boolean' ? lockersFetching[cartId] : false
        }
        return Object.values(lockersFetching).reduce((sum, next) => sum || next, false)
    }, [cartId, lockersFetching])

    const cartLockerErrors = useMemo(() => {
        if (cartValidationMode !== ICartValidationMode.Lock) {
            return []
        }
        return typeof lockersErrors[cartId || 'all'] !== 'undefined' ? lockersErrors[cartId || 'all'] : []
    }, [cartId, lockersErrors, cartValidationMode])

    const cartLocked = useMemo(() => {
        if (!selectedCarts || selectedCarts?.length === 0) {
            return false
        }

        if (selectedCarts?.length === 1) {
            return selectedCarts[0].locked
        }

        for (const idx in selectedCarts) {
            if (!selectedCarts[idx].locked) {
                return false
            }
        }

        return true
    }, [selectedCarts])

    const modalTitle = useMemo(() => {
        if (loading) {
            return formatMessage({ id: 'default.loading' })
        } else if (store && validCarts && validCarts.length === 1) {
            return formatMessage({ id: 'cart.place_store_order' }, { business_name: store.business_name })
        } else if (typeof cartNumber === 'number') {
            return formatMessage({ id: `cart.validate_single_${orderMode}` }, { number: cartNumber })
        }

        return formatMessage({ id: `cart.validate_all_${orderMode}` }, { total: validCarts?.length || 0 })
    }, [store, validCarts, formatMessage, loading, cartNumber, orderMode])

    const showModalButtonLabel = useMemo(() => {
        if (buttonLabel) {
            return buttonLabel
        }
        if (cartValidationMode === ICartValidationMode.Lock) {
            return formatMessage({ id: 'cart.lock_all' }, { total: selectedCarts?.length || 0 })
        }
        return formatMessage({ id: `cart.validate_all_${orderMode}` }, { total: selectedCarts?.length || 0 })
    }, [buttonLabel, formatMessage, selectedCarts, cartValidationMode, orderMode])

    const handleOnHide = useCallback(() => {
        setShow(false)
    }, [setShow])

    const handleShowModal = useCallback(() => {
        if (buttonDisabled) {
            return
        }

        if (typeof cartId === 'string') {
            // on regarde si c'est le child ou pas
            if (
                typeof selectedCarts === 'object' &&
                selectedCarts.length === 1 &&
                typeof child === 'object' &&
                selectedCarts.indexOf(child) !== -1
            ) {
                dispatch(cartLoadCartProcessAction(cartId, mainCart))
                setShowAfterLoad(true)
                return
            }
        }
        setShow(typeof validCarts === 'object' && validCarts.length > 0)
    }, [dispatch, buttonDisabled, validCarts, selectedCarts, cartId, child, mainCart, setShow, setShowAfterLoad])

    const handleLockCart = useCallback(() => {
        if (buttonDisabled) {
            return
        }
        setShowLockSweetAlert(true)
    }, [buttonDisabled, setShowLockSweetAlert])

    const handleSweetAlertLockCancel = useCallback(() => {
        setShowLockSweetAlert(false)
    }, [setShowLockSweetAlert])

    const handleSweetAlertLockConfirm = useCallback(() => {
        setShowLockSweetAlert(false)
        if (!validCarts || validCarts.length === 0) {
            return
        }

        if (validCarts.length === 1) {
            const cart = validCarts[0]
            dispatch(cartLockerProcessAction(cart['@id'], true, true))
        } else {
            const cartIds = validCarts.map((cart) => cart['@id'])
            dispatch(cartsLockerProcessAction(cartIds, true))
        }
    }, [setShowLockSweetAlert, validCarts, dispatch])

    const handleSweetAlertLockSuccessFailClick = useCallback(() => {
        setShowLockSweetAlertError(false)
        setShowLockSweetAlertSuccess(false)
        if (!selectedCarts || selectedCarts.length === 0) {
            return
        }

        if (selectedCarts.length === 1) {
            const cart = selectedCarts[0]
            dispatch(cartLockerResetAction(cart['@id']))
        } else {
            const cartIds = selectedCarts.map((cart) => cart['@id'])
            dispatch(cartsLockerResetAction(cartIds))
        }
    }, [setShowLockSweetAlertError, setShowLockSweetAlertSuccess, dispatch, selectedCarts])

    const handleClick = useCallback(() => {
        if (buttonDisabled) {
            return
        }

        if (cartValidationMode === ICartValidationMode.Default) {
            handleShowModal()
        } else {
            handleLockCart()
        }
    }, [buttonDisabled, cartValidationMode, handleShowModal, handleLockCart])

    const handleOnFormReset = useCallback(() => {
        dispatch(cartSaveToOrdersResetAction())
        setShow(false)
    }, [setShow, dispatch])

    const handleFormSubmit = useCallback(
        (data: ICreateOrderDataCollectionType, carts: ICartCollection): void => {
            if (carts.length === 1) {
                const cart = carts[0]
                dispatch(cartSaveToOrderProcessAction(cart['@id'], data[cart['@id']], true, true))
            } else {
                const cartIds = carts.map((cart) => cart['@id'])
                dispatch(cartSaveToOrdersProcessAction(cartIds, data))
            }
        },
        [dispatch]
    )
    const handleValidateButtonMouseEnter = useCallback((): void => {
        if (!buttonDisabled) {
            setShowTooltip(false)
            return
        }
        setShowTooltip(true)
    }, [setShowTooltip, buttonDisabled])
    const handleValidateButtonMouseLeave = useCallback((): void => {
        setShowTooltip(false)
    }, [setShowTooltip])

    const handleSweetAlertAfterLoadErrorsCancel = useCallback(() => {
        setShowAfterLoadHasErrors(false)
        setShowAfterLoad(false)
    }, [setShowAfterLoadHasErrors, setShowAfterLoad])

    const handleSweetAlertAfterLoadErrorsConfirm = useCallback(() => {
        setShowAfterLoadHasErrors(false)
        setShowAfterLoad(false)
        if (onCartEditClick) {
            onCartEditClick()
        }
    }, [setShowAfterLoadHasErrors, setShowAfterLoad, onCartEditClick])

    const alertMessages = useMemo(() => {
        const messages: CollectionMap<Array<string>> = {}
        if (!selectedCarts) {
            return undefined
        }
        for (const idx in selectedCarts) {
            const selectedCart = selectedCarts[idx]
            if (typeof selectedCart.store_carts === 'undefined') {
                // on regarde si le montant minimum est atteint
                if (
                    isMinimumAmountActive(selectedCart, customer, cartMode, minimumAmountMode) &&
                    !isMinimumAmountReached(selectedCart, customer, cartMode, minimumAmountMode) &&
                    customer.minimum_amount_mode === ICustomerMinimumAmountMode.Permissive
                ) {
                    if (typeof messages[selectedCart['@id']] === 'undefined') {
                        messages[selectedCart['@id']] = []
                    }
                    messages[selectedCart['@id']].push(
                        formatMessage(
                            { id: 'cart.single_store_minimum_amount_not_reached' },
                            {
                                business_name: store ? store.business_name : customer.business_name,
                                minimum_amount: formatNumber(
                                    getMinimumOrderAmount(selectedCart, customer, cartMode, minimumAmountMode),
                                    {
                                        currency: 'EUR',
                                        currencyDisplay: 'symbol',
                                        currencySign: 'standard',
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        style: 'currency',
                                    }
                                ),
                            }
                        )
                    )
                }

                if (selectedCart.locked) {
                    if (typeof messages[selectedCart['@id']] === 'undefined') {
                        messages[selectedCart['@id']] = []
                    }
                    messages[selectedCart['@id']].push(formatMessage({ id: 'cart.your_cart_locked_explain' }))
                }
            } else if (selectedCart.store_carts!.length > 0) {
                for (const idx2 in selectedCart.store_carts) {
                    const currentStoreCart = selectedCart.store_carts[idx2]
                    if (
                        isMinimumAmountActive(currentStoreCart, customer, cartMode, minimumAmountMode) &&
                        !isMinimumAmountReached(currentStoreCart, customer, cartMode, minimumAmountMode)
                    ) {
                        if (typeof messages[selectedCart['@id']] === 'undefined') {
                            messages[selectedCart['@id']] = []
                        }
                        const currentStoreCustomer = findCustomerStore(customer, currentStoreCart.customer)
                        messages[selectedCart['@id']].push(
                            formatMessage(
                                { id: 'cart.global_single_store_minimum_amount_not_reached' },
                                {
                                    business_name: currentStoreCustomer.business_name,
                                    minimum_amount: formatNumber(
                                        getMinimumOrderAmount(currentStoreCart, customer, cartMode, minimumAmountMode),
                                        {
                                            currency: 'EUR',
                                            currencyDisplay: 'symbol',
                                            currencySign: 'standard',
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            style: 'currency',
                                        }
                                    ),
                                }
                            )
                        )
                    }

                    if (currentStoreCart.locked) {
                        if (typeof messages[selectedCart['@id']] === 'undefined') {
                            messages[selectedCart['@id']] = []
                        }
                        messages[selectedCart['@id']].push(formatMessage({ id: 'cart.your_cart_locked_explain' }))
                    }
                }
                if (typeof messages[selectedCart['@id']] === 'object') {
                    messages[selectedCart['@id']].push(
                        formatMessage({ id: 'cart.create_order_stores_minimum_amount_not_reached' })
                    )
                }
            }
        }

        return Object.keys(messages).length > 0 ? messages : undefined
    }, [selectedCarts, customer, cartMode, minimumAmountMode, formatMessage, store, formatNumber])

    useEffect(() => {
        if (showAfterLoad && !loading) {
            if (
                typeof selectedCarts === 'object' &&
                selectedCarts.length === 1 &&
                typeof child === 'object' &&
                selectedCarts.indexOf(child) === -1
            ) {
                const canShow = typeof validCarts === 'object' && validCarts.length > 0
                if (canShow) {
                    setShow(canShow)
                    return
                }
                //on affiche une sweet alert
                setShowAfterLoadHasErrors(true)
            }
        }
    }, [selectedCarts, child, cartId, showAfterLoad, setShow, validCarts, loading])

    useEffect(() => {
        if (show) {
            setShowAfterLoad(false)
            setShowAfterLoadHasErrors(false)
        }
    }, [setShowAfterLoad, show])

    useEffect(() => {
        if (cartValidationMode !== ICartValidationMode.Lock) {
            setShowLockSweetAlertSuccess(false)
            return
        }

        if (!lockersSuccess || (cartId && !lockersSuccess[cartId]) || (!cartId && !lockersSuccess['all'])) {
            setShowLockSweetAlertSuccess(false)
            return
        }
        setShowLockSweetAlertSuccess(lockersSuccess[cartId || 'all'] || false)
    }, [setShowLockSweetAlertSuccess, lockersSuccess, cartId, cartValidationMode])

    useEffect(() => {
        if (cartValidationMode !== ICartValidationMode.Lock) {
            setShowLockSweetAlertError(false)
            return
        }

        if (!lockersErrors || (cartId && !lockersErrors[cartId]) || (!cartId && !lockersErrors['all'])) {
            setShowLockSweetAlertError(false)
            return
        }
        setShowLockSweetAlertError(typeof lockersErrors[cartId || 'all'] !== 'undefined' || false)
    }, [setShowLockSweetAlertError, lockersErrors, cartId, cartValidationMode])

    return (
        <>
            <Button
                block
                ref={target}
                className={classNames('btn-validate', {
                    inactive: buttonDisabled,
                })}
                variant={buttonVariant}
                onClick={handleClick}
                size={buttonSize}
                loading={loading || cartLockerFetching}
                disabled={loading || cartLockerFetching}
                data-id={selectedCarts ? selectedCarts.map((selectedCart) => selectedCart['@id']) : ''}
                onMouseEnter={handleValidateButtonMouseEnter}
                onMouseLeave={handleValidateButtonMouseLeave}
            >
                <FlatIcon icon={'check'} />
                {showModalButtonLabel}
            </Button>
            {validCarts?.length === 0 && (
                <Overlay target={target.current} show={showTooltip}>
                    {(props) => (
                        <Popover id="cart-alert-tooltip" {...props}>
                            <Popover.Content>
                                {cartLocked && (
                                    <p>
                                        <FormattedMessage id={'cart.your_cart_locked_explain'} />
                                    </p>
                                )}
                                {hasErrors && (
                                    <p>
                                        <FormattedMessage id={'cart.has_errors'} />
                                    </p>
                                )}
                                {allIsEmpty && (
                                    <p>
                                        <FormattedMessage
                                            id={'carts.empty'}
                                            values={{ total: selectedCarts ? selectedCarts.length : 1 }}
                                        />
                                    </p>
                                )}
                                {!allReachedCarts &&
                                    mainCart &&
                                    selectedCarts?.length === 1 &&
                                    cartMode === ICartMode.Grouped && (
                                        <p>
                                            <FormattedMessage id={'cart.total_minimum_amount_all_warning'} />
                                        </p>
                                    )}
                                {!hasReachedCarts && typeof cartId !== 'undefined' && cartMode === ICartMode.Default && (
                                    <p>
                                        <FormattedMessage
                                            id={'cart.total_minimum_amount_required'}
                                            values={{
                                                minimum_amount: formatNumber(
                                                    getMinimumOrderAmount(
                                                        selectedCarts![0],
                                                        customer,
                                                        cartMode,
                                                        minimumAmountMode
                                                    ),
                                                    {
                                                        currency: 'EUR',
                                                        currencyDisplay: 'symbol',
                                                        currencySign: 'standard',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                    }
                                                ),
                                            }}
                                        />
                                    </p>
                                )}
                                {!hasReachedCarts && typeof child !== 'undefined' && !allIsEmpty && (
                                    <p>
                                        <FormattedMessage
                                            id={'cart.total_minimum_amount_required'}
                                            values={{
                                                minimum_amount: formatNumber(
                                                    getMinimumOrderAmount(child, customer, cartMode, minimumAmountMode),
                                                    {
                                                        currency: 'EUR',
                                                        currencyDisplay: 'symbol',
                                                        currencySign: 'standard',
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                        style: 'currency',
                                                    }
                                                ),
                                            }}
                                        />
                                    </p>
                                )}
                                {!hasReachedCarts &&
                                    !mainCart &&
                                    typeof child === 'undefined' &&
                                    cartMode === ICartMode.Grouped && (
                                        <p>
                                            <FormattedMessage id={'cart.store_minimum_amount_not_reached'} />
                                        </p>
                                    )}
                            </Popover.Content>
                        </Popover>
                    )}
                </Overlay>
            )}
            <Modal
                className={classNames('create-order-modal', { global })}
                show={show && !loading}
                // onExited={handleOnExited}
                onHide={handleOnHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && (
                        <div className={'loading-part'}>
                            <FontAwesomeIcon icon="circle-notch" spin={true} className={'app-icon app-icon-loader'} />
                        </div>
                    )}
                    {alertMessages && selectedCarts && (
                        <AlertMessages carts={selectedCarts} messages={alertMessages} tree={tree} treeMode={treeMode} />
                    )}
                    {createOrdersErrors && (
                        <CreateOrderErrors
                            carts={validCarts}
                            errors={createOrdersErrors}
                            tree={tree}
                            treeMode={treeMode}
                        />
                    )}
                    {validCarts && (
                        <>
                            <CreateOrderForm
                                showStrictMinimumAmountField={
                                    hasReachedCarts &&
                                    !allReachedCarts &&
                                    mainCart &&
                                    customer.minimum_amount_mode === ICustomerMinimumAmountMode.Permissive
                                }
                                allowGroupFields={global}
                                carts={validCarts}
                                customer={customer}
                                familyTree={tree}
                                submitButtonLabel={formatMessage({ id: 'checkout.confirm_and_save' })}
                                onFormSubmit={handleFormSubmit}
                                onFormReset={handleOnFormReset}
                                formValues={createOrdersData}
                                formErrors={createOrdersErrors}
                                disabled={createOrderFetching || cartLockerFetching}
                                fetching={createOrderFetching || cartLockerFetching}
                                orderMode={orderMode}
                                paymentModes={paymentModes}
                            />
                        </>
                    )}
                </Modal.Body>
            </Modal>
            <Portal>
                <SweetAlert
                    showCancel
                    warning={true}
                    cancelBtnText={formatMessage({ id: 'default.close' })}
                    confirmBtnText={formatMessage({ id: 'default.edit' })}
                    show={showAfterLoadHasErrors}
                    title={formatMessage({ id: `default.warning` })}
                    onCancel={handleSweetAlertAfterLoadErrorsCancel}
                    onConfirm={handleSweetAlertAfterLoadErrorsConfirm}
                >
                    <FormattedMessage id={'cart.has_errors'} />
                </SweetAlert>
                <SweetAlert
                    showCancel
                    warning={true}
                    cancelBtnText={formatMessage({ id: 'default.close' })}
                    confirmBtnText={formatMessage({ id: 'default.validate' })}
                    show={showLockSweetAlert}
                    title={formatMessage({ id: `cart.lock` })}
                    onCancel={handleSweetAlertLockCancel}
                    onConfirm={handleSweetAlertLockConfirm}
                >
                    <FormattedMessage
                        id={
                            // @ts-ignore
                            selectedCarts?.length > 1
                                ? validCarts?.length !== selectedCarts?.length
                                    ? 'carts.will_lock_question_warning'
                                    : 'carts.will_lock_question'
                                : 'cart.will_lock_question'
                        }
                    />
                </SweetAlert>
                <SweetAlert
                    success
                    confirmBtnText={formatMessage({ id: 'default.ok' })}
                    show={showLockSweetAlertSuccess}
                    title={formatMessage({ id: `cart.lock` })}
                    onConfirm={handleSweetAlertLockSuccessFailClick}
                >
                    <FormattedMessage id={'cart.locked_success_message'} />
                </SweetAlert>
                <SweetAlert
                    error
                    dependencies={[cartLockerErrors]}
                    confirmBtnText={formatMessage({ id: 'default.ok' })}
                    show={showLockSweetAlertError}
                    title={formatMessage({ id: `cart.lock` })}
                    onConfirm={handleSweetAlertLockSuccessFailClick}
                >
                    {cartLockerErrors.map((cartLockerError, idx) => (
                        <p key={idx} className="mb-0">
                            {cartLockerError.message}
                        </p>
                    ))}
                </SweetAlert>
            </Portal>
        </>
    )
}

CreateOrder.defaultProps = {
    mainCart: true,
    buttonSize: undefined,
    buttonLabel: undefined,
    number: undefined,
    global: false,
    minimumAmountMode: ICustomerMinimumAmountMode.Strict,
} as Partial<IProps>

export default CreateOrder
