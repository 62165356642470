import { createStructuredSelector } from 'reselect'
import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../../store/classification/selectors'
import { makeSelectTreeMode } from '../../store/config/selectors'
import { useSelector } from 'react-redux'
import { IApplicationRootState } from '../../store'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    IShippingLocation,
    TreeMode,
} from '../../services/api/service/classification/types'
import { useMemo } from 'react'
import { findShippingLocationBy } from '../../store/classification/utils'
import { Undefinable } from 'tsdef'

const stateSelector = createStructuredSelector<any, any>({
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    treeMode: makeSelectTreeMode(),
})

export function useShippingLocation(id: Undefinable<string>, propertyName: keyof IShippingLocation = '@id') {
    const { familyTree, categoryTree, treeMode } = useSelector<
        IApplicationRootState,
        {
            familyTree: IFamilyTreeCollection | undefined
            categoryTree: ICategoryTreeCollection | undefined
            treeMode: TreeMode
        }
    >(stateSelector)

    const tree = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return categoryTree
        }

        return familyTree
    }, [categoryTree, familyTree, treeMode])

    if (!id) {
        return undefined
    }

    if (!tree) {
        return undefined
    }

    return findShippingLocationBy(tree, propertyName, id, treeMode)
}
