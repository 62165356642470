import { AxiosInstance, CancelToken } from 'axios'
import {
    IApiProductGridPublicResponse,
    IProductGridPublic,
    IProductGridPublicCollectionResponse,
    IProductGridPublicListParameters,
    IProductGridPublicsService,
} from './types'
import { $PropertyType } from 'utility-types'

const create = (instance: AxiosInstance): IProductGridPublicsService => {
    /*****************************
     * METHODS
     *****************************/
    const list = (
        parameters: IProductGridPublicListParameters,
        cancelToken?: CancelToken
    ): Promise<IProductGridPublicCollectionResponse> => {
        return instance.get('/product-grids-public', { params: parameters, cancelToken })
    }

    const get = (
        publicGridId: $PropertyType<IProductGridPublic, 'id'>,
        cancelToken?: CancelToken
    ): Promise<IApiProductGridPublicResponse> => {
        return instance.get(`/product-grids-public/${publicGridId}`, { cancelToken })
    }

    return {
        list,
        get,
    }
}

export default {
    create,
}
