/**
 *
 * CustomerInfo
 *
 */
import classNames from 'classnames'
import React, { memo, MouseEvent } from 'react'
import ArrivalInfo from './SalesmanInfo/ArrivalInfo'
import ViewButton from '../../../containers/ProductStats/Weekly/Action/ViewButton'

interface IProps {
    productId?: string
    uniqId?: string
    tooltip?: boolean
    stats?: boolean
    stock: number | null
    currentValue?: number | null
    arrivalStock: number | null
    arrivalStockDate: string | null
    onArrivalStockClick?: (e: MouseEvent) => void
}

function SalesmanInfo({
    productId,
    arrivalStock = null,
    arrivalStockDate = null,
    stats = false,
    tooltip = true,
    stock,
    currentValue,
    onArrivalStockClick,
    uniqId,
}: IProps): JSX.Element {
    return (
        <div className={classNames('product-member-information')}>
            <div className={'content'}>
                <div className="row">
                    <div className="col">
                        <ArrivalInfo
                            uniqId={uniqId}
                            stock={stock}
                            currentValue={currentValue}
                            arrivalStock={arrivalStock}
                            arrivalDate={arrivalStockDate}
                            onClick={onArrivalStockClick}
                            tooltip={tooltip}
                        />
                    </div>
                    {stats && typeof productId !== 'undefined' ? (
                        <div className="col-auto">
                            <ViewButton productId={productId} />
                        </div>
                    ) : undefined}
                </div>
            </div>
        </div>
    )
}

export default memo(SalesmanInfo)
