import { ICart } from '../../../services/api/service/carts/types'
import { IShippingLocation } from '../../../services/api/service/classification/types'
import { ICustomer } from '../../../services/api/service/customers/types'
import { CartBackClickCallback } from '../../../types/cartCallback'
import { StrictCartMode } from '../../../store/carts/types'
import { IOrderMode } from '../../../services/api/service/orders/types'

export type IItemProps = {}

export type IListProps = {
    activeCartId?: string
    onAccordionItemClick?: (cart: ICart, number: number, eventKey: string) => void
}

export type IEntryProps = {
    cart: ICart
    cartMode: StrictCartMode
    orderMode: IOrderMode
    customer: ICustomer
    currentStore?: ICustomer
    number: number
    shippingLocation: IShippingLocation
    onCartBackClick?: CartBackClickCallback
    onAccordionItemClick?: (cart: ICart, number: number, eventKey: string) => void
}

export enum EntryModalType {
    Export = 'export',
    Clean = 'Clean',
    Duplicate = 'Duplicate',
}
