/*
 *
 * Product reducer
 *
 */

import ActionTypes from './constants'
import {
    ContainerActions,
    ContainerState,
    IProductCollectionState,
    IProductCrossSellingState,
    IProductDetailState,
    IProductMonthlyStatsState,
    IProductWeeklyStatsState,
} from './types'
import StockAlertActionTypes from '../stockAlert/constants'
import { generateCacheKey } from '../../services/api/utils'

export const initialAttributesState = {
    fetching: false,
    data: null,
}

export const initialProductDetailState: IProductDetailState = {
    fetching: false,
    detail: null,
}

export const initialProductCrossSellingState: IProductCrossSellingState = {
    params: {},
    totalItems: undefined,
    fetching: false,
}

export const initialProductCollectionState: IProductCollectionState = {
    identifiers: undefined,
    fetching: false,
    data: null,
}

export const initialProductWeeklyStats: IProductWeeklyStatsState = {}

export const initialProductMonthlyStats: IProductMonthlyStatsState = {}

export const initialState: ContainerState = {
    productId: null,
    detail: initialProductDetailState,
    groupAttributes: initialAttributesState,
    crossSelling: initialProductCrossSellingState,
    productCollection: initialProductCollectionState,
    weeklyStats: initialProductWeeklyStats,
    monthlyStats: initialProductMonthlyStats,
}
export const PRODUCT_STAT_DEFAULT_FILTER_KEY = 'default'

function productReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.DETAIL_RESET_ACTION:
            return {
                ...state,
                detail: initialProductDetailState,
            }
        case ActionTypes.ADD_TO_FAVORITE_ACTION: {
            if (!state.detail.detail || state.detail.detail['@id'] !== action.payload.productId) {
                return state
            }
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: {
                        ...state.detail.detail,
                        favorite: true,
                    },
                },
            }
        }
        case ActionTypes.REMOVE_TO_FAVORITE_ACTION: {
            if (!state.detail.detail || state.detail.detail.id !== action.payload.productId) {
                return state
            }
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: {
                        ...state.detail.detail,
                        favorite: false,
                    },
                },
            }
        }
        case ActionTypes.DETAIL_SUCCESS_ACTION:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: action.payload.response.data,
                },
            }
        case ActionTypes.GROUP_ATTRIBUTE_PROCESS_ACTION:
            return {
                ...state,
                groupAttributes: {
                    ...state.groupAttributes,
                    data: null,
                },
            }
        case ActionTypes.GROUP_ATTRIBUTE_RESET_ACTION:
            return {
                ...state,
                groupAttributes: initialAttributesState,
            }
        case ActionTypes.GROUP_ATTRIBUTE_SUCCESS_ACTION:
            return {
                ...state,
                groupAttributes: {
                    ...state.groupAttributes,
                    data: action.payload.response ? action.payload.response!.data : null,
                },
            }
        case ActionTypes.COLLECTION_PROCESS_ACTION:
            return {
                ...state,
                productCollection: {
                    ...state.productCollection,
                    identifiers: action.payload.identifiers,
                },
            }
        case ActionTypes.COLLECTION_RESET_ACTION:
            return {
                ...state,
                productCollection: initialProductCollectionState,
            }
        case ActionTypes.COLLECTION_SUCCESS_ACTION:
            return {
                ...state,
                productCollection: {
                    ...state.productCollection,
                    data: action.payload.response ? action.payload.response!.data : null,
                },
            }
        case ActionTypes.CROSS_SELLING_RESET_ACTION:
            return {
                ...state,
                crossSelling: initialProductCrossSellingState,
            }
        case ActionTypes.CROSS_SELLING_SUCCESS_ACTION:
            return {
                ...state,
                crossSelling: {
                    ...state.crossSelling,
                    items: action.payload['hydra:member'],
                    // totalItems: action.payload['hydra:totalItems'],
                    // nextPageUrl: action.payload['hydra:view']['hydra:next'],
                    // prevPageUrl: action.payload['hydra:view']['hydra:prev'],
                    // firstPageUrl: action.payload['hydra:view']['hydra:first'],
                    // lastPageUrl: action.payload['hydra:view']['hydra:last'],
                },
            }
        case ActionTypes.WEEKLY_STATS_PROCESS_ACTION: {
            const cacheKey = generateCacheKey(action.payload.filters ?? {}, PRODUCT_STAT_DEFAULT_FILTER_KEY)

            return {
                ...state,
                weeklyStats: {
                    ...state.weeklyStats,
                    [action.payload!.productId]: {
                        ...(state.weeklyStats[action.payload!.productId] ?? {}),
                        [cacheKey]: {
                            productId: action.payload.productId,
                            fetching: true,
                            error: undefined,
                            data: undefined,
                        },
                    },
                },
            }
        }
        case ActionTypes.WEEKLY_STATS_SUCCESS_ACTION: {
            const cacheKey = generateCacheKey(action.payload.filters ?? {}, PRODUCT_STAT_DEFAULT_FILTER_KEY)

            return {
                ...state,
                weeklyStats: {
                    ...state.weeklyStats,
                    [action.payload.productId]: {
                        ...state.weeklyStats[action.payload.productId],
                        [cacheKey]: {
                            fetching: false,
                            productId: action.payload.productId,
                            data: action.payload.data,
                        },
                    },
                },
            }
        }
        case ActionTypes.WEEKLY_STATS_RESET_ACTION:
            return {
                ...state,
                weeklyStats: {
                    ...initialProductWeeklyStats,
                },
            }
        case ActionTypes.MONTHLY_STATS_PROCESS_ACTION: {
            const cacheKey = generateCacheKey(action.payload.filters ?? {}, PRODUCT_STAT_DEFAULT_FILTER_KEY)

            return {
                ...state,
                monthlyStats: {
                    ...state.monthlyStats,
                    [action.payload!.productId]: {
                        ...(state.monthlyStats[action.payload!.productId] ?? {}),
                        [cacheKey]: {
                            productId: action.payload.productId,
                            fetching: true,
                            error: undefined,
                            data: undefined,
                        },
                    },
                },
            }
        }
        case ActionTypes.MONTHLY_STATS_SUCCESS_ACTION: {
            const cacheKey = generateCacheKey(action.payload.filters ?? {}, PRODUCT_STAT_DEFAULT_FILTER_KEY)

            return {
                ...state,
                monthlyStats: {
                    ...state.monthlyStats,
                    [action.payload.productId]: {
                        ...state.monthlyStats[action.payload.productId],
                        [cacheKey]: {
                            fetching: false,
                            productId: action.payload.productId,
                            data: action.payload.data,
                        },
                    },
                },
            }
        }
        case ActionTypes.MONTHLY_STATS_RESET_ACTION:
            return {
                ...state,
                monthlyStats: {
                    ...initialProductMonthlyStats,
                },
            }
        case StockAlertActionTypes.STOCK_ALERT_BULK_SUCCESS_ACTION: {
            if (!state.detail.detail || action.payload.productIds.indexOf(state.detail.detail.id) === -1) {
                return state
            }

            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: {
                        ...state.detail.detail,
                        has_stock_alert: action.payload.subscribe,
                    },
                },
            }
        }
        case StockAlertActionTypes.STOCK_ALERT_SUCCESS_ACTION: {
            if (!state.detail.detail || state.detail.detail.id !== action.payload.productId) {
                return state
            }
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: {
                        ...state.detail.detail,
                        has_stock_alert: action.payload.subscribe,
                    },
                },
            }
        }
        case StockAlertActionTypes.STOCK_ALERT_FAILURE_ACTION: {
            if (!state.detail.detail || state.detail.detail.id !== action.payload.productId) {
                return state
            }
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: {
                        ...state.detail.detail,
                        has_stock_alert: action.payload.subscribe,
                        can_add_stock_alert: action.payload.subscriptionEnabled,
                    },
                },
            }
        }
        case ActionTypes.RESET_ACTION:
            return initialState
        default:
            return state
    }
}

export default productReducer
