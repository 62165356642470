import { call, cancelled, delay, getContext, put, select, takeLatest } from 'redux-saga/effects'
import CartActionTypes from './constants'
import { IApiClient, SELECTED_CUSTOMER_HEADER_NAME } from '../../services/api/types'
import {
    BulkCartQuantityFilterAttributeCollection,
    BulkCartQuantityParameters,
    IApiBulkCartQuantityResponse,
    IApiCartItemDeleteResponse,
    IApiCartItemResponse,
    IApiCartLightListResponse,
    IApiCartListResponse,
    IAPiCartLockResponse,
    IApiCartResponse,
    IApiMultiStoreCartResponse,
    IApiReOrderResponse,
    IApiSalesmanCartListResponse,
    IBulkCartQuantityFilterAttribute,
    ICart,
    ICartCollection,
    ICartMode,
    ICartValidationMode,
} from '../../services/api/service/carts/types'
import {
    BulkCartQuantityMode,
    IAddToCartProcessAction,
    IAddToCartSuccessAction,
    ICartBulkQuantityProcessAction,
    ICartDuplicateProcessAction,
    ICartItemErrorsType,
    ICartLoadProcessAction,
    ICartLockerProcessAction,
    ICartMultistoreCartsProcessAction,
    ICartReOrderProcessAction,
    ICartSalesmanCartListProcessAction,
    ICartSaveToOrderProcessAction,
    ICartSaveToOrdersProcessAction,
    ICartsCleanProcessAction,
    ICartsLockerProcessAction,
    ICartsPersistParameters,
    ICartsPersistSettingsAction,
    ICartsSwitchCartModeAction,
    ICartStoreCartLightProcessAction,
    ICartStoreQuantityProcessAction,
    ICreateOrderItems,
    IRemoveToCartProcessAction,
    IRemoveToCartSuccessAction,
} from './types'
import {
    addToCartSuccessAction,
    cartBulkCartQuantityFailureAction,
    cartBulkCartQuantitySuccessAction,
    cartCartLightFailureAction,
    cartCartLightsProcessAction,
    cartCartLightsReloadAction,
    cartCartLightsResetAction,
    cartCartLightsSuccessAction,
    cartDuplicateFailureAction,
    cartDuplicateSuccessAction,
    cartFetchCartsAction,
    cartLoadCartProcessAction,
    cartLoadCartSuccessAction,
    cartLockerFailureAction,
    cartLockerProcessAction,
    cartLockerSuccessAction,
    cartMultiStoreCartsFailureAction,
    cartMultiStoreCartsSuccessAction,
    cartRefreshCartItemsErrors,
    cartReOrderFailureAction,
    cartReOrderSuccessAction,
    cartRevertQuantityAction,
    cartSaveToOrderFailureAction,
    cartSaveToOrderProcessAction,
    cartSaveToOrdersSuccessAction,
    cartSaveToOrderSuccessAction,
    cartsLockerFailureAction,
    cartsLockerSuccessAction,
    cartsPersistedParamsAction,
    cartsPersistParamsAction,
    cartsRefreshAction,
    cartStoreQuantityFailureAction,
    cartStoreQuantitySuccessAction,
    cleanCartsSuccessAction,
    removeToCartSuccessAction,
    salesmanCartListFailureAction,
    salesmanCartListSuccessAction,
} from './actions'
import { toast } from 'react-toastify'
import { formatApiError, formatAppError, formatIntlMessage } from '../app/saga'
import {
    makeSelectCartById,
    makeSelectCartLightDomain,
    makeSelectCartsCreateOrdersItems,
    makeSelectCartsLockerErrors,
    makeSelectCartsLockerSuccess,
    makeSelectMainCartsList,
} from './selectors'
import { IApiOrdersCreateResponse } from '../../services/api/service/orders/types'

// @ts-ignore
import moment from 'moment/min/moment-with-locales.min'
import { IApiErrorDescription } from '../http/types'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router'
import { getPath } from '../../routes'
import { selectLocale } from '../intl/selectors'
import HttpStatusCode, { HttpAuthErrors } from '../http/codes'
import { makeSelectCustomer } from '../customers/selectors'
import Config from '../../config'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import isUndefined from 'lodash/isUndefined'

import {
    makeSelectClassificationCategory,
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../classification/selectors'
import {
    IProductListParameters,
    ProductListStaticFilterCodes,
    ProductsListMode,
} from '../../services/api/service/products/types'
import {
    IApiCatalogListFilterCollection,
    ICatalogListFilterRangeValue,
    ICatalogListUserSelectionCollection,
} from '../../services/api/service/core/types'
import {
    makeSelectProductsListFilters,
    makeSelectProductsListGridColumns,
    makeSelectProductsListGridItems,
    makeSelectProductsListGridRows,
    makeSelectProductsListGridSelectedDefinition,
    makeSelectProductsListMode,
    makeSelectProductsListParams,
    makeSelectProductsListSelection,
} from '../products/selectors'
import ApplicationHelper from '../../utils/applicationHelper'
import {
    ICategory,
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    TreeMode,
} from '../../services/api/service/classification/types'
import { formatProductListFilterTreeToBulkCartQuantityTree } from '../classification/utils'
import { cartHasErrors, cartIsEmpty, isMinimumAmountReached } from './utils'
import { authLogoutAction } from '../auth/actions'
import { findCustomerStore, findCustomerStoreBy } from '../customers/utils'
import { ICustomer } from '../../services/api/service/customers/types'
import { $enum } from 'ts-enum-util'
import { violationStandardToString } from '../../utils/api'
import localforage from 'localforage'
import AuthActionTypes from '../auth/constants'
import CustomerActionTypes from '../customers/constants'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectAuthMe } from '../auth/selectors'
import { isSalesmanResource } from '../salesmens/utils'
import { CollectionMap } from '../../types/common'
import { Undefinable } from 'tsdef'
import { splashScreenShowAction } from '../splashScreen/actions'
import { reloadingAction } from '../app/actions'
import { productGridExtractAllProductIds } from '../products/utils'
import axios from 'axios'
import { makeSelectTreeMode } from '../config/selectors'

const CancelToken = axios.CancelToken

function formatAddRemoveToCartError(error: IApiErrorDescription) {
    return violationStandardToString(error)
}

function* processLoadCartRequest(action: ICartLoadProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { cartId, main } = action.payload
    try {
        const response: IApiCartResponse = yield call({ context: api.carts, fn: 'get' }, cartId)
        yield put(cartLoadCartSuccessAction(cartId, response.data, main))
    } catch (e) {
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }
}

function* processStoreCartLightReload(action: IAddToCartSuccessAction | IRemoveToCartSuccessAction) {
    const { storeId, cartId } = action.payload
    if (storeId || cartId) {
        const data = yield select(makeSelectCartLightDomain())
        for (const idx in data) {
            yield put(cartCartLightsProcessAction(idx))
        }
    }
}

function* processStoreCartLightRequest(action: ICartStoreCartLightProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { cartIdParent } = action.payload
    try {
        // récupération du panier parent
        const cart = yield select(makeSelectCartById(), cartIdParent)
        const response: IApiCartLightListResponse = yield call({ context: api.carts, fn: 'storeCartList' }, cart['@id'])
        yield put(cartCartLightsSuccessAction(cart['@id'], response))
    } catch (e) {
        const err = yield call(formatAppError, e, 'products.unknow_error')
        yield put(cartCartLightFailureAction(cartIdParent, err))
    }
}

function* processRefreshCarts() {
    const api: IApiClient = yield getContext('api')
    try {
        const response: IApiCartListResponse = yield call({ context: api.carts, fn: 'list' })
        yield put(cartsRefreshAction(response.data['hydra:member'], true))
        yield put(cartCartLightsReloadAction())
    } catch (e) {
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }
    }
}

function* processBulkCartQuantity(a: ICartBulkQuantityProcessAction) {
    const api: IApiClient = yield getContext('api')
    const pickerProductIds: ICatalogListUserSelectionCollection = yield select(makeSelectProductsListSelection())
    const { mode, quantity, planogramId, planogramModuleId } = a.payload

    try {
        let params: BulkCartQuantityParameters = {
            bulk: {
                quantity,
            },
        }

        if (mode === BulkCartQuantityMode.Planogram) {
            params = { ...params, bulk: { ...params.bulk, planogram: planogramId } }
        } else if (mode === BulkCartQuantityMode.PlanogramModule) {
            params = { ...params, bulk: { ...params.bulk, planogram_module: planogramModuleId } }
        } else if (mode === BulkCartQuantityMode.Grid) {
            const columns = yield select(makeSelectProductsListGridColumns())
            const rows = yield select(makeSelectProductsListGridRows())
            const items = yield select(makeSelectProductsListGridItems())
            const gridDef = yield select(makeSelectProductsListGridSelectedDefinition())
            const productIds = productGridExtractAllProductIds(columns, rows, items, gridDef)
            params = { ...params, bulk: { ...params.bulk, products: productIds } }
        } else if (mode === BulkCartQuantityMode.Picker) {
            params = { ...params, bulk: { ...params.bulk, products: pickerProductIds } }
        } else if (mode === BulkCartQuantityMode.List) {
            // récupération des paramètres de la liste de produits
            const parameters: IProductListParameters = yield select(makeSelectProductsListParams())
            const productMode: ProductsListMode = yield select(makeSelectProductsListMode())
            const definition: IApiCatalogListFilterCollection = yield select(makeSelectProductsListFilters())
            const category: ICategory | undefined = yield select(makeSelectClassificationCategory())
            const treeMode = yield select(makeSelectTreeMode())
            const familyTree: IFamilyTreeCollection | undefined = yield select(
                makeSelectClassificationFamilyTreeDefault()
            )
            const categoryTree: ICategoryTreeCollection | undefined = yield select(
                makeSelectClassificationCategoryTreeDefault()
            )

            const currentTree = treeMode === TreeMode.Categories ? categoryTree : familyTree

            const tree = formatProductListFilterTreeToBulkCartQuantityTree(
                treeMode,
                parameters.tree,
                currentTree,
                category
            )

            // conversion du tree pour passer les @id au lieu des id

            // on forge les paramètres à envoyer à l'API
            // TODO: Rendre cela moins statique (new, favorite, toussa, ...). Se baser sur les configs formattées
            params = {
                bulk: {
                    ...params.bulk,
                    ...tree,
                    search:
                        parameters.filters && parameters.filters[ProductListStaticFilterCodes.Search]
                            ? (parameters.filters[ProductListStaticFilterCodes.Search] as string)
                            : undefined,
                    best_sellers: productMode === ProductsListMode.BestSeller,
                    favorite: productMode === ProductsListMode.Favorite,
                    arrival_stocks: productMode === ProductsListMode.ArrivalStocks,
                    my_listing: productMode === ProductsListMode.MyListing,
                    discount: productMode === ProductsListMode.Discount,
                    new: productMode === ProductsListMode.New,
                },
            }

            // a-t-on des prix ?
            if (parameters.filters?.price) {
                // récupération des valeurs
                const values = parameters.filters?.price as ICatalogListFilterRangeValue
                if (values.min) {
                    params = {
                        ...params,
                        bulk: {
                            ...params.bulk,
                            min_price: Number(values.min),
                        },
                    }
                }
                if (values.max) {
                    params = {
                        ...params,
                        bulk: {
                            ...params.bulk,
                            max_price: Number(values.max),
                        },
                    }
                }
            }

            // on extrait les filtres possibles
            let codes: Array<string> = []
            if (definition) {
                if (definition && Object.keys(definition).length > 0) {
                    codes = Object.values(definition).map((value) => value.code)
                }
            }

            if (parameters.filters) {
                const filters: BulkCartQuantityFilterAttributeCollection = []
                for (const k in parameters.filters) {
                    if (!$enum(ProductListStaticFilterCodes).isValue(k) && codes.indexOf(k) > -1) {
                        const values = parameters.filters![k] as Array<string>
                        const converted = values.map((val) => parseFloat(val))
                        const attr: IBulkCartQuantityFilterAttribute = {
                            attribute: k,
                            values: converted,
                        }
                        filters.push(attr)
                    }
                }
                if (filters.length > 0) {
                    params = {
                        ...params,
                        bulk: {
                            ...params.bulk,
                            filters: filters,
                        },
                    }
                }
            }
        }

        //on supprime toutes les valeurs undefined
        params = ApplicationHelper.removeEmpty(params)

        // appel
        const response: IApiBulkCartQuantityResponse = yield call({ context: api.carts, fn: 'bulkMove' }, params)

        // refresh des paniers
        const resp = yield call({ context: api.carts, fn: 'list' })
        const carts = resp.data['hydra:member']
        for (const cart of carts) {
            yield put(cartLoadCartSuccessAction(cart['@id'], cart, true))
        }

        // stockage elements
        yield put(
            cartBulkCartQuantitySuccessAction(response.data['hydra:member'], response.data.unavailable_stock_products)
        )
    } catch (e) {
        const error = yield call(formatApiError, e, `product.add_to_cart.error`)
        yield put(cartBulkCartQuantityFailureAction(error))
    }
}

function* processAddToCart(action: IAddToCartProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { productId, quantity, quantityPrev, origin, storeId, cartId } = action.payload
    try {
        // le store id correspond à la resource ID. On ne veux que l'id ici
        let realStoreId: string | undefined = undefined
        if (storeId) {
            const customer: ICustomer = yield select(makeSelectCustomer())
            const store = findCustomerStoreBy(customer, 'id', storeId)
            realStoreId = store.id
        }

        const response: IApiCartItemResponse = yield call(
            { context: api.carts, fn: 'addItem' },
            productId,
            quantity,
            realStoreId
        )
        const cartItem = response.data

        // on regarde si l'item a été supprimé du panier
        if (cartItem.is_deleted) {
            const cartItemDeleteMessage = yield call(formatIntlMessage, 'cart.cart_item_auto_deleted')
            toast.error(`${cartItem.product.name} :\n${cartItemDeleteMessage}`, {
                toastId: 'add-to-cart-error',
            })
            yield put(removeToCartSuccessAction(productId, origin, storeId, cartId, undefined))

            // on regarde si c'est le meme panier
            if (cartId && cartItem.cart !== cartId) {
                yield put(cartLoadCartProcessAction(cartId))
            }

            return
        }

        if (cartItem.quantity_changed) {
            const newQuantity =
                storeId && cartItem.store_quantities && cartItem.store_quantities
                    ? cartItem.store_quantities[storeId] || 0
                    : cartItem.quantity
            const cartItemQtyUpdatedMessage = yield call(formatIntlMessage, 'cart.cart_item_quantity_updated', {
                quantity: quantity,
                new_quantity: newQuantity,
            })
            toast.info(`${cartItem.product.name} :\n${cartItemQtyUpdatedMessage}`, {
                toastId: 'add-to-cart-error',
            })
        }

        // stockage element
        yield put(addToCartSuccessAction(cartItem, origin, storeId, cartId))

        if (Config.GOOGLE.ANALYTICS.ACTIVE) {
            try {
                ReactGA.event('add_to_cart', {
                    currency: Config.I18N.DEFAULT_CURRENCY.CURRENCY,
                    value: cartItem.total,
                    items: [
                        {
                            item_id: cartItem.product.reference,
                            item_name: cartItem.product.name,
                            price: cartItem.unit_price,
                            quantity: cartItem.quantity,
                        },
                    ],
                })
            } catch (e) {
                if (Config.SENTRY.ACTIVE) {
                    Sentry.captureException(e)
                }
            }
        }

        // on regarde si on a des erreurs
        // if (cartItem && cartItem.errors && cartItem.errors.length > 0 && origin !== 'cart') {
        //     toast.error(`${cartItem.product.name} :\n${cartItem.errors.join(`\n`)}`, { toastId: 'add-to-cart-error' })
        // }

        // on regarde si c'est le meme panier
        if (cartId && cartItem.cart !== cartId) {
            yield put(cartLoadCartProcessAction(cartId))
        }
    } catch (e) {
        console.error(e)
        if (Config.SENTRY.ACTIVE) {
            Sentry.captureException(e)
        }

        const error: IApiErrorDescription = yield call(formatApiError, e, `product.add_to_cart.error`)
        if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
            if (error.status === HttpStatusCode.UNAUTHORIZED || error.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                const logoutErr = yield call(formatAppError, e, 'default.http.code.401')
                yield put(authLogoutAction(true, logoutErr, window.location.pathname))
                return
            }
        }

        const message = formatAddRemoveToCartError(error)
        toast.error(message, {
            toastId: 'add-to-cart-error',
        })
        yield put(cartRevertQuantityAction(productId, quantityPrev, origin, storeId, cartId))
    }
}

function* processRemoveToCart(action: IRemoveToCartProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { productId, quantityPrev, origin, storeId, cartId } = action.payload
    try {
        // le store id correspond à la resource ID. On ne veux que l'id ici
        let realStoreId: string | undefined = undefined
        if (storeId) {
            const customer: ICustomer = yield select(makeSelectCustomer())
            const store = findCustomerStoreBy(customer, 'id', storeId)
            realStoreId = store.id
        }

        const response: IApiCartItemDeleteResponse = yield call(
            { context: api.carts, fn: 'deleteItem' },
            productId,
            realStoreId
        )
        const cartItem = response.data

        yield put(removeToCartSuccessAction(productId, origin, storeId, cartId, response.data))

        // on regarde si on a des erreurs
        // if (cartItem && cartItem.errors && cartItem.errors.length > 0 && origin !== 'cart') {
        //     toast.error(`${cartItem.product.name} :\n${cartItem.errors.join(`\n`)}`, { toastId: 'add-to-cart-error' })
        // }

        // on regarde si c'est le meme panier
        if (cartId && cartItem && cartItem.cart !== cartId) {
            yield put(cartLoadCartProcessAction(cartId))
        }
    } catch (e) {
        console.error(e)
        const error: IApiErrorDescription = yield call(formatApiError, e, `product.remove_to_cart.error`)

        if (error.status && HttpAuthErrors.indexOf(error.status) > -1) {
            if (error.status === HttpStatusCode.UNAUTHORIZED || error.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                const logoutErr = yield call(formatAppError, e, 'default.http.code.401')
                yield put(authLogoutAction(true, logoutErr, window.location.pathname))
                return
            }
        }

        const message = formatAddRemoveToCartError(error)
        toast.error(message, {
            toastId: 'remove-to-cart-error',
        })
        yield put(cartRevertQuantityAction(productId, quantityPrev, origin, storeId, cartId))
    }
}

function* processReOrder(action: ICartReOrderProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { resourceId, customerId, data } = action.payload
    const me: IMe = yield select(makeSelectAuthMe())
    const customer: Undefinable<ICustomer> = yield select(makeSelectCustomer())

    try {
        // ajout du header dans le cas où c'est un commercial & qu'aucun client a été sélectionné
        let headers: Undefinable<CollectionMap<string>> = undefined
        if (isSalesmanResource(me) && isUndefined(customer) && customerId) {
            headers = { [SELECTED_CUSTOMER_HEADER_NAME]: customerId }
        }

        const response: IApiReOrderResponse = yield call({ context: api.carts, fn: 'reOrder' }, data, headers)
        // stockage des infos
        yield put(cartReOrderSuccessAction(resourceId, response.data['hydra:member'], response.data.warnings))
    } catch (e) {
        const error = yield call(formatAppError, e, 'checkout.save_to_cart.error')
        yield put(cartReOrderFailureAction(resourceId, error))
    }
}

function* processDuplicate(action: ICartDuplicateProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { resourceId, data } = action.payload

    try {
        const response: IApiReOrderResponse = yield call({ context: api.carts, fn: 'duplicate' }, data)
        // refresh
        yield call(processRefreshCarts)

        // stockage des infos
        yield put(cartDuplicateSuccessAction(resourceId, response.data['hydra:member'], response.data.warnings))
    } catch (e) {
        const error = yield call(formatAppError, e, 'checkout.save_to_cart.error')
        yield put(cartDuplicateFailureAction(resourceId, error))
    }
}

function* processLockerCart(action: ICartLockerProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { cartId, processApiCall, lock, reloadCarts } = action.payload

    // on s'arrete ici
    // exemple: dans le cas de l'appel en masse, on ne veux pas que cet appel soit fait
    // on veux simplement stocker
    if (!processApiCall) {
        return
    }

    try {
        const response: IAPiCartLockResponse = yield call({ context: api.carts, fn: lock ? 'lock' : 'unlock' }, cartId)

        // sauvegarde
        yield put(cartLockerSuccessAction(response.data, lock))

        // refresh des paniers
        if (reloadCarts) {
            yield put(cartFetchCartsAction())
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'checkout.save_to_cart.error')
        if (error.isHttpError) {
            const formattedItemsErrors: ICartItemErrorsType = {}
            if (String(error.status) === HttpStatusCode.BAD_REQUEST.toString() && error.violations) {
                for (const key in error.violations) {
                    const itemErrors = error.violations[key]
                    if (key.search('cart.indexed_items') > -1) {
                        let identifier = key.replace('cart.indexed_items[', '').replace(']', '')
                        identifier = `/carts/items/${identifier}`
                        formattedItemsErrors[identifier] = itemErrors
                    }
                }
                yield put(cartRefreshCartItemsErrors(cartId, formattedItemsErrors))
            }
        }

        yield put(cartLockerFailureAction(cartId, error, lock))
    }
}

function* processCartsLocker(action: ICartsLockerProcessAction) {
    const { cartIds, lock } = action.payload
    const carts: ICartCollection = yield select(makeSelectMainCartsList())
    const customer: ICustomer = yield select(makeSelectCustomer())

    for (const index in cartIds) {
        const cartId = cartIds[index]
        const current = carts.find((cart) => cart['@id'] === cartId)
        if (current === undefined) {
            continue
        }
        const cartCustomer =
            current.customer !== customer['@id'] ? findCustomerStore(customer, current.customer) : customer
        if (
            cartHasErrors(current) ||
            cartIsEmpty(current) ||
            !isMinimumAmountReached(current, cartCustomer, ICartMode.Default, customer.minimum_amount_mode)
        ) {
            continue
        }
        yield put(cartLockerProcessAction(cartId, lock, false))
        yield call(processLockerCart, {
            type: CartActionTypes.LOCKER_PROCESS_ACTION,
            payload: {
                cartId,
                lock,
                processApiCall: true,
                reloadCarts: true,
            },
        })
    }

    // récupération des lock
    const lockErrors = yield select(makeSelectCartsLockerErrors())
    const lockSuccess = yield select(makeSelectCartsLockerSuccess())

    const successCartIds = Object.values(lockSuccess)
    if (successCartIds.length > 0) {
        // eslint-disable-next-line prefer-spread
        const merged = [].concat.apply([], Object.values(lockErrors))
        // ILS ONT TOUS ETE VALIDES NICKEL !
        yield put(cartsLockerSuccessAction(Object.keys(lockSuccess), lock, merged))
    } else {
        // eslint-disable-next-line prefer-spread
        const merged = [].concat.apply([], Object.values(lockErrors))
        yield put(cartsLockerFailureAction(Object.keys(lockErrors), merged, lock))
    }
}

function* processSaveToOrder(action: ICartSaveToOrderProcessAction) {
    const locale = yield select(selectLocale)
    const api: IApiClient = yield getContext('api')
    const { cartId, data, redirectAfterSuccess, processApiCall } = action.payload

    // on s'arrete ici
    // exemple: dans le cas de l'appel en masse, on ne veux pas que cet appel soit fait
    // on veux simplement stocker
    if (!processApiCall) {
        return
    }

    try {
        const comment =
            // eslint-disable-next-line no-prototype-builtins
            data && typeof data.comment === 'string' ? data.comment : undefined
        const requestedDeliveryDate =
            // eslint-disable-next-line no-prototype-builtins
            data && data.date instanceof Date ? data.date : undefined
        const paymentMode =
            // eslint-disable-next-line no-prototype-builtins
            data && typeof data.payment_mode === 'string' ? data.payment_mode : undefined
        const validateOnlyMinimumAmountOrders = data && data.validate_only_minimum_amount_orders ? true : undefined

        const formattedDate: string | undefined = requestedDeliveryDate
            ? moment(requestedDeliveryDate).format('YYYY-MM-DD')
            : undefined

        const response: IApiOrdersCreateResponse = yield call(
            { context: api.orders, fn: 'create' },
            cartId,
            comment,
            formattedDate,
            validateOnlyMinimumAmountOrders,
            paymentMode
        )

        // récupération des commandes
        const orders = response.data['hydra:member']

        // si on en a aucune
        if (orders.length === 0) {
            const mesg = yield call(formatIntlMessage, 'order.no_order_created')
            throw new Error(mesg)
        }

        // sauvegarde
        yield put(cartSaveToOrderSuccessAction(cartId, orders))

        // refresh des paniers
        yield put(cartFetchCartsAction())

        // on regarde si ce sont les mêmes clients
        // const customer: ICustomer = yield select(makeSelectCustomer())
        // const currentCart = yield select(makeSelectCartById(), uniqId)
        // if (currentCart.customer !== customer['@id']) {
        //     yield put(cartFetchCartsAction())
        // }

        // analytics
        if (Config.GOOGLE.ANALYTICS.ACTIVE) {
            try {
                // pour chaque commande, on envoi une transaction
                for (const index in orders) {
                    const order = orders[index]
                    const eventData: {
                        transaction_id: string
                        currency: string
                        value: number
                        items: { item_id: string; item_name: string; price: number; quantity: number }[]
                    } = {
                        transaction_id: order.number,
                        currency: Config.I18N.DEFAULT_CURRENCY.CURRENCY,
                        value: order.total,
                        items: [],
                    }
                    for (const idx in order.items) {
                        const orderProduct = order.items[idx]
                        eventData.items.push({
                            item_id: orderProduct.product.reference, // SKU/code.
                            item_name: orderProduct.product.name, // Product name. Required.
                            price: orderProduct.unit_price, // Unit price.
                            quantity: orderProduct.quantity,
                        })
                    }

                    ReactGA.event('purchase', eventData)
                }
            } catch (e) {
                if (Config.SENTRY.ACTIVE) {
                    Sentry.captureException(e)
                }
            }
        }

        if (redirectAfterSuccess) {
            yield put(push(generatePath(getPath('confirmation', locale), { lang: locale })))
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'checkout.save_to_cart.error')
        if (error.isHttpError) {
            const formattedItemsErrors: ICartItemErrorsType = {}
            if (String(error.status) === HttpStatusCode.BAD_REQUEST.toString() && error.violations) {
                for (const key in error.violations) {
                    const itemErrors = error.violations[key]
                    if (key.search('cart.indexed_items') > -1) {
                        let identifier = key.replace('cart.indexed_items[', '').replace(']', '')
                        identifier = `/carts/items/${identifier}`
                        formattedItemsErrors[identifier] = itemErrors
                    }
                }
                yield put(cartRefreshCartItemsErrors(cartId, formattedItemsErrors))
            }
        }

        yield put(cartSaveToOrderFailureAction(cartId, error))
    }
}

function* processSaveToOrders(action: ICartSaveToOrdersProcessAction) {
    const { cartIds, data } = action.payload
    const locale = yield select(selectLocale)
    const carts: ICartCollection = yield select(makeSelectMainCartsList())
    const customer: ICustomer = yield select(makeSelectCustomer())

    const submittedCartIds: Array<string> = []

    for (const index in cartIds) {
        const cartId = cartIds[index]
        const current = carts.find((cart) => cart['@id'] === cartId)
        if (current === undefined) {
            continue
        }
        const cartCustomer =
            current.customer !== customer['@id'] ? findCustomerStore(customer, current.customer) : customer
        if (
            cartHasErrors(current) ||
            cartIsEmpty(current) ||
            !isMinimumAmountReached(current, cartCustomer, ICartMode.Default, customer.minimum_amount_mode) ||
            typeof data[cartId] === 'undefined'
        ) {
            continue
        }
        yield put(cartSaveToOrderProcessAction(cartId, data[cartId], false, false))
        yield call(processSaveToOrder, {
            type: CartActionTypes.SAVE_TO_ORDERS_PROCESS_ACTION,
            payload: {
                cartId,
                data: data[cartId],
                processApiCall: true,
                redirectAfterSuccess: false,
            },
        })
        submittedCartIds.push(cartId)
    }

    // on regarde si on a au moins une commande de validée
    const createOrderItems: ICreateOrderItems = yield select(makeSelectCartsCreateOrdersItems())
    const orders = Object.values(createOrderItems).reduce((a, b) => a.concat(b), [])
    if (orders.length > 0) {
        yield put(push(generatePath(getPath('confirmation', locale), { lang: locale })))
        yield put(cartSaveToOrdersSuccessAction())
    }
}

function* processStoreQuantityRequest(action: ICartStoreQuantityProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { productId } = action.payload

    try {
        const response: IApiCartItemResponse = yield call({ context: api.carts, fn: 'getItem' }, productId)
        yield put(cartStoreQuantitySuccessAction(productId, response.data.store_quantities || {}))
    } catch (e) {
        const error = yield call(formatAppError, e, 'product.store_quantity.retrieve_error')
        yield put(cartStoreQuantityFailureAction(productId, error))
    }
}

function* processCartsCleanRequest(action: ICartsCleanProcessAction) {
    const carts: Array<ICart> = []
    const api: IApiClient = yield getContext('api')
    const { cartIds } = action.payload
    const currentCarts = yield select(makeSelectMainCartsList())

    for (const index in cartIds) {
        const cartId = cartIds[index]
        const currentCart = currentCarts.find((currCart: ICart) => currCart['@id'] === cartId)
        try {
            const response: IApiCartResponse = yield call({ context: api.carts, fn: 'clean' }, cartId)
            carts.push(response.data)
        } catch (e) {
            console.error(e)
            carts.push(currentCart)
        }
    }
    yield put(cleanCartsSuccessAction(carts))
    yield put(cartCartLightsResetAction())
}

function* processPersistedParams() {
    const api: IApiClient = yield getContext('api')
    const storage: typeof localforage = yield getContext('storage')
    const customer: ICustomer | undefined = yield select(makeSelectCustomer())

    try {
        const settings: ICartsPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.CART.SETTINGS_STORAGE_NAME
        )
        if (customer && customer.cart_mode === ICartMode.Both) {
            yield call({ context: api, fn: 'setCartMode' }, settings?.cart_mode || undefined)
        }
    } catch (e) {
        console.error(e)
    }
}

function* processPersistParams(action: ICartsPersistSettingsAction) {
    const { params, merge } = action.payload

    try {
        const storage: typeof localforage = yield getContext('storage')
        if (!params) {
            yield call({ context: storage, fn: 'removeItem' }, Config.CART.SETTINGS_STORAGE_NAME)
            yield put(cartsPersistedParamsAction())
            return
        }
        if (!merge) {
            yield call({ context: storage, fn: 'setItem' }, Config.CART.SETTINGS_STORAGE_NAME, params)
            yield put(cartsPersistedParamsAction())
            return
        }

        // récupération item
        const settings: ICartsPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.CART.SETTINGS_STORAGE_NAME
        )
        const parameters = { ...settings, ...params }
        yield call({ context: storage, fn: 'setItem' }, Config.CART.SETTINGS_STORAGE_NAME, parameters)

        yield put(cartsPersistedParamsAction())
    } catch (e) {
        console.error(e)
    }
}

export function* processInitializeCartsSettings() {
    try {
        const storage: typeof localforage = yield getContext('storage')
        const me: IMe = yield select(makeSelectAuthMe())

        const settings: ICartsPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.CART.SETTINGS_STORAGE_NAME
        )
        const customer: ICustomer = yield select(makeSelectCustomer())
        const cartValidationMode = isSalesmanResource(me) ? ICartValidationMode.Default : customer.cart_validation_mode

        // si le mode change côté back, il faut pouvoir l'overrider ici !
        const cartMode =
            customer.cart_mode === ICartMode.Both ? settings?.cart_mode || ICartMode.Default : customer.cart_mode
        yield put(cartsPersistParamsAction({ cart_mode: cartMode, cart_validation_mode: cartValidationMode }))

        // if (!settings) {
        //     const cartMode = customer.cart_mode === ICartMode.Both ? ICartMode.Default : customer.cart_mode
        //     yield put(cartsPersistParamsAction({ cart_mode: cartMode, cart_validation_mode: cartValidationMode }))
        // } else {
        //     yield put(cartsPersistParamsAction({ ...settings, cart_validation_mode: cartValidationMode }, true))
        // }
    } catch (e) {}
}

function* removeCartsPersistParams() {
    try {
        const api: IApiClient = yield getContext('api')
        const storage: typeof localforage = yield getContext('storage')
        yield call({ context: storage, fn: 'removeItem' }, Config.CART.SETTINGS_STORAGE_NAME)
        yield call({ context: api, fn: 'setCartMode' }, undefined)
    } catch (e) {}
}

function* switchCartModeProcess(action: ICartsSwitchCartModeAction) {
    const { cartMode } = action.payload

    yield put(cartsPersistParamsAction({ cart_mode: cartMode }, true))
    yield put(splashScreenShowAction(true))

    yield delay(400) // ca va tellement vite que l'interface "rame". ON patiente un tout petit peu

    yield put(reloadingAction())
}

function* processMultiStoreCartsRequest(action: ICartMultistoreCartsProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    try {
        const { params } = action.payload
        const response: IApiMultiStoreCartResponse = yield call(
            { context: api.carts, fn: 'storesCartList' },
            params,
            source.token
        )
        yield put(cartMultiStoreCartsSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'products.unknow_error')
        yield put(cartMultiStoreCartsFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processSalesmanListProcessAction(action: ICartSalesmanCartListProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    try {
        const { params } = action.payload
        const response: IApiSalesmanCartListResponse = yield call(
            { context: api.carts, fn: 'salesmanCartList' },
            params,
            source.token
        )
        yield put(salesmanCartListSuccessAction(response.data))
    } catch (e) {
        const err = yield call(formatAppError, e, 'products.unknow_error')
        yield put(salesmanCartListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(CartActionTypes.ADD_TO_CART_PROCESS_ACTION, processAddToCart),
    takeLatest(CartActionTypes.REMOVE_TO_CART_PROCESS_ACTION, processRemoveToCart),
    takeLatest(CartActionTypes.SAVE_TO_ORDER_PROCESS_ACTION, processSaveToOrder),
    // takeLatest(CartActionTypes.SAVE_TO_ORDER_SUCCESS_ACTION, processRefreshCart),
    // takeLatest(CartActionTypes.SAVE_TO_ORDERS_SUCCESS_ACTION, processRefreshCarts),
    takeLatest(CartActionTypes.FETCH_CARTS_ACTION, processRefreshCarts),
    takeLatest(CartActionTypes.SAVE_TO_ORDERS_PROCESS_ACTION, processSaveToOrders),
    takeLatest(CartActionTypes.REORDER_PROCESS_ACTION, processReOrder),
    takeLatest(CartActionTypes.DUPLICATE_PROCESS_ACTION, processDuplicate),
    takeLatest(CartActionTypes.STORE_QUANTITY_PROCESS_ACTION, processStoreQuantityRequest),
    takeLatest(CartActionTypes.CLEAN_CART_PROCESS_ACTION, processCartsCleanRequest),
    takeLatest(CartActionTypes.LOAD_CART_PROCESS_ACTION, processLoadCartRequest),
    takeLatest(CartActionTypes.CART_LIGHT_PROCESS_ACTION, processStoreCartLightRequest),
    takeLatest(CartActionTypes.ADD_TO_CART_SUCCESS_ACTION, processStoreCartLightReload),
    takeLatest(CartActionTypes.REMOVE_TO_CART_SUCCESS_ACTION, processStoreCartLightReload),
    takeLatest(CartActionTypes.BULK_ADD_TO_CART_PROCESS_ACTION, processBulkCartQuantity),
    takeLatest(CartActionTypes.PERSIST_PARAMS_ACTION, processPersistParams),
    takeLatest(CartActionTypes.PERSISTED_PARAMS_ACTION, processPersistedParams),
    takeLatest(AuthActionTypes.LOGOUT_ACTION, removeCartsPersistParams),
    // takeLatest(CustomerActionTypes.SET_CURRENT_CUSTOMER_ACTION, removeCartsPersistParams),
    takeLatest(CustomerActionTypes.RESET_CURRENT_CUSTOMER_ACTION, removeCartsPersistParams),
    takeLatest(CartActionTypes.LOCKER_PROCESS_ACTION, processLockerCart),
    takeLatest(CartActionTypes.LOCKERS_PROCESS_ACTION, processCartsLocker),
    takeLatest(CartActionTypes.SWITCH_CART_MODE, switchCartModeProcess),
    takeLatest(CartActionTypes.CART_MULTI_STORE_PROCESS_ACTION, processMultiStoreCartsRequest),
    takeLatest(CartActionTypes.CART_SALESMAN_LIST_PROCESS_ACTION, processSalesmanListProcessAction),
]
