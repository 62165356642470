import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductsPage from './ProductsPage'
import PageLoader from '../components/Loader/PageLoader'
import { ProductsListMode } from '../services/api/service/products/types'
import {
    classificationCategoryTreeProcessAction,
    classificationFamilyTreeProcessAction,
} from '../store/classification/actions'
import {
    makeSelectClassificationCategoryTreeByMode,
    makeSelectClassificationFamilyTreeByMode,
} from '../store/classification/selectors'
import { Undefinable } from 'tsdef'
import { ICategoryTreeCollection, IFamilyTreeCollection, TreeMode } from '../services/api/service/classification/types'
import { IApplicationRootState } from '../store'
import { createStructuredSelector } from 'reselect'
import { makeSelectCustomer } from '../store/customers/selectors'
import { makeSelectAuthLoggedIn, makeSelectAuthMe } from '../store/auth/selectors'
import { makeSelectAppReload } from '../store/app/selectors'
import { ICustomer } from '../services/api/service/customers/types'
import { IMe } from '../services/api/service/me/types'
import { useIntl } from 'react-intl'
import { generatePath, Redirect } from 'react-router-dom'
import { getPath } from '../routes'
import { useCustomer } from '../utils/hook/useCustomer'
import { makeSelectTreeMode } from '../store/config/selectors'

interface IProps {
    mode: ProductsListMode
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
    loggedIn: makeSelectAuthLoggedIn(),
    reloading: makeSelectAppReload(),
    treeMode: makeSelectTreeMode(),
})

export default function ProductsLoaderPage({ mode }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { locale } = useIntl()
    const { isInternal: isInternalCustomer } = useCustomer()
    const [favoriteTreeReloaded, setFavoriteTreeReloaded] = useState(false)
    const { treeMode } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            loggedIn: boolean
            me?: IMe
            reloading: boolean
            treeMode: TreeMode
        }
    >(stateSelector)

    const selectFamilyTreeWithMode = useMemo(makeSelectClassificationFamilyTreeByMode, [])
    const familyTree: Undefinable<IFamilyTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (mode !== ProductsListMode.Category && treeMode === TreeMode.Families) {
            return selectFamilyTreeWithMode(state, mode)
        }
        return undefined
    })

    const selectCategoryTreeWithMode = useMemo(makeSelectClassificationCategoryTreeByMode, [])
    const categoryTree: Undefinable<ICategoryTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<ICategoryTreeCollection>
    >((state) => {
        if (mode !== ProductsListMode.Category && treeMode === TreeMode.Categories) {
            return selectCategoryTreeWithMode(state, mode)
        }
        return undefined
    })

    useEffect(() => {
        if (
            (isInternalCustomer && [ProductsListMode.Default].includes(mode)) ||
            (!isInternalCustomer && ![ProductsListMode.Category, ProductsListMode.Favorite].includes(mode))
        ) {
            if (typeof familyTree === 'undefined' && treeMode === TreeMode.Families) {
                // @ts-ignore
                dispatch(classificationFamilyTreeProcessAction(mode))
            } else if (typeof categoryTree === 'undefined' && treeMode === TreeMode.Categories) {
                // @ts-ignore
                dispatch(classificationCategoryTreeProcessAction(mode))
            }
        }
    }, [dispatch, mode, treeMode, familyTree, categoryTree, isInternalCustomer])

    useEffect(() => {
        if (mode === ProductsListMode.Favorite && !favoriteTreeReloaded) {
            if (treeMode === TreeMode.Families) {
                // @ts-ignore
                dispatch(classificationFamilyTreeProcessAction(mode))
            } else if (treeMode === TreeMode.Categories) {
                // @ts-ignore
                dispatch(classificationCategoryTreeProcessAction(mode))
            }
            setFavoriteTreeReloaded(true)
        }
    }, [dispatch, mode, treeMode, familyTree, categoryTree, isInternalCustomer, favoriteTreeReloaded])

    if (
        isInternalCustomer &&
        ![ProductsListMode.Default, ProductsListMode.Category, ProductsListMode.Favorite].includes(mode)
    ) {
        return <Redirect to={generatePath(getPath('catalog', locale), { lang: locale })} />
    }

    if (treeMode === TreeMode.Families && typeof familyTree !== 'undefined') {
        return <ProductsPage mode={mode} />
    }

    if (treeMode === TreeMode.Categories && typeof categoryTree !== 'undefined') {
        return <ProductsPage mode={mode} />
    }

    return <PageLoader />
}
