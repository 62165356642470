import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState, PRODUCT_STAT_DEFAULT_FILTER_KEY } from './reducer'
import {
    ContainerState as ProductState,
    IProductCollectionState,
    IProductCrossSellingState,
    IProductDetailState,
    IProductGroupAttributeState,
    IProductStatsFilters,
} from './types'
import { CmsWidgetTheme, CmsWidgetType, ICmsWidgetHighlightCollection } from '../../services/api/service/cms/types'
import ImagePlaceholder from '../../assets/img/product-list-placeholder.png'
import { generateCacheKey } from '../../services/api/utils'

/**
 * Direct selector to the product state domain
 */

const selectProductDomain = (state: IApplicationRootState): ProductState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product || initialState
}

const selectProductDetail = (state: IApplicationRootState): IProductDetailState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product.detail || initialState.detail
}

const selectProductWeeklyStats = (state: IApplicationRootState): IProductDetailState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product.weeklyStats || initialState.weeklyStats
}

const selectProductMonthlyStats = (state: IApplicationRootState): IProductDetailState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product.monthlyStats || initialState.monthlyStats
}

const selectProductGroupAttributes = (state: IApplicationRootState): IProductGroupAttributeState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product.groupAttributes || initialState.groupAttributes
}
const selectProductCollection = (state: IApplicationRootState): IProductCollectionState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product.productCollection || initialState.productCollection
}
const selectProductCrossSelling = (state: IApplicationRootState): IProductCrossSellingState => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.product.crossSelling || initialState.crossSelling
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Product
 */

const makeSelectProduct = () =>
    createSelector(selectProductDomain, (substate) => {
        return substate
    })

const makeSelectProductDetailId = () =>
    createSelector(selectProductDomain, (substate) => {
        return substate.productId
    })

const makeSelectProductDetailGroupId = () =>
    createSelector(selectProductDomain, (substate) => {
        return substate.productGroupId
    })

const makeSelectProductDetailFetching = () =>
    createSelector(selectProductDomain, (substate) => {
        return substate.detail.fetching
    })

const makeSelectProductDetailError = () =>
    createSelector(selectProductDomain, (substate) => {
        return substate.detail.error
    })

const makeSelectProductDetail = () =>
    createSelector(selectProductDetail, (substate) => {
        return substate.detail
    })

const makeSelectProductGroupAttributes = () =>
    createSelector(selectProductGroupAttributes, (substate) => {
        return substate
    })

const makeSelectProductCollection = () =>
    createSelector(selectProductCollection, (substate) => {
        return substate.data
    })

const makeFormatProductCollection = () =>
    createSelector(selectProductCollection, (substate) => {
        const item = substate.data
        if (!item) {
            return undefined
        }
        const collection: ICmsWidgetHighlightCollection = {
            css_class: 'fake-widget',
            widget_theme: CmsWidgetTheme.Dark,
            id: item!['@id'],
            widget: CmsWidgetType.HighlightCollection,
            configuration: {
                title: null,
                subtitle: null,
                items: [
                    {
                        label: item!.label,
                        products: item!.products,
                        count: item!.count,
                        url: item!.url,
                        image: {
                            placeholder: ImagePlaceholder,
                            default: item!.image.default,
                        },
                    },
                ],
            },
        }
        return collection
    })

const makeSelectProductCollectionIdentifiers = () =>
    createSelector(selectProductCollection, (substate) => {
        return substate.identifiers
    })

const makeSelectProductCollectionFetching = () =>
    createSelector(selectProductCollection, (substate) => {
        return substate.fetching
    })

const makeSelectProductGroupLinkedProductsAttributes = () =>
    createSelector(selectProductGroupAttributes, (substate) => {
        return substate.data?.linked_products
    })

const makeSelectProductCrossSellingFetching = () =>
    createSelector(selectProductCrossSelling, (substate) => {
        return substate.fetching
    })

const makeSelectProductCrossSellingItems = () =>
    createSelector(selectProductCrossSelling, (substate) => {
        return substate.items
    })

const makeSelectProductWeeklyStatsState = () =>
    createSelector(
        [
            selectProductWeeklyStats,
            (_: any, resourceId: string) => resourceId,
            (_: any, __: string, filters?: IProductStatsFilters) => filters,
        ],
        (state, resourceId, filters) => {
            const cacheKey = generateCacheKey(filters ?? {}, PRODUCT_STAT_DEFAULT_FILTER_KEY)
            return state[resourceId] ? state[resourceId][cacheKey] || undefined : undefined
        }
    )

const makeSelectProductMonthlyStatsState = () =>
    createSelector(
        [
            selectProductMonthlyStats,
            (_: any, resourceId: string) => resourceId,
            (_: any, __: string, filters?: IProductStatsFilters) => filters,
        ],
        (state, resourceId, filters) => {
            const cacheKey = generateCacheKey(filters ?? {}, PRODUCT_STAT_DEFAULT_FILTER_KEY)
            return state[resourceId] ? state[resourceId][cacheKey] || undefined : undefined
        }
    )

export default makeSelectProduct
export {
    selectProductDomain,
    makeSelectProductDetailId,
    makeSelectProductDetail,
    makeSelectProductGroupAttributes,
    makeSelectProductDetailFetching,
    makeSelectProductGroupLinkedProductsAttributes,
    makeSelectProductDetailError,
    makeSelectProductDetailGroupId,
    makeSelectProductCrossSellingFetching,
    makeSelectProductCrossSellingItems,
    makeSelectProductCollection,
    makeSelectProductCollectionFetching,
    makeSelectProductCollectionIdentifiers,
    makeFormatProductCollection,
    makeSelectProductWeeklyStatsState,
    makeSelectProductMonthlyStatsState,
}
