import { AxiosError } from 'axios'

import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import { IApiErrorCollection } from './types'

export function isAxiosError(error: any): error is AxiosError {
    return typeof (error as AxiosError).isAxiosError === 'boolean' && (error as AxiosError).isAxiosError
}

export function isAxiosTimeout(error: any): boolean {
    return error && isString(error.code) && error.code === 'ECONNABORTED'
}

export function isConstraintViolationList(data: any): data is IApiErrorCollection {
    return (
        isObject(data) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !isUndefined(data['@type']) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data['@type'] &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isString(data['@type']) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data['@type'] === 'ConstraintViolationList'
    )
}

export function generateCacheKey(obj: Record<string, any>, defaultCacheKey = 'default'): string {
    // Fonction pour trier les clés de l'objet
    function stableStringify(obj: any): string {
        if (typeof obj === 'undefined' || obj === null) {
            return defaultCacheKey
        }

        if (typeof obj !== 'object') {
            return JSON.stringify(obj)
        }

        if (Array.isArray(obj)) {
            return obj.length ? `[${obj.map(stableStringify).join(',')}]` : defaultCacheKey
        }

        if (isObject(obj) && !Object.keys(obj).length) {
            return defaultCacheKey
        }

        // Trie les clés d'un objet
        const sortedKeys = Object.keys(obj).sort()
        const sortedObj = sortedKeys.map((key) => `"${key}":${stableStringify(obj[key])}`)
        return `{${sortedObj.join(',')}}`
    }

    return stableStringify(obj)
}
