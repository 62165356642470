/*
 *
 * CartList
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import FlatIcon from '../../../components/Icon/FlatIcon'
import { ICustomer } from '../../../services/api/service/customers/types'
import { IApplicationRootState } from '../../../store'
import { cartCartLightsProcessAction } from '../../../store/carts/actions'
import {
    makeSelectCartLightDomain,
    makeSelectCartMode,
    makeSelectCartValidationMode,
} from '../../../store/carts/selectors'
import { ICartLightStates, StrictCartMode } from '../../../store/carts/types'
import { makeSelectCustomer } from '../../../store/customers/selectors'
import Entry from './Entry'
import { IListProps } from './type'
import { ICartValidationMode } from '../../../services/api/service/carts/types'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    lights: makeSelectCartLightDomain(),
    cartMode: makeSelectCartMode(),
    cartValidationMode: makeSelectCartValidationMode(),
})

function List({ cartParentId, onCartLightTitleClick, disabled, cartLightCount }: IListProps): JSX.Element {
    const dispatch = useDispatch()
    const { customer, lights, cartMode, cartValidationMode } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            lights: ICartLightStates
            cartMode: StrictCartMode
            cartValidationMode: ICartValidationMode
        }
    >(stateSelector)

    const data = lights && lights[cartParentId] ? lights[cartParentId] : undefined
    const [show, setShow] = useState<boolean>(false)
    const [currentChevron, setCurrentChevron] = useState<string>('chevron-down')

    const handleCartTitleClick = useCallback(() => {
        const newValue = !show
        onCartLightTitleClick(newValue)
        setShow(newValue)
        if (newValue) {
            dispatch(cartCartLightsProcessAction(cartParentId))
        }
    }, [show, onCartLightTitleClick, dispatch, cartParentId])

    useEffect(() => {
        setCurrentChevron(show ? 'chevron-up' : 'chevron-down')
    }, [setCurrentChevron, show])

    useEffect(() => {
        if (typeof data === 'undefined') {
            setShow(false)
        }
    }, [data, setShow])

    return (
        <Card className={classNames('single-cart-light-section', { show: show, disabled })}>
            <Card.Header>
                <Card.Title onClick={handleCartTitleClick}>
                    <span className={'lbl'}>
                        <FormattedMessage id={'cart.detail_by_store'} values={{ total: cartLightCount }} />
                    </span>
                    {!data?.fetching && <FlatIcon icon={currentChevron} />}
                    {data?.fetching && (
                        <FontAwesomeIcon className={'app-icon'} icon={['fal', 'circle-notch']} spin={true} />
                    )}
                </Card.Title>
                {data?.list && (
                    <Card.Body>
                        <ul className={'list-unstyled cart-light-list'}>
                            {data?.list?.map((child) => (
                                <li key={child['@id']}>
                                    <Entry
                                        cartParentId={cartParentId}
                                        child={child}
                                        customer={customer}
                                        cartMode={cartMode}
                                        cartValidationMode={cartValidationMode}
                                    />
                                </li>
                            ))}
                        </ul>
                    </Card.Body>
                )}
            </Card.Header>
        </Card>
    )
}

List.defaultProps = {
    cartLightCount: 0,
    disabled: false,
} as Partial<IListProps>

export default List
