/*
 *
 * CartList
 *
 */

import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { getPath } from '../../../routes'
import { ICart, ICartCollection } from '../../../services/api/service/carts/types'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    TreeMode,
} from '../../../services/api/service/classification/types'
import { ICustomer } from '../../../services/api/service/customers/types'
import { IApplicationRootState } from '../../../store'
import { makeSelectCartMode, makeSelectMainCartsList } from '../../../store/carts/selectors'
import { canPlaceOrder } from '../../../store/carts/utils'
import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../../../store/classification/selectors'
import { findShippingLocationBy } from '../../../store/classification/utils'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../../store/customers/selectors'
import CreateOrder from '../../CreateOrder/CreateOrder'
import Entry from './Entry'
import { IListProps } from './type'
import { StrictCartMode } from '../../../store/carts/types'

const stateSelector = createStructuredSelector<any, any>({
    carts: makeSelectMainCartsList(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
})

function List({ onCartTitleClick, activeCartId }: IListProps): JSX.Element {
    const { treeMode, carts, familyTree, categoryTree, customer, store, cartMode } = useSelector<
        IApplicationRootState,
        {
            carts: ICartCollection
            familyTree: IFamilyTreeCollection
            categoryTree: ICategoryTreeCollection
            customer: ICustomer
            cartMode: StrictCartMode
            store?: ICustomer
            treeMode: TreeMode
        }
    >(stateSelector)
    const history = useHistory()
    const { locale } = useIntl()
    // est ce que le client peut commander
    const cartOrderIsAvailable = useMemo(() => {
        return canPlaceOrder(customer, store, cartMode)
    }, [customer, store, cartMode])

    const tree = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return categoryTree
        }

        return familyTree
    }, [categoryTree, familyTree, treeMode])

    const handleCartBackClick = useCallback(
        (cart: ICart) => {
            const sp = findShippingLocationBy(tree, '@id', cart.shipping_location, treeMode)
            if (sp) {
                const basePath = generatePath(getPath('catalog', locale), { lang: locale })
                history.push(`${basePath}${sp.url}`)
            }
        },
        [tree, treeMode, locale, history]
    )

    return (
        <div className={'cart-list'}>
            <div className={'cart-list-content'}>
                {carts.map((cart, index) => (
                    <Entry
                        onCartTitleClick={onCartTitleClick}
                        onCartBackClick={handleCartBackClick}
                        key={`main_resume_cart_entry_${cart['@id']}`}
                        cartId={cart['@id']}
                        activeCartId={activeCartId}
                        number={index + 1}
                    />
                ))}
            </div>
            {cartOrderIsAvailable && (
                <div className={'cart-list-footer'}>
                    <CreateOrder
                        global={true}
                        mainCart={true}
                        minimumAmountMode={customer.minimum_amount_mode}
                        customer={customer}
                    />
                </div>
            )}
        </div>
    )
}

List.defaultProps = {} as Partial<IListProps>

export default List
