import { call, cancelled, getContext, put, takeLatest } from 'redux-saga/effects'
import ProductActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { productPublicGridDetailFailureAction, productGridPublicDetailSuccessAction } from './actions'
import { IProductGridPublicDetailProcessAction } from './types'
import { formatAppError } from '../app/saga'
import ReactGA from 'react-ga4'
import Config from '../../config/index'
// TOKEN
import axios from 'axios'

import { IApiProductGridPublicResponse } from '../../services/api/service/product-grid-publics/types'

const CancelToken = axios.CancelToken

function* processPlanogramDetail(action: IProductGridPublicDetailProcessAction) {
    const source = CancelToken.source()
    const resourceId: string = action.payload!.publicGridId
    const api: IApiClient = yield getContext('api')

    try {
        const response: IApiProductGridPublicResponse = yield call(
            { context: api.productGridPublics, fn: 'get' },
            resourceId,
            source.token
        )
        yield put(productGridPublicDetailSuccessAction(response))

        if (Config.GOOGLE.ANALYTICS.ACTIVE) {
            try {
                ReactGA.event({
                    category: 'ProductGridPublic',
                    action: 'show',
                    label: response.data.name,
                })
            } catch (e) {}
        }
    } catch (e) {
        const error = yield call(formatAppError, e, 'product_grid_publics.unknow_error')
        yield put(productPublicGridDetailFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [takeLatest(ProductActionTypes.DETAIL_PROCESS_ACTION, processPlanogramDetail)]
