/*
 *
 * CartList
 *
 */

import React, { useCallback, useMemo } from 'react'
import { Accordion } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { getPath } from '../../../routes'
import { ICart, ICartCollection } from '../../../services/api/service/carts/types'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    TreeMode,
} from '../../../services/api/service/classification/types'
import { ICustomer } from '../../../services/api/service/customers/types'
import { IApplicationRootState } from '../../../store'
import { makeSelectCartMode, makeSelectMainCartsList } from '../../../store/carts/selectors'
import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../../../store/classification/selectors'
import { findShippingLocationBy } from '../../../store/classification/utils'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../../store/customers/selectors'
import Entry from './Entry'
import { IListProps } from './type'
import { Easing, ScrollTo } from 'scroll-to-position'
import { StrictCartMode } from '../../../store/carts/types'
import { makeSelectOrdersMode } from '../../../store/orders/selectors'
import { IOrderMode } from '../../../services/api/service/orders/types'
import { makeSelectTreeMode } from '../../../store/config/selectors'

const stateSelector = createStructuredSelector<any, any>({
    carts: makeSelectMainCartsList(),
    cartMode: makeSelectCartMode(),
    orderMode: makeSelectOrdersMode(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    customer: makeSelectCustomer(),
    currentStore: makeSelectCustomerStore(),
    treeMode: makeSelectTreeMode(),
})

function List({ activeCartId, onAccordionItemClick }: IListProps): JSX.Element {
    const { carts, familyTree, customer, currentStore, cartMode, orderMode, categoryTree, treeMode } = useSelector<
        IApplicationRootState,
        {
            carts: ICartCollection
            cartMode: StrictCartMode
            orderMode: IOrderMode
            familyTree: IFamilyTreeCollection
            categoryTree: ICategoryTreeCollection
            customer: ICustomer
            currentStore: ICustomer | undefined
            treeMode: TreeMode
        }
    >(stateSelector)

    const tree = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return categoryTree
        }

        return familyTree
    }, [categoryTree, familyTree, treeMode])

    const shippingLocations = useMemo(() => {
        const items = {}
        carts.forEach((cart) => {
            items[cart['@id']] = findShippingLocationBy(tree, '@id', cart.shipping_location, treeMode)
        })
        return items
    }, [treeMode, tree, carts])

    const history = useHistory()
    const { locale } = useIntl()

    const handleCartBackClick = useCallback(
        (cart: ICart) => {
            const sp = findShippingLocationBy(tree, '@id', cart.shipping_location)
            if (sp) {
                const basePath = generatePath(getPath('catalog', locale), { lang: locale })
                history.push(`${basePath}${sp.url}`)
            }
        },
        [history, tree, locale]
    )

    const handleAccordionItemClick = useCallback(
        (cart: ICart, number: number, eventKey: string) => {
            const element = document.getElementById(`card_${cart['@id']}`)
            if (element) {
                setTimeout(() => {
                    ScrollTo(element, {
                        easing: Easing.easeInSine,
                        duration: [200, 250],
                    })
                }, 300)
            }
            if (onAccordionItemClick) {
                onAccordionItemClick(cart, number, eventKey)
            }
        },
        [onAccordionItemClick]
    )

    if (!shippingLocations) {
        return <></>
    }

    return (
        <div className={'cart-list'}>
            <Accordion activeKey={activeCartId}>
                {carts.map((cart, index) => (
                    <Entry
                        key={`main_cart_entry_${cart['@id']}`}
                        onAccordionItemClick={handleAccordionItemClick}
                        onCartBackClick={handleCartBackClick}
                        cart={cart}
                        cartMode={cartMode}
                        orderMode={orderMode}
                        shippingLocation={shippingLocations[cart['@id']]}
                        customer={customer}
                        currentStore={currentStore}
                        number={index + 1}
                    />
                ))}
            </Accordion>
        </div>
    )
}

List.defaultProps = {} as Partial<IListProps>

export default List
