enum ActionTypes {
    RESET_ACTION = 'app/ProductGridPublicsGridPublics/RESET',
    PERSIST_PARAMS_ACTION = 'app/ProductGridPublicsGridPublics/Params/PERSIST',
    PERSISTED_PARAMS_ACTION = 'app/ProductGridPublicsGridPublics/Params/PERSISTED',
    LIST_REFRESH_ACTION = 'app/ProductGridPublicsGridPublics/List/REFRESH',
    LIST_PROCESS_ACTION = 'app/ProductGridPublicsGridPublics/List/REQUEST_PROCESS',
    LIST_SUCCESS_ACTION = 'app/ProductGridPublicsGridPublics/List/REQUEST_SUCCESS',
    LIST_FAILURE_ACTION = 'app/ProductGridPublicsGridPublics/List/REQUEST_FAILURE',
    LIST_RESET_ACTION = 'app/ProductGridPublicsGridPublics/List/RESET',
}

export default ActionTypes
