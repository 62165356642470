import React, { memo, useCallback, useMemo } from 'react'
import { Nav } from 'react-bootstrap'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { getPath } from '../../routes'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    IMenuItem,
    IMenuItemCollection as IMenuApiItemCollection,
    MenuItemResourceType,
    TreeMode,
} from '../../services/api/service/classification/types'
import { IApplicationRootState } from '../../store'
import { ICustomer } from '../../services/api/service/customers/types'
import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
    makeSelectClassificationMenu,
} from '../../store/classification/selectors'
import { makeSelectCustomer } from '../../store/customers/selectors'
import FamilyTreeItem from './ItemType/Family/FamilyTreeItem'
import LinkItem from './ItemType/LinkItem'
import { formatDisplayableCategoryTree, formatDisplayableFamilyTree } from '../../store/classification/utils'
import { makeSelectTreeMode } from '../../store/config/selectors'
import CategoryTreeItem from './ItemType/Category/CategoryTreeItem'

interface IProps {
    id?: string
}

const stateSelector = createStructuredSelector<any, any>({
    menu: makeSelectClassificationMenu(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    customer: makeSelectCustomer(),
    treeMode: makeSelectTreeMode(),
})

function MainMenu({ id }: IProps): JSX.Element {
    const { menu, familyTree, customer, treeMode, categoryTree } = useSelector<
        IApplicationRootState,
        {
            menu: IMenuApiItemCollection
            familyTree?: IFamilyTreeCollection
            categoryTree?: ICategoryTreeCollection
            customer?: ICustomer
            treeMode: TreeMode
        }
    >(stateSelector)

    const { locale } = useIntl()
    const basePath = useMemo(() => {
        return generatePath(getPath('catalog', locale), { lang: locale })
    }, [locale])

    const handleMouseClick = useCallback((e: React.MouseEvent, item: any, itemType: any) => {
        e.currentTarget
            .closest('.main-menu')
            ?.firstElementChild?.querySelectorAll('.nav-item')
            .forEach((item) => item.classList.remove('entered'))
    }, [])

    const displayableTree = useMemo(() => {
        return formatDisplayableFamilyTree(familyTree || [], customer?.has_virtual_cart)
    }, [familyTree, customer])

    const displayableCategoryTree = useMemo(() => {
        return formatDisplayableCategoryTree(categoryTree || [], customer?.has_virtual_cart)
    }, [categoryTree, customer])

    return (
        <nav id={id} className={classNames('main-menu')}>
            {menu && (
                <Nav className={'navbar-nav'}>
                    {menu
                        .filter((itm: IMenuItem) => itm['@type'] === MenuItemResourceType.Tree)
                        .map((item: IMenuItem) => {
                            if (treeMode === TreeMode.Categories) {
                                return (
                                    <CategoryTreeItem
                                        onClick={handleMouseClick}
                                        key={`${item.type}_${item['@id']}`}
                                        item={item}
                                        basePath={basePath}
                                        categoryTree={displayableCategoryTree}
                                    />
                                )
                            }
                            return (
                                <FamilyTreeItem
                                    onClick={handleMouseClick}
                                    key={`${item.type}_${item['@id']}`}
                                    item={item}
                                    basePath={basePath}
                                    familyTree={displayableTree}
                                />
                            )
                        })}
                    {menu
                        .filter((itm: IMenuItem) => itm['@type'] !== MenuItemResourceType.Tree)
                        .map((item: IMenuItem) => {
                            return (
                                <LinkItem
                                    item={item}
                                    subItems={item.children}
                                    key={`${item.type}_${item['@id']}`}
                                    onClick={handleMouseClick}
                                />
                            )
                        })}
                </Nav>
            )}
        </nav>
    )
}

MainMenu.defaultProps = {
    id: 'desktop-menu',
    mobile: false,
} as Partial<IProps>

export default memo(MainMenu)
