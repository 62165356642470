/*
 *
 * Products
 *
 */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProductsListCanAddBulk,
    makeSelectProductsListError,
    makeSelectProductsListFetching,
    makeSelectProductsListFilters,
    makeSelectProductsListGridColumns,
    makeSelectProductsListGridErrors,
    makeSelectProductsListGridItems,
    makeSelectProductsListGridRows,
    makeSelectProductsListItems,
    makeSelectProductsListPersistSettings,
    makeSelectProductsListSelection,
    makeSelectProductsListSelectionAction,
    makeSelectProductsListTotalItems,
} from '../../store/products/selectors'
import ReactPlaceholder from 'react-placeholder'
import { Alert, Button, Form } from 'react-bootstrap'
import { default as ProductsPlaceholder } from './Placeholder'
import {
    IProductGridDataResponse,
    IProductList,
    IProductListParameters,
    IProductListPersistParameters,
    ProductsListDisplayMode,
    ProductsListMode,
} from '../../services/api/service/products/types'
import classNames from 'classnames'
import Counter from './Partial/Counter'
import { IApplicationRootState } from '../../store'
import { FormattedMessage, useIntl } from 'react-intl'
import { productAddToFavoriteAction, productRemoveToFavoriteAction } from '../../store/product/actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IAppErrorTypes } from '../../store/app/types'
import { generateProductUrl } from '../../utils/productHelper'
import { ICustomer } from '../../services/api/service/customers/types'
import {
    makeSelectClassificationAttributeDefinition,
    makeSelectClassificationCategory,
} from '../../store/classification/selectors'
import { IAttributeDefinitionCollection, ICategory } from '../../services/api/service/classification/types'
import { productListFormatQueriesToParams, productListIsMassAddToCartEligible } from '../../store/classification/utils'
import { getPath } from '../../routes'
import { ShopImportType } from '../../services/api/service/imports/types'
import Pagination from './Partial/Pagination'
import { isQuantitySelectorMultipleEnabled } from '../../store/carts/utils'
import { canUseFavorites } from '../../store/customers/utils'
import {
    productListGridStoreDefinitionAction,
    productsListProcessAction,
    productsPersistParamsAction,
    productsSelectionAddAction,
    productsSelectionAddBulkAction,
    productsSelectionRemoveAction,
    productsSelectionRemoveBulkAction,
    productsSelectionResetAction,
    productsSelectionSetAction,
} from '../../store/products/actions'

import { productListHasPublicFilters } from '../../store/products/utils'

import { default as FilterList } from './Filter/List'
import { default as MobileFilterList } from './Filter/Mobile'
import MassAddToCart from './MassAddToCart/Button/Button'

import { useProductListParsedQuery } from './useParsedQuery'
import { ProductListMode } from '../../components/Product/List/List'
import { getPickerActions } from '../../services/product/picker'
import PickerActions from '../../components/Products/Content/PickerActions'
import { IPickerAction } from '../../types/productPicker'
import PickerBanner from '../../components/Products/Content/PickerBanner'
import intersection from 'lodash/intersection'
import { makeSelectCartBulkCartQuantityFetching, makeSelectCartMode } from '../../store/carts/selectors'
import { BulkCartQuantityMode, StrictCartMode } from '../../store/carts/types'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { objectEquals } from 'object-equals'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import { MediaContext } from 'react-media-query-hoc'
import NotFoundAlert, {
    NotFoundReasonAction,
    NotFoundReasonModes,
} from '../../components/Products/Content/NotFoundAlert'
import { isSalesmanResource } from '../../store/salesmens/utils'
import ProductsListDisplayModeChoice from './Partial/ProductsListDisplayModeChoice'
import { $PropertyType } from 'utility-types'
import ProductsList from './DisplayMode/Default/List'
import ProductsGrid from './DisplayMode/Grid/List'
import { cartBulkCartQuantityProcessAction, cartBulkCartQuantityResetAction } from '../../store/carts/actions'
import Logo from '../../components/Logo/Logo'
import useProductsListDisplayMode from '../../utils/hook/useProductsListDisplayMode'
import { manageFavoriteDepartmentAction } from '../../services/product/picker/type/favorite'
import ManagerModal from '../Favorite/Classification/Manage/Loadable'
import {
    ICatalogListFilterCollection,
    ICatalogListFilterTree,
    ICatalogListUserSelectionCollection,
} from '../../services/api/service/core/types'
import { customerFilterHasSelectedValues } from '../../utils/catalog'
import ItemsPerPage from '../Catalog/Partial/ItemsPerPage'

interface IProps {
    tree?: ICatalogListFilterTree | undefined
    mode: ProductsListMode
    slug?: string
    baseUrl?: string
    onPickerActivationChange?: (active: boolean) => void
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
    listFetching: makeSelectProductsListFetching(),
    listItems: makeSelectProductsListItems(),
    listCanAddBulk: makeSelectProductsListCanAddBulk(),
    listTotalItems: makeSelectProductsListTotalItems(),
    listError: makeSelectProductsListError(),
    listSettings: makeSelectProductsListPersistSettings(),
    definition: makeSelectClassificationAttributeDefinition(),
    category: makeSelectClassificationCategory(),
    listFilters: makeSelectProductsListFilters(),
    listPickedProductIds: makeSelectProductsListSelection(),
    listPickerActionCode: makeSelectProductsListSelectionAction(),
    listProductGridRows: makeSelectProductsListGridRows(),
    listProductGridColumns: makeSelectProductsListGridColumns(),
    listProductGridItems: makeSelectProductsListGridItems(),
    listProductGridErrors: makeSelectProductsListGridErrors(),
    bulkAddToCartFetching: makeSelectCartBulkCartQuantityFetching(),
    me: makeSelectAuthMe(),
})

function Products({ tree, baseUrl, slug, mode, onPickerActivationChange }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()
    const { queries } = useProductListParsedQuery()
    const { switchDisplayMode } = useProductsListDisplayMode()
    const media: any = useContext(MediaContext)
    const [notFoundReason, setNotFoundReason] = useState<NotFoundReasonModes | undefined>(undefined)
    const [currentProductListParams, setCurrentProductListParams] = useState<IProductListParameters | undefined>(
        undefined
    )

    // redux
    const {
        listCanAddBulk,
        listFetching,
        listItems,
        listError,
        listTotalItems,
        customer,
        definition,
        category,
        listSettings,
        listFilters,
        listPickedProductIds,
        listPickerActionCode,
        listProductGridRows,
        listProductGridColumns,
        listProductGridItems,
        listProductGridErrors,
        bulkAddToCartFetching,
        cartMode,
        store,
        me,
    } = useSelector<
        IApplicationRootState,
        {
            listSettings?: IProductListPersistParameters
            listCanAddBulk?: boolean
            listFetching: boolean
            bulkAddToCartFetching: boolean
            listTotalItems: number | undefined
            listItems: Array<IProductList>
            listError?: IAppErrorTypes | undefined
            customer: ICustomer
            store?: ICustomer
            me: IMe
            cartMode: StrictCartMode
            definition: IAttributeDefinitionCollection
            category: ICategory | undefined
            listFilters?: ICatalogListFilterCollection
            listPickedProductIds: ICatalogListUserSelectionCollection
            listPickerActionCode: string
            listProductGridRows: $PropertyType<IProductGridDataResponse, 'rows'>
            listProductGridColumns: $PropertyType<IProductGridDataResponse, 'columns'>
            listProductGridItems: $PropertyType<IProductGridDataResponse, 'items'>
            listProductGridErrors: $PropertyType<IProductGridDataResponse, 'errors'>
        }
    >(stateSelector)

    // est-ce que la personne connectée est un commercial ?
    const isSalesman = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    const [favoriteDepartmentShown, setFavoriteDepartmentShown] = useState<boolean>(false)
    const pickerActions = useMemo(() => {
        return getPickerActions(mode, queries?.displayMode || ProductsListDisplayMode.Default, me, customer, cartMode)
    }, [mode, me, customer, queries?.displayMode, cartMode])

    const pikerAction = useMemo(() => {
        return pickerActions[listPickerActionCode] || undefined
    }, [pickerActions, listPickerActionCode])

    const pickerPropertyPathId = useMemo(() => {
        if (!pikerAction || !pikerAction.identifierPath) {
            return '@id'
        }
        return pikerAction.identifierPath || '@id'
    }, [pikerAction])

    const listPickedProductInPageCount = useMemo(() => {
        const identifiers = !listItems ? [] : listItems.map((listItem) => listItem[pickerPropertyPathId])
        return intersection(identifiers, listPickedProductIds).length
    }, [listItems, listPickedProductIds, pickerPropertyPathId])
    const productListMode = useMemo(() => {
        return pikerAction ? ProductListMode.Picker : ProductListMode.Default
    }, [pikerAction])

    const pickerMaxReached = useMemo(() => {
        if (!pikerAction || !pikerAction.options.max) {
            return false
        }
        return listPickedProductIds.length >= pikerAction.options.max
    }, [listPickedProductIds, pikerAction])

    const isQtySelectorMultiple = useMemo(() => {
        return isQuantitySelectorMultipleEnabled(customer, store, cartMode)
    }, [customer, store, cartMode])

    const productsListShowPlaceholder = useMemo(() => {
        if (queries?.displayMode === ProductsListDisplayMode.GridData) {
            return listFetching
        }
        return (
            typeof listTotalItems === 'undefined' &&
            typeof listError === 'undefined' &&
            typeof notFoundReason === 'undefined'
        )
    }, [listTotalItems, listError, notFoundReason, listFetching, queries?.displayMode])

    const isMassAddToCartEnabled = useMemo(() => {
        return listCanAddBulk && typeof listTotalItems === 'number' && listTotalItems > 0
    }, [listCanAddBulk, listTotalItems])

    const isMassAddToCartEligible = useMemo(() => {
        return productListIsMassAddToCartEligible(definition, customer, currentProductListParams, category)
    }, [customer, definition, category, currentProductListParams])

    const hasPublicFiltersAvailable = useMemo(() => {
        return productListHasPublicFilters(listFilters, true)
    }, [listFilters])

    const handleProductCustomerStockClick = useCallback(() => {
        const importUrl = generatePath(getPath('customerImports', locale), {
            lang: locale,
            type: ShopImportType.CustomerStock,
        })
        history.push(importUrl)
    }, [history, locale])

    const handleProductPickerCancel = useCallback(() => {
        dispatch(productsSelectionResetAction())
        if (onPickerActivationChange) {
            onPickerActivationChange(false)
        }
    }, [dispatch, onPickerActivationChange])

    const handleProductPickerActionChange = useCallback(
        (action: IPickerAction) => {
            if (action.code === manageFavoriteDepartmentAction.code) {
                setFavoriteDepartmentShown(true)
                return
            }
            dispatch(productsSelectionSetAction(action.code))
            if (onPickerActivationChange) {
                onPickerActivationChange(true)
            }
        },
        [dispatch, onPickerActivationChange, setFavoriteDepartmentShown]
    )

    const handleFavoriteDepartmentHide = useCallback(() => {
        setFavoriteDepartmentShown(false)
    }, [setFavoriteDepartmentShown])

    const handleProductPickerChange = useCallback(
        (product: IProductList, productPicked: boolean) => {
            if (productPicked) {
                // on s'assure qu'on passe pas le max.
                // Si c'est le cas, on s'arrête ici (le mesg d'erreur est affiché dans la bannière)
                if (
                    pikerAction &&
                    pikerAction.options.max &&
                    pikerAction.options.max < listPickedProductIds.length + 1
                ) {
                    return
                }
                dispatch(productsSelectionAddAction(product[pickerPropertyPathId]))
            } else {
                dispatch(productsSelectionRemoveAction(product[pickerPropertyPathId]))
            }
        },
        [dispatch, pikerAction, pickerPropertyPathId, listPickedProductIds]
    )

    const handleProductPickerSelectAllChange = useCallback(
        (checked: boolean) => {
            if (!listItems) {
                return
            }

            let items: typeof listItems = [...listItems]
            // on regarde si on a un maximum de configuré
            if (checked && pikerAction && pikerAction.options.max) {
                // on s'assure qu'on passe pas le max.
                if (pikerAction.options.max <= listPickedProductIds.length) {
                    return
                }

                // si ce n'est pas le cas, on ne récupère que le nombre possiblement ajoutable
                const remainingItemCount = pikerAction.options.max - listPickedProductIds.length

                // on récupère que les produits qui n'ont pas déjà été ajouté
                items = listItems
                    .filter((listItem) => listPickedProductIds.indexOf(listItem[pickerPropertyPathId]) === -1)
                    .slice(0, remainingItemCount)
            }

            const identifiers = items.map((listItem) => listItem[pickerPropertyPathId])
            if (checked) {
                dispatch(productsSelectionAddBulkAction(identifiers))
            } else {
                dispatch(productsSelectionRemoveBulkAction(identifiers))
            }
        },
        [dispatch, listItems, pikerAction, pickerPropertyPathId, listPickedProductIds]
    )

    const handleOnProductFavoriteChange = useCallback(
        (product: IProductList, productFavorite: boolean) => {
            if (productFavorite) {
                dispatch(productAddToFavoriteAction(product.id))
            } else {
                dispatch(productRemoveToFavoriteAction(product.id))
            }
        },
        [dispatch]
    )

    const handleGridBulkCartQuantityClick = useCallback(
        (action, definition) => {
            // stockage définition
            dispatch(productListGridStoreDefinitionAction(definition))
            // ajout au panier !
            dispatch(
                cartBulkCartQuantityProcessAction({
                    bulkMode: BulkCartQuantityMode.Grid,
                    bulkAction: action,
                    quantity: 1,
                })
            )
        },
        [dispatch]
    )

    const handleOnProductClick = useCallback(
        (product: IProductList) => {
            history.push(generateProductUrl(product.id, locale), { background: location })
        },
        [locale, location, history]
    )

    const handleNotFoundAlertClick = useCallback(
        (action: NotFoundReasonAction) => {
            if (action === NotFoundReasonAction.ResetListedOnly) {
                dispatch(productsPersistParamsAction({ listed_only: 0 }))
                return
            }
            if (!baseUrl) {
                history.push(generatePath(getPath('catalog', locale), { lang: locale }))
                return
            }
            history.push(baseUrl)
        },
        [dispatch, history, baseUrl, locale]
    )

    const handleRestoreDisplayModeDefault = useCallback(() => {
        switchDisplayMode(ProductsListDisplayMode.Default)
        dispatch(cartBulkCartQuantityResetAction())
    }, [switchDisplayMode, dispatch])

    const favoritesAllowed = useMemo(() => {
        return canUseFavorites(customer, me, store, cartMode)
    }, [customer, store, me, cartMode])

    useEffect(() => {
        const params: IProductListParameters | undefined = productListFormatQueriesToParams(
            mode,
            queries,
            tree,
            listSettings
        )
        setCurrentProductListParams((ste) => {
            if (!ste || !objectEquals(ste, params)) {
                return params
            }
            return ste
        })
    }, [mode, queries, tree, listSettings, setCurrentProductListParams])

    useEffect(() => {
        if (!currentProductListParams || (slug && !tree)) {
            return
        }
        dispatch(productsListProcessAction(mode, currentProductListParams))
    }, [dispatch, mode, tree, slug, currentProductListParams])

    // ràz de la selection au changement de mode
    useEffect(() => {
        return () => {
            handleProductPickerCancel()
        }
    }, [mode, queries?.displayMode, handleProductPickerCancel])

    useEffect(() => {
        if (slug && !tree) {
            setNotFoundReason(NotFoundReasonModes.UnknownFamilyTree)
            return
        }

        if (
            queries?.displayMode === ProductsListDisplayMode.GridData &&
            Object.keys(listProductGridItems).length === 0 &&
            Object.keys(listProductGridErrors).length === 0
        ) {
            // setNotFoundReason(NotFoundReasonModes.NoResultsFilters)
            return
        }

        if (listFetching || listTotalItems !== 0 || typeof listError !== 'undefined') {
            setNotFoundReason(undefined)
            return
        }

        if (currentProductListParams?.filters && currentProductListParams?.filters.search) {
            setNotFoundReason(NotFoundReasonModes.NoResultsSearch)
            return
        }

        if (customerFilterHasSelectedValues(listFilters, currentProductListParams)) {
            setNotFoundReason(NotFoundReasonModes.NoResultsFilters)
            return
        }

        setNotFoundReason(NotFoundReasonModes.NoResults)
    }, [
        tree,
        slug,
        currentProductListParams,
        setNotFoundReason,
        listFilters,
        listTotalItems,
        listFetching,
        listProductGridItems,
        queries?.displayMode,
        listError,
        listProductGridErrors,
    ])

    useEffect(() => {
        document.documentElement.classList.add(`display-mode-${queries?.displayMode}`)
        return () => {
            document.documentElement.className = document.documentElement.className.replace(
                /display-mode-[A-Za-z0-9_-]+/g,
                ''
            )
        }
    }, [queries?.displayMode])

    useEffect(() => {
        document.documentElement.classList.add(`product-list-member-${me['@type'].toLowerCase()}`)
        return () => {
            document.documentElement.className = document.documentElement.className.replace(
                /product-list-member-[A-Za-z0-9_-]+/g,
                ''
            )
        }
    }, [me])

    return (
        <>
            {queries?.displayMode === ProductsListDisplayMode.Default && pikerAction && (
                <PickerBanner
                    action={pikerAction}
                    items={listPickedProductIds}
                    pageItemCount={listItems.length}
                    selectedPageItemCount={listPickedProductInPageCount}
                    onSelectAllChange={handleProductPickerSelectAllChange}
                    onCancel={handleProductPickerCancel}
                />
            )}
            {favoriteDepartmentShown && (
                <ManagerModal
                    onHide={handleFavoriteDepartmentHide}
                    onExit={handleFavoriteDepartmentHide}
                    allowAssociation={false}
                    show
                />
            )}
            <div
                className={classNames(
                    'catalog-list-section',
                    'product-list-section',
                    `product-list-display-mode-${queries?.displayMode}`
                )}
                id={'product-list-section'}
            >
                {queries?.displayMode === ProductsListDisplayMode.GridData && (
                    <div className={classNames('product-list-header-grid')}>
                        <div className={classNames('product-list-header-grid-inner')}>
                            <Button variant="link" className="btn-logo" onClick={handleRestoreDisplayModeDefault}>
                                <Logo />
                            </Button>
                            <Button variant="link" className="btn-close" onClick={handleRestoreDisplayModeDefault}>
                                <FontAwesomeIcon icon={['fal', 'times']} className={'app-icon'} />
                            </Button>
                        </div>
                    </div>
                )}
                <div className={classNames('catalog-list-header', 'product-list-header', { 'has-errors': listError })}>
                    <div className={classNames('catalog-list-filter-section', 'product-list-filter-section')}>
                        {!media.mobile && hasPublicFiltersAvailable && <FilterList className={'desktop'} />}
                    </div>
                    <div
                        className={classNames('catalog-list-action-section', 'product-list-action-section', {
                            'd-none': listError,
                        })}
                    >
                        {queries?.displayMode === ProductsListDisplayMode.Default && (
                            <>
                                {!listPickerActionCode && (
                                    <PickerActions
                                        action={pikerAction?.code}
                                        actions={pickerActions}
                                        loading={listFetching}
                                        disabled={typeof listItems === 'undefined' || listItems.length === 0}
                                        onCancel={handleProductPickerCancel}
                                        onChange={handleProductPickerActionChange}
                                    />
                                )}
                                <MassAddToCart
                                    enabled={isMassAddToCartEnabled}
                                    show={isMassAddToCartEligible && isMassAddToCartEnabled}
                                />
                                <Counter loading={listFetching} count={listTotalItems} />
                            </>
                        )}
                        <div className={classNames('catalog-list-actions', 'product-list-actions')}>
                            <Form inline>
                                {media.mobile && hasPublicFiltersAvailable && <MobileFilterList />}
                                {queries?.displayMode === ProductsListDisplayMode.Default && (
                                    <ItemsPerPage loading={listFetching} count={listTotalItems} />
                                )}
                                {isSalesman && <ProductsListDisplayModeChoice />}
                            </Form>
                        </div>
                    </div>
                </div>
                <div className={classNames('catalog-list-content', 'product-list-content')}>
                    <div
                        className={classNames('catalog-list-overlay', 'product-list-overlay', {
                            showing:
                                listFetching &&
                                queries?.displayMode === ProductsListDisplayMode.Default &&
                                typeof listTotalItems !== 'undefined',
                        })}
                    >
                        <FontAwesomeIcon icon="circle-notch" spin={true} className={'app-icon'} />
                    </div>
                    <ReactPlaceholder
                        ready={!productsListShowPlaceholder}
                        customPlaceholder={<ProductsPlaceholder itemsPerPage={queries?.itemsPerPage} />}
                    >
                        <>
                            {typeof notFoundReason !== 'undefined' && (
                                <NotFoundAlert
                                    mode={mode}
                                    displayMode={queries?.displayMode}
                                    reason={notFoundReason}
                                    onClick={handleNotFoundAlertClick}
                                    queries={currentProductListParams}
                                    persistedParams={listSettings}
                                />
                            )}
                            {typeof notFoundReason === 'undefined' && listError && (
                                <Alert variant={'danger'}>
                                    <Alert.Heading>
                                        <FormattedMessage id="default.warning" />
                                    </Alert.Heading>
                                    <p className="mb-0">{listError?.message}</p>
                                </Alert>
                            )}
                            {listProductGridErrors.length > 0 && (
                                <Alert variant={'danger'}>
                                    <Alert.Heading>
                                        <FormattedMessage id="default.warning" />
                                    </Alert.Heading>
                                    {listProductGridErrors.map((listProductGridError, index) => (
                                        <p key={index} className="mb-0">
                                            {listProductGridError}
                                        </p>
                                    ))}
                                </Alert>
                            )}
                            {queries?.displayMode === ProductsListDisplayMode.Default ? (
                                <ProductsList
                                    me={me}
                                    customer={customer}
                                    items={listItems}
                                    pickerProductIds={listPickedProductIds}
                                    pickerPropertyPathId={pickerPropertyPathId}
                                    onProductClick={handleOnProductClick}
                                    onProductFavorite={handleOnProductFavoriteChange}
                                    quantitySelectorMultiple={isQtySelectorMultiple}
                                    onProductCustomerStockClick={handleProductCustomerStockClick}
                                    excludeBadges={undefined}
                                    showProductSalesmanInfo={isSalesman}
                                    onProductPickerChange={handleProductPickerChange}
                                    mode={productListMode}
                                    disabledPicker={pickerMaxReached}
                                    showFavorite={favoritesAllowed}
                                    showPrices={!customer.hide_prices}
                                    showGeneralPriceBadge={customer.general_price}
                                />
                            ) : (
                                <ProductsGrid
                                    className={classNames({
                                        'd-none':
                                            (typeof listProductGridErrors !== 'undefined' &&
                                                listProductGridErrors.length > 0) ||
                                            Object.keys(listProductGridItems).length === 0,
                                    })}
                                    bulkAddingToCart={bulkAddToCartFetching}
                                    rows={listProductGridRows}
                                    columns={listProductGridColumns}
                                    items={listProductGridItems}
                                    onMassBulkCartQuantityClick={handleGridBulkCartQuantityClick}
                                    onProductClick={handleOnProductClick}
                                    quantitySelectorMultiple={isQtySelectorMultiple}
                                    showProductSalesmanInfo={isSalesman}
                                    showPrices={!customer.hide_prices}
                                    showGeneralPriceBadge={customer.general_price}
                                />
                            )}
                        </>
                    </ReactPlaceholder>
                </div>
                {queries?.displayMode === ProductsListDisplayMode.Default && (
                    <div className={classNames('catalog-list-footer', 'product-list-footer', { 'd-none': listError })}>
                        <Pagination loading={listFetching} count={listTotalItems} />
                    </div>
                )}
            </div>
        </>
    )
}

export default Products
