import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects'
import ActionTypes from './constants'
import { IApiClient } from '../../services/api/types'
import { formatAppError } from '../app/saga'
import cloneDeep from 'lodash/cloneDeep'

import axios from 'axios'

import { makeSelectProductGridPublicsListParams, makeSelectProductGridPublicsListParamsPrev } from './selectors'
import {
    productGridPublicsListFailureAction,
    productGridPublicsListProcessAction,
    productGridPublicsListSuccessAction,
} from './actions'
import { IProductGridPublicsListProcessAction } from './types'
import {
    IProductGridPublicCollectionResponse,
    IProductGridPublicListParameters,
} from '../../services/api/service/product-grid-publics/types'

// TOKEN
const CancelToken = axios.CancelToken

function* processPlanogramsRefresh() {
    // récupération des paramètres
    const params = yield select(makeSelectProductGridPublicsListParams())

    // dispatch
    yield put(productGridPublicsListProcessAction(params))
}

function* processPlanogramsRequest(action: IProductGridPublicsListProcessAction) {
    const api: IApiClient = yield getContext('api')

    const source = CancelToken.source()
    const params = cloneDeep(action.payload.params)

    // récupération des filtres précédents
    const prevParams: IProductGridPublicListParameters | undefined = yield select(
        makeSelectProductGridPublicsListParamsPrev()
    )

    try {
        // on va chercher les produits
        const parameters = cloneDeep(params)
        const response: IProductGridPublicCollectionResponse = yield call(
            { context: api.productGridPublics, fn: 'list' },
            parameters, // dupliqué dans l'API pas besoin de spread ici
            source.token
        )

        const update = cloneDeep(params)
        yield put(productGridPublicsListSuccessAction(response.data, update))
    } catch (e) {
        const err = yield call(formatAppError, e, 'product_grid_publics.unknow_error')
        yield put(productGridPublicsListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export default [
    takeLatest(ActionTypes.LIST_REFRESH_ACTION, processPlanogramsRefresh),
    takeLatest(ActionTypes.LIST_PROCESS_ACTION, processPlanogramsRequest),
]
