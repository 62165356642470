import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Form, Modal } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Button from '../../components/Buttons/Button'
import FlatIcon from '../../components/Icon/FlatIcon'
import { ICart, RESOURCE_CART_TYPE } from '../../services/api/service/carts/types'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    IShippingLocation,
    IShippingLocationLight,
    TreeMode,
} from '../../services/api/service/classification/types'
import { ICustomer } from '../../services/api/service/customers/types'
import {
    ExportProductResourceType,
    IApiExportProductDetail,
    IApiExportProductStateList,
} from '../../services/api/service/exports/types'
import { IOrderList, RESOURCE_ORDER_TYPE } from '../../services/api/service/orders/types'
import { IApiResource } from '../../services/api/types'
import { IApplicationRootState } from '../../store'
import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../../store/classification/selectors'
import { findShippingLocationBy } from '../../store/classification/utils'
import { makeSelectCustomer } from '../../store/customers/selectors'
import {
    exportProductCheckStateProcessAction,
    exportProductCreateProcessAction,
    exportProductCreateResetSingleAction,
    exportsResetAction,
} from '../../store/exports/actions'
import { makeSelectExportCreateState, makeSelectExportProcessState } from '../../store/exports/selectors'
import { IExportProductProcessCheckState, IExportProductsSubCreateState } from '../../store/exports/types'
import { IApiErrorDescription } from '../../store/http/types'
import { useInterval } from 'use-timers-hooks'
import { makeSelectTreeMode } from '../../store/config/selectors'

interface IProps {
    show: boolean
    resource: IApiResource
    onExportModalExit: () => void
}

const stateSelector = createStructuredSelector<any, any>({
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    customer: makeSelectCustomer(),
    treeMode: makeSelectTreeMode(),
})

function ExportModal({ show, resource, onExportModalExit }: IProps): JSX.Element {
    const { formatMessage } = useIntl()
    const dispatch = useDispatch()

    const { familyTree, categoryTree, treeMode } = useSelector<
        IApplicationRootState,
        {
            familyTree: IFamilyTreeCollection
            categoryTree: ICategoryTreeCollection
            customer: ICustomer
            treeMode: TreeMode
        }
    >(stateSelector)

    const tree = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return categoryTree
        }

        return familyTree
    }, [categoryTree, familyTree, treeMode])

    // récupération de l'état de l'export du panier
    const selectCreateExportProductState = useMemo(makeSelectExportCreateState, [])
    const selectExportProductItem = useMemo(makeSelectExportProcessState, [])
    const createState: IExportProductsSubCreateState = useSelector<
        IApplicationRootState,
        IExportProductsSubCreateState
    >((state) => selectCreateExportProductState(state, resource['@id'], resource['@type']))

    const { fetching, error, itemId } = createState || {}
    const processState: IExportProductProcessCheckState | undefined = useSelector<
        IApplicationRootState,
        IExportProductProcessCheckState | undefined
    >((state) => {
        // @ts-ignore
        return selectExportProductItem(state, itemId)
    })
    const item = processState ? processState.item : undefined
    const processing =
        typeof item !== 'undefined' &&
        [IApiExportProductStateList.Processing, IApiExportProductStateList.Wait].indexOf(item.state) > -1
    // const processFetching = processState && processState.fetching
    const processReady = typeof item !== 'undefined' && [IApiExportProductStateList.Ready].indexOf(item.state) > -1
    const processError = typeof item !== 'undefined' && [IApiExportProductStateList.Error].indexOf(item.state) > -1
    // const isCustomerBuyingGroup = customer && customer.account_type === ICustomerType.BuyingGroup
    const allowAllStoreExport = false

    // récupération du shipping location si possible
    const shippingLocation: IShippingLocation | IShippingLocationLight | undefined = useMemo(() => {
        if (resource['@type'] === RESOURCE_CART_TYPE) {
            const sp = findShippingLocationBy(tree, '@id', (resource as ICart).shipping_location, treeMode)
            return sp ? sp : undefined
        } else if (resource['@type'] === RESOURCE_ORDER_TYPE) {
            return (resource as IOrderList).shipping_location
        }
        return undefined
    }, [resource, tree, treeMode])

    const modalTitle = useMemo(() => {
        if (resource['@type'] === RESOURCE_CART_TYPE) {
            return formatMessage({ id: 'cart.export' }, { shipping_location: shippingLocation?.full_name })
        } else if (resource['@type'] === RESOURCE_ORDER_TYPE) {
            return formatMessage({ id: 'order.export' }, { number: (resource as IOrderList).number })
        }
        return formatMessage({ id: 'default.export' })
    }, [formatMessage, resource, shippingLocation])

    const [appendPicture, setAppendPicture] = useState<boolean>(false)
    const [allStores, setAllStores] = useState<boolean>(false)

    const { stopInterval } = useInterval(
        () => {
            if (processing && !processReady && !processError && item) {
                dispatch(exportProductCheckStateProcessAction(item))
            }
        },
        1500,
        []
    )

    const handleSubmit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            if (!shippingLocation && resource['@type'] === RESOURCE_CART_TYPE) {
                return
            }
            dispatch(
                exportProductCreateProcessAction({
                    resourceType: resource['@type'] as ExportProductResourceType,
                    resourceId: resource['@id'],
                    appendPicture: appendPicture,
                    allStores: allStores,
                    shippingLocationId: shippingLocation ? shippingLocation['@id'] : undefined,
                })
            )
        },
        [resource, appendPicture, allStores, dispatch, shippingLocation]
    )

    const handleOnModalExited = useCallback(() => {
        stopInterval()
        dispatch(exportProductCreateResetSingleAction(resource['@type'] as ExportProductResourceType, resource['@id']))
        setTimeout(() => onExportModalExit(), 350)
    }, [onExportModalExit, stopInterval, dispatch, resource])

    const handleAppendPictureChange = useCallback(() => {
        setAppendPicture((state) => !state)
    }, [setAppendPicture])

    const handleAllStoresChange = useCallback(() => {
        setAllStores((state) => !state)
    }, [setAllStores])

    useEffect(() => {
        if (processReady) {
            setTimeout(() => handleOnModalExited(), 1000)
        }
    }, [processReady, handleOnModalExited])

    useEffect(() => {
        return () => {
            dispatch(exportsResetAction())
        }
    }, [dispatch])

    return (
        <>
            <Modal
                className={'export-modal'}
                show={show}
                // onExited={handleOnModalExited}
                onHide={handleOnModalExited}
                backdrop={fetching || processing ? 'static' : true}
                keyboard={!fetching && !processing}
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton={!fetching && !processing}>
                        <Modal.Title as={'h5'}>
                            <FlatIcon icon={'shopping-cart'} />
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {error && (
                            <Alert variant={'danger'}>
                                <Alert.Heading>
                                    <FormattedMessage id={'default.error'} />
                                </Alert.Heading>
                                {(error as IApiErrorDescription).error && (
                                    <p>{(error as IApiErrorDescription).error}</p>
                                )}
                                {error.message && <p>{error.message}</p>}
                            </Alert>
                        )}
                        {processError && (
                            <Alert variant={'danger'}>
                                <Alert.Heading>
                                    <FormattedMessage id={'default.error'} />
                                </Alert.Heading>
                                <p>
                                    <FormattedMessage id={'export.detail.error'} />
                                </p>
                            </Alert>
                        )}
                        {!processing && !item && (
                            <>
                                <p>
                                    <FormattedMessage id={'exports.append_picture_message'} values={{ br: <br /> }} />
                                </p>
                                <div className={'export-modal-form'}>
                                    {allowAllStoreExport && (
                                        <Form.Group>
                                            <Form.Check
                                                disabled={fetching}
                                                type="switch"
                                                name={'all_stores'}
                                                id={`export-modal-form-${resource['@type']}-${resource['@id']}-all_stores`}
                                                label={formatMessage({ id: 'export.all_stores' })}
                                                onChange={handleAllStoresChange}
                                                checked={allStores}
                                                value={1}
                                            />
                                        </Form.Group>
                                    )}
                                    <Form.Group>
                                        <Form.Check
                                            disabled={fetching}
                                            type="switch"
                                            name={'append_picture'}
                                            id={`export-modal-form-${resource['@type']}-${resource['@id']}-append_picture`}
                                            label={formatMessage({ id: 'export.append_picture' })}
                                            onChange={handleAppendPictureChange}
                                            checked={appendPicture}
                                            value={1}
                                        />
                                    </Form.Group>
                                </div>
                            </>
                        )}
                        {processing && (
                            <div className={'export-loading-section'}>
                                <FontAwesomeIcon icon={['fal', 'circle-notch']} spin={true} />
                                <h5 className={'title'}>
                                    <FormattedMessage id={'export.detail.processing'} />
                                </h5>
                            </div>
                        )}
                        {processReady && typeof item !== 'undefined' && (
                            <div className={'export-success-section'}>
                                <FontAwesomeIcon icon={['fal', 'check-circle']} />
                                <h5 className={'title'}>
                                    <FormattedMessage id={'export.detail.ready'} />
                                </h5>
                                <iframe
                                    src={(item as IApiExportProductDetail).url}
                                    width={0}
                                    height={0}
                                    style={{ border: '0 none' }}
                                />
                            </div>
                        )}
                    </Modal.Body>
                    {!fetching && !processing && !item && (
                        <Modal.Footer>
                            <Button type={'submit'} variant="primary" disabled={fetching} loading={fetching}>
                                <FormattedMessage id={'default.export'} />
                            </Button>
                        </Modal.Footer>
                    )}
                </Form>
            </Modal>
        </>
    )
}

ExportModal.defaultProps = {
    show: true,
} as Partial<IProps>

export default memo(ExportModal)
