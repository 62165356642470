/*
 *
 * CartListEntry
 *
 */

import classNames from 'classnames'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import CartMinimumAmountAlert from '../../../components/Carts/CartMinimumAmountAlert'
import FlatIcon from '../../../components/Icon/FlatIcon'
import Price from '../../../components/Price/Price'
import { ICart, ICartMode, ICartValidationMode } from '../../../services/api/service/carts/types'

import { makeSelectCustomer, makeSelectCustomerStore } from '../../../store/customers/selectors'
import { IApplicationRootState } from '../../../store'
import { ICustomer } from '../../../services/api/service/customers/types'

import { makeSelectCartById, makeSelectCartMode, makeSelectCartValidationMode } from '../../../store/carts/selectors'
import {
    canPlaceOrder,
    canUnlockOrder,
    cartHasErrors,
    cartIsEmpty,
    isMinimumAmountReached,
    isQuantitySelectorMultipleEnabled,
} from '../../../store/carts/utils'

import CreateOrder from '../../CreateOrder/CreateOrder'
import { default as CartLightList } from '../CartLight/List'
import { IEntryProps } from './type'
import ContainerVolumes from '../../../components/ContainerVolume/ContainerVolumes'
import { StrictCartMode } from '../../../store/carts/types'
import { IOrderMode } from '../../../services/api/service/orders/types'
import { makeSelectOrdersMode } from '../../../store/orders/selectors'
import { IMe } from '../../../services/api/service/me/types'
import { makeSelectAuthMe } from '../../../store/auth/selectors'
import { customerShowPrices } from '../../../store/customers/utils'
import ShippingLocation from '../../../components/Classification/ShippingLocation'
import CartLocker from '../CartLocker'
import { useCustomer } from '../../../utils/hook/useCustomer'
import { makeSelectTreeMode } from '../../../store/config/selectors'
import { useShippingLocation } from '../../../utils/hook/useShippingLocation'

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
    cartValidationMode: makeSelectCartValidationMode(),
    orderMode: makeSelectOrdersMode(),
    treeMode: makeSelectTreeMode(),
})

function Entry({ cartId, number, onCartTitleClick, onCartBackClick, activeCartId }: IEntryProps): JSX.Element {
    const { formatMessage } = useIntl()
    const { isNeedPriceIncludingTaxes, isPriceIncludingEcoTax } = useCustomer()
    // récupération des informations du client
    const { customer, store, cartMode, cartValidationMode, orderMode, me } = useSelector<
        IApplicationRootState,
        {
            me: IMe
            customer: ICustomer
            store?: ICustomer
            cartMode: StrictCartMode
            orderMode: IOrderMode
            cartValidationMode: ICartValidationMode
        }
    >(stateSelector)

    const [showLockerPopover, setShowLockerPopover] = useState(false)

    // récupération du panier
    const selectCartById = useMemo(makeSelectCartById, [])
    const cart: ICart = useSelector<IApplicationRootState, ICart | null>((state) =>
        selectCartById(state, cartId)
    ) as ICart

    const shippingLocation = useShippingLocation(cart?.shipping_location)

    const canUnlockOrders = useMemo(() => {
        return customer && canUnlockOrder(customer)
    }, [customer])

    // est ce qu'on affiche la liste des paniers enfants
    const showCartLight = useMemo(() => {
        return isQuantitySelectorMultipleEnabled(customer, store, cartMode)
    }, [customer, store, cartMode])

    // est ce qu'on affiche les containers
    const showContainerVolumes = useMemo(() => {
        return customer.show_container_volume && cartMode === ICartMode.Default
    }, [customer, cartMode])

    const showPrices = useMemo(() => {
        return customerShowPrices(customer, me)
    }, [customer, me])

    const showPacking = useMemo(() => {
        return customer?.order_by_unit === false
    }, [customer])

    // est ce que le client peut commander
    const cartOrderIsAvailable = useMemo(() => {
        return canPlaceOrder(customer, store, cartMode)
    }, [customer, store, cartMode])

    const handleLockerMouseEnter = useCallback(() => {
        setShowLockerPopover(true)
    }, [setShowLockerPopover])

    const handleLockerPopoverMouseEnter = useCallback(() => {
        setShowLockerPopover(true)
    }, [setShowLockerPopover])

    const handleLockerPopoverMouseLeave = useCallback(() => {
        setShowLockerPopover(false)
    }, [setShowLockerPopover])

    const handleCartLightTitleClick = useCallback(
        (show: boolean) => {
            if (show && cart['@id'] !== activeCartId) {
                onCartTitleClick(cart)
            }
        },
        [cart, activeCartId, onCartTitleClick]
    )

    const handleCartTitleClick = useCallback(() => {
        if (onCartTitleClick) {
            onCartTitleClick(cart)
        }
    }, [onCartTitleClick, cart])

    const handleCartTitleMouseLeave = useCallback(() => {
        setShowLockerPopover(false)
    }, [setShowLockerPopover])

    const handleCartBackClick = useCallback(() => {
        if (onCartBackClick) {
            onCartBackClick(cart)
        }
    }, [onCartBackClick, cart])

    const createOrderButtonLabel = useMemo(() => {
        if (cartValidationMode === ICartValidationMode.Lock) {
            return formatMessage({ id: 'cart.lock_single' }, { number })
        }
        return formatMessage({ id: `cart.validate_single_${orderMode}` }, { number })
    }, [number, formatMessage, cartValidationMode, orderMode])

    return (
        <Card
            data-id={cart['@id']}
            className={classNames('single-cart-resume', 'card', {
                'minimum-reached': isMinimumAmountReached(cart, customer, cartMode, customer.minimum_amount_mode),
                'has-errors': cartHasErrors(cart),
                'is-empty': cartIsEmpty(cart),
                locked: cart.locked,
            })}
        >
            <Card.Header onClick={handleCartTitleClick} onMouseLeave={handleCartTitleMouseLeave}>
                <Card.Title>
                    <span className={'cart-header'}>
                        {canUnlockOrders && cart.locked && (
                            <CartLocker
                                cart={cart}
                                customer={customer}
                                showPopover={showLockerPopover}
                                onMouseEnter={handleLockerMouseEnter}
                                onPopoverMouseEnter={handleLockerPopoverMouseEnter}
                                onPopoverMouseLeave={handleLockerPopoverMouseLeave}
                            />
                        )}
                        {!cart.locked && <FlatIcon icon={'warehouse'} />}
                        {number && (
                            <>
                                <span className={'cart-number'}>
                                    <FormattedMessage id={`cart.number_${orderMode}`} values={{ number }} />
                                </span>
                                <span className={'separator'}>|</span>
                            </>
                        )}
                        {shippingLocation && (
                            <ShippingLocation
                                shippingLocation={shippingLocation}
                                showLabel={false}
                                labelId={'classification.transporter'}
                            />
                        )}
                    </span>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row noGutters>
                    <Col xs={24} lg={'auto'}>
                        <p>
                            <FormattedMessage id={'cart.resume.quantity'} values={{ total: cart.total_quantity }} />
                        </p>
                        {showPacking && (
                            <p>
                                <FormattedMessage id={'cart.resume.packing'} values={{ total: cart.package_count }} />
                            </p>
                        )}
                        {showPrices && (
                            <p className="cart-total">
                                <span className={'lbl'}>
                                    <FormattedMessage
                                        id={
                                            isNeedPriceIncludingTaxes
                                                ? 'cart.total_price_including_taxes'
                                                : 'cart.total_price_excluding_taxes'
                                        }
                                    />
                                </span>
                                <Price price={cart.total} />
                            </p>
                        )}
                        {showPrices && (cart.total_eco_tax || 0) > 0 && (
                            <p className="cart-total-eco-tax">
                                <span className={'lbl'}>
                                    <FormattedMessage
                                        id={
                                            isPriceIncludingEcoTax
                                                ? 'cart.total_including_eco_tax'
                                                : 'cart.total_eco_tax'
                                        }
                                    />
                                </span>
                                <Price price={cart.total_eco_tax} />
                            </p>
                        )}
                    </Col>
                    {showPrices && (
                        <Col xs={24} lg={true}>
                            <CartMinimumAmountAlert
                                cart={cart}
                                cartMode={cartMode}
                                customer={customer}
                                store={store}
                                abbreviate={true}
                                minimumAmountMode={customer.minimum_amount_mode}
                            />
                        </Col>
                    )}
                </Row>
                {showContainerVolumes && (
                    <Row noGutters>
                        <Col>
                            <ContainerVolumes uniqId={cart['@id']} totalVolume={cart.total_volume} sm={12} lg={8} />
                        </Col>
                    </Row>
                )}
                {showCartLight && (
                    <CartLightList
                        disabled={cart.store_carts?.length === 0}
                        cartLightCount={cart.store_carts?.length}
                        cartParentId={cart['@id']}
                        onCartLightTitleClick={handleCartLightTitleClick}
                    />
                )}
            </Card.Body>
            {cartOrderIsAvailable && (
                <Card.Footer>
                    <div className={'card-footer-component'}>
                        <Row>
                            <Col xs={24} xl={true}>
                                <Button block variant={'link'} onClick={handleCartBackClick}>
                                    <FlatIcon icon={'chevron-left'} />
                                    <FormattedMessage id={'default.continue_my_shopping'} />
                                </Button>
                            </Col>
                            <Col xs={24} xl={true}>
                                <CreateOrder
                                    mainCart={true}
                                    cartNumber={number}
                                    cartId={cart['@id']}
                                    customer={customer}
                                    minimumAmountMode={customer.minimum_amount_mode}
                                    buttonLabel={createOrderButtonLabel}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card.Footer>
            )}
        </Card>
    )
}

Entry.defaultProps = {
    number: 1,
} as Partial<IEntryProps>

export default memo(Entry)
