/*
 *
 * Product reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState, IProductGridPublicDetailState } from './types'

export const initialProductGridPublicDetailState: IProductGridPublicDetailState = {
    fetching: false,
    detail: null,
}

export const initialState: ContainerState = {
    publicGridId: null,
    detail: initialProductGridPublicDetailState,
}

function planogramReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.DETAIL_RESET_ACTION:
            return {
                ...state,
                detail: initialProductGridPublicDetailState,
            }
        case ActionTypes.DETAIL_SUCCESS_ACTION:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    detail: action.payload.response.data,
                },
            }
        case ActionTypes.RESET_ACTION:
            return initialState
        default:
            return state
    }
}

export default planogramReducer
