import Qs from 'qs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import Breadcrumb, { BreadcrumbItemObject } from '../containers/Breadcrumb/Breadcrumb'
import CmsBlock from '../containers/CmsBlock/CmsBlock'
import Products from '../containers/Products/Products'
import { getPath } from '../routes'
import {
    ClassificationType,
    ICategory,
    ICategoryTreeCollection,
    IClassificationTypes,
    IFamilyTreeCollection,
    IShippingLocationCategoryTreeType,
    TreeMode,
} from '../services/api/service/classification/types'
import { CmsBlockCodeList } from '../services/api/service/cms/types'
import { ProductsListDisplayMode, ProductsListMode } from '../services/api/service/products/types'
import { IApplicationRootState } from '../store'
import { cartsBannerHideAction, cartsBannerShowAction } from '../store/carts/actions'
import {
    makeSelectClassificationCategoryTreeByMode,
    makeSelectClassificationFamilyTreeByMode,
} from '../store/classification/selectors'
import {
    findCategoryTreeItemBy,
    findFamilyTreeItemBy,
    formatCatalogListFilterTree,
    formatDisplayableCategoryTree,
    formatDisplayableFamilyTree,
} from '../store/classification/utils'
import { productsListResetAction } from '../store/products/actions'
import { removeProductPathToPathName } from '../store/products/utils'
import rtrim from '../utils/rtrim'
import { Undefinable } from 'tsdef'
import { makeSelectCustomer } from '../store/customers/selectors'
import { ICustomer } from '../services/api/service/customers/types'
import { makeSelectProductsListDisplayMode } from '../store/products/selectors'
import Sidebar from '../containers/Products/Partial/Sidebar'
import { ICatalogListFilterTree } from '../services/api/service/core/types'
import classNames from 'classnames'
import { makeSelectTreeMode } from '../store/config/selectors'
import FamilyTree from '../components/Products/Sidebar/Type/FamilyTree'
import CategoryTree from '../components/Products/Sidebar/Type/CategoryTree'

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    displayMode: makeSelectProductsListDisplayMode(),
    treeMode: makeSelectTreeMode(),
})

interface IProps {
    mode: ProductsListMode
    category?: ICategory
}

function ProductsPage({ mode, category }: IProps): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const [pickerActive, setPickerActive] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const { pathname, search } = useLocation()

    useEffect(() => {
        dispatch(cartsBannerShowAction())
        return () => {
            dispatch(productsListResetAction())
        }
    }, [dispatch])

    const { customer, displayMode, treeMode } = useSelector<
        IApplicationRootState,
        {
            customer?: ICustomer
            displayMode: ProductsListDisplayMode
            treeMode: TreeMode
        }
    >(stateSelector)

    const handlePickerActivationChange = useCallback(
        (enabled) => {
            setPickerActive(enabled)
        },
        [setPickerActive]
    )

    useEffect(() => {
        if (displayMode === ProductsListDisplayMode.GridData || pickerActive) {
            dispatch(cartsBannerHideAction())
        } else {
            dispatch(cartsBannerShowAction())
        }
    }, [dispatch, displayMode, pickerActive])

    const selectFamilyTreeWithMode = useMemo(makeSelectClassificationFamilyTreeByMode, [])
    const selectCategoryTreeWithMode = useMemo(makeSelectClassificationCategoryTreeByMode, [])
    const currentFamilyTree: Undefinable<IFamilyTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (mode !== ProductsListMode.Category && treeMode === TreeMode.Families) {
            return selectFamilyTreeWithMode(state, mode)
        }
        return undefined
    })
    const currentCategoryTree: Undefinable<ICategoryTreeCollection> = useSelector<
        IApplicationRootState,
        Undefinable<IFamilyTreeCollection>
    >((state) => {
        if (mode !== ProductsListMode.Category && treeMode === TreeMode.Categories) {
            return selectCategoryTreeWithMode(state, mode)
        }
        return undefined
    })

    const basePath: string | undefined = useMemo(() => {
        let basePathName = 'catalog'
        if (mode === ProductsListMode.BestSeller) {
            basePathName = 'bestSellers'
        } else if (mode === ProductsListMode.New) {
            basePathName = 'freshProducts'
        } else if (mode === ProductsListMode.Favorite) {
            basePathName = 'favorite'
        } else if (mode === ProductsListMode.MyListing) {
            basePathName = 'myListing'
        } else if (mode === ProductsListMode.ArrivalStocks) {
            basePathName = 'arrivalStocks'
        } else if (mode === ProductsListMode.Discount) {
            basePathName = 'discounts'
        }
        return generatePath(getPath(basePathName, locale), { lang: locale })
    }, [mode, locale])

    const productsPageSlug: string | undefined = useMemo(() => {
        if (!basePath) {
            return
        }
        // récupération du familyItem
        let itemUrl = removeProductPathToPathName(pathname)
        itemUrl = rtrim(itemUrl.replace(basePath, ''), '/')
        return itemUrl.replace('/', '').length > 0 ? itemUrl : undefined
    }, [basePath, pathname])

    const familyItem: IClassificationTypes | undefined = useMemo(() => {
        if (!productsPageSlug || productsPageSlug.length === 0 || !currentFamilyTree) {
            return
        }
        // récupération du familyItem
        return findFamilyTreeItemBy(currentFamilyTree, 'url', productsPageSlug)
    }, [currentFamilyTree, productsPageSlug])

    const categoryTreeItem: IShippingLocationCategoryTreeType | undefined = useMemo(() => {
        if (!productsPageSlug || productsPageSlug.length === 0 || !currentCategoryTree) {
            return
        }

        // récupération du familyItem
        return findCategoryTreeItemBy(currentCategoryTree, 'url', productsPageSlug)
    }, [currentCategoryTree, productsPageSlug])

    const tree: ICatalogListFilterTree | undefined = useMemo(() => {
        return formatCatalogListFilterTree(
            treeMode === TreeMode.Families
                ? category || familyItem || undefined
                : category || categoryTreeItem || undefined,
            treeMode === TreeMode.Families ? currentFamilyTree : currentCategoryTree,
            'id',
            treeMode
        )
    }, [treeMode, category, familyItem, categoryTreeItem, currentFamilyTree, currentCategoryTree])

    let seoPageTitle = formatMessage({ id: 'seo.products.title' })
    if (mode === ProductsListMode.Favorite) {
        seoPageTitle = formatMessage({ id: 'customer.favorites' })
    } else if (mode === ProductsListMode.New) {
        seoPageTitle = formatMessage({ id: 'page.fresh' })
    } else if (mode === ProductsListMode.BestSeller) {
        seoPageTitle = formatMessage({ id: 'page.best_seller' })
    } else if (mode === ProductsListMode.MyListing) {
        seoPageTitle = formatMessage({ id: 'page.my_listing' })
    } else if (mode === ProductsListMode.ArrivalStocks) {
        seoPageTitle = formatMessage({ id: 'page.arrival_stocks' })
    } else if (mode === ProductsListMode.Discount) {
        seoPageTitle = formatMessage({ id: 'page.discounts' })
    } else if (familyItem) {
        seoPageTitle = familyItem!.label
    } else if (category) {
        seoPageTitle = category.label
    }

    const items = useMemo(() => {
        let arr: Array<BreadcrumbItemObject> = []
        const parsed = Qs.parse(search.substring(1))

        if (mode === ProductsListMode.Favorite) {
            arr.push({
                label: 'page.favorite',
                href: generatePath(getPath('favorites', locale), { lang: locale }),
            })
        } else if (mode === ProductsListMode.New) {
            arr.push({
                label: 'page.fresh',
                href: generatePath(getPath('freshProducts', locale), { lang: locale }),
            })
        } else if (mode === ProductsListMode.MyListing) {
            arr.push({
                label: 'page.my_listing',
                href: generatePath(getPath('myListing', locale), { lang: locale }),
            })
        } else if (mode === ProductsListMode.BestSeller) {
            arr.push({
                label: 'page.best_seller',
                href: generatePath(getPath('bestSellers', locale), { lang: locale }),
            })
        } else if (mode === ProductsListMode.ArrivalStocks) {
            arr.push({
                label: 'page.arrival_stocks',
                href: generatePath(getPath('arrivalStocks', locale), { lang: locale }),
            })
        } else if (mode === ProductsListMode.Discount) {
            arr.push({
                label: 'page.discounts',
                href: generatePath(getPath('discounts', locale), { lang: locale }),
            })
        } else if (!category) {
            arr.push({
                label: 'products.sidebar.title',
                href: generatePath(getPath('catalog', locale), { lang: locale }),
            })
        }

        const classificationItems: Array<BreadcrumbItemObject> = []

        if (tree?.category) {
            if (category) {
                classificationItems.push({
                    label: category.label,
                    href: `${category.url}`,
                    localize: false,
                })
            }
        }

        if (categoryTreeItem) {
            categoryTreeItem.parent_ids.forEach((parentId) => {
                const s = findCategoryTreeItemBy(currentCategoryTree!, '@id', parentId)
                if (s && s['@type'] !== ClassificationType.ShippingLocation) {
                    classificationItems.push({
                        label: s.label,
                        href: `${basePath}${s.url}`,
                        localize: false,
                    })
                }
            })
            classificationItems.push({
                label: categoryTreeItem.label,
                href: `${categoryTreeItem.url}`,
                localize: false,
            })
        }

        if (currentFamilyTree) {
            if (tree?.department) {
                const itm = findFamilyTreeItemBy(currentFamilyTree, 'id', tree.department)
                if (itm) {
                    classificationItems.push({
                        label: itm.label,
                        href: `${basePath}${itm.url}`,
                        localize: false,
                    })
                }
            }

            if (tree?.family) {
                const itm = findFamilyTreeItemBy(currentFamilyTree, 'id', tree.family)
                if (itm) {
                    classificationItems.push({
                        label: itm.label,
                        href: `${basePath}${itm.url}`,
                        localize: false,
                    })
                }
            }

            if (tree?.sub_family) {
                const itm = findFamilyTreeItemBy(currentFamilyTree, 'id', tree.sub_family)
                if (itm) {
                    classificationItems.push({
                        label: itm.label,
                        href: `${basePath}${itm.url}`,
                        localize: false,
                    })
                }
            }
        }

        // ajout libellé nouveauté au dernier element
        if (
            [
                ProductsListMode.Favorite,
                ProductsListMode.New,
                ProductsListMode.ArrivalStocks,
                ProductsListMode.Discount,
            ].indexOf(mode) > -1 &&
            !parsed.search &&
            classificationItems.length > 0
        ) {
            let i18nIdentifier = 'classification.favorite_decorator'
            if (mode === ProductsListMode.New) {
                i18nIdentifier = 'classification.fresh_decorator'
            } else if (mode === ProductsListMode.MyListing) {
                i18nIdentifier = 'classification.my_listing_decorator'
            } else if (mode === ProductsListMode.ArrivalStocks) {
                i18nIdentifier = 'classification.arrival_stocks_decorator'
            } else if (mode === ProductsListMode.Discount) {
                i18nIdentifier = 'classification.discounts_decorator'
            }
            // récupération last item
            let lastItem = classificationItems.pop()
            lastItem = {
                ...lastItem!,
                label: formatMessage({ id: i18nIdentifier }, { name: lastItem!.label.toLowerCase() }),
            }
            classificationItems.push(lastItem)
        }

        // merge des tableaux
        arr = [...arr, ...classificationItems]

        // ajout recherche
        if (parsed.search) {
            arr.push({
                label: parsed.search as string,
                href: '#',
                localize: false,
            })
        }

        return arr
    }, [
        locale,
        category,
        mode,
        currentFamilyTree,
        basePath,
        tree,
        formatMessage,
        search,
        currentCategoryTree,
        categoryTreeItem,
    ])

    const familyTreeTitle = useMemo(() => {
        if (mode === ProductsListMode.BestSeller) {
            return formatMessage({ id: 'page.best_seller' })
        } else if (mode === ProductsListMode.New) {
            return formatMessage({ id: 'page.fresh' })
        } else if (mode === ProductsListMode.Favorite) {
            return formatMessage({ id: 'page.favorite' })
        } else if (mode === ProductsListMode.MyListing) {
            return formatMessage({ id: 'page.my_listing' })
        } else if (mode === ProductsListMode.ArrivalStocks) {
            return formatMessage({ id: 'page.arrival_stocks' })
        } else if (mode === ProductsListMode.Discount) {
            return formatMessage({ id: 'page.discounts' })
        }
        return undefined
    }, [mode, formatMessage])

    const handleClassificationTreeTitleClick = useCallback(() => {
        history.push(basePath)
    }, [basePath, history])

    const currentFamilyTreeDisplayable = useMemo(() => {
        if (treeMode === TreeMode.Families) {
            return formatDisplayableFamilyTree(currentFamilyTree || [], customer?.has_virtual_cart)
        }

        return []
    }, [currentFamilyTree, customer, treeMode])

    const currentCategoryTreeDisplayable = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return formatDisplayableCategoryTree(currentCategoryTree || [], customer?.has_virtual_cart)
        }

        return []
    }, [currentCategoryTree, customer, treeMode])

    return (
        <>
            <Helmet>
                <title>{seoPageTitle}</title>
                <meta
                    name="description"
                    content={formatMessage({ id: 'seo.products.description' }, { name: seoPageTitle })}
                />
            </Helmet>
            <div className={classNames('catalog-list-page', 'products-page')} id={'products-page'}>
                <Breadcrumb items={items} />
                <Container fluid>
                    <div className={classNames('catalog-list-page-container', 'products-page-container')}>
                        <Row noGutters className={classNames('catalog-list-page-content', 'products-page-content')}>
                            <Sidebar as={Col} className="col-sidebar">
                                {treeMode === TreeMode.Families && currentFamilyTreeDisplayable ? (
                                    <FamilyTree
                                        title={familyTreeTitle}
                                        basePath={basePath}
                                        tree={currentFamilyTreeDisplayable}
                                        onTitleClick={handleClassificationTreeTitleClick}
                                        useFullName={mode === ProductsListMode.BestSeller}
                                    />
                                ) : undefined}

                                {treeMode === TreeMode.Categories && currentCategoryTreeDisplayable ? (
                                    <CategoryTree
                                        title={familyTreeTitle}
                                        basePath={basePath}
                                        tree={currentCategoryTreeDisplayable}
                                        onTitleClick={handleClassificationTreeTitleClick}
                                    />
                                ) : undefined}
                                <CmsBlock identifier={CmsBlockCodeList.SidebarHighlight} lazy />
                            </Sidebar>
                            <Col className={classNames('col-catalog-list', 'col-product-list')}>
                                <Products
                                    baseUrl={basePath}
                                    mode={mode}
                                    tree={tree}
                                    slug={productsPageSlug}
                                    onPickerActivationChange={handlePickerActivationChange}
                                />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ProductsPage
