import classNames, { Value as ClassNames } from 'classnames'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { TextBlock } from 'react-placeholder/lib/placeholders'
import { TimeUnit } from '../../../services/api/service/products/types'
import ProductStatsFilters from '../../../containers/ProductStats/Common/ProductStatsFilters'

interface IProps {
    className?: ClassNames
    timeUnit?: TimeUnit
    withFilters?: boolean
    withTitle?: boolean
}

const fakeStatsNum = Array.from({ length: 5 }, (_, index) => index + 1)

function ProductStatsPlaceholder({
    className,
    timeUnit = TimeUnit.Weekly,
    withFilters = false,
    withTitle = false,
}: IProps): JSX.Element {
    return (
        <div className={classNames('product-stats', 'product-stats-placeholder', className)}>
            {withTitle || withFilters ? (
                <div className="product-stats-heading">
                    {withTitle ? (
                        <h5 className="title">
                            <FormattedMessage id="default.purchase_statistics" />
                        </h5>
                    ) : undefined}
                    {withFilters ? <ProductStatsFilters onChange={() => {}} fetching /> : undefined}
                </div>
            ) : undefined}
            <div className="table-responsive">
                <table className="table table-sm table-stripped">
                    <thead>
                        <tr>
                            <th>
                                {timeUnit === TimeUnit.Weekly ? (
                                    <FormattedMessage id="default.week" />
                                ) : (
                                    <FormattedMessage id="default.month" />
                                )}
                            </th>
                            {fakeStatsNum.map((stat) => (
                                <th key={`th-${stat}`}>
                                    <TextBlock rows={1} color={'gray'} />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <FormattedMessage id="default.quantity_parts_ordered" />
                            </th>
                            {fakeStatsNum.map((stat) => (
                                <th key={`tr-1-${stat}`}>
                                    <TextBlock rows={1} color={'gray'} />
                                </th>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default memo(ProductStatsPlaceholder)
