import { AxiosResponse, CancelToken } from 'axios'
import {
    ApiListOrderDirection,
    IApiBooleanType,
    IApiResource,
    ICollectionApiResponse,
    IPaginationApiResponse,
} from '../../types'
import { CollectionMap } from '../../../../types/common'
import { Nullable } from 'tsdef'
import { $PropertyType } from 'utility-types'
import { OrderStates, ProductOrderInProgressCollection } from '../orders/types'
import {
    CatalogListFilterProps,
    CatalogUnitType,
    IApiCatalogListFilterCollection,
    IBaseCatalogImage,
    ICatalogAttributeValue,
    ICatalogDetailImage,
    ICatalogImageCollection,
    ICatalogListImage,
    ICatalogListOrder,
    ICatalogListParameters,
} from '../core/types'
import { IPlanogramList } from '../planograms/types'
import { has } from 'lodash'
import { IProductStatsFilters } from '../../../../store/product/types'

export interface IProductAttributeValue extends ICatalogAttributeValue {}

export interface IProductAttributeCollection {
    readonly brand: IProductAttributeValue
    readonly color?: IProductAttributeValue
    readonly main_color?: IProductAttributeValue
    readonly primary_color?: IProductAttributeValue
    readonly size?: IProductAttributeValue
    readonly collection?: IProductAttributeValue
    readonly line?: IProductAttributeValue
    readonly theme?: IProductAttributeValue
    readonly weight?: number
    readonly box_weight?: number
    readonly new?: boolean
    readonly decreasing_price?: boolean
}

/*******************************
 * PRODUCT_GROUP ATTRIBUTES
 * ****************************/
export interface IProductGroupAttributeValue {
    readonly id: number
    readonly label: string
    readonly products: Array<string>
    readonly color?: string | null
    readonly image?: string | null
}

export type IProductGroupAttributeValueIdentifiers = CollectionMap<number>

export interface IProductGroupAttribute {
    readonly attribute: string
    readonly name: string
    readonly values: Array<IProductGroupAttributeValue>
}

export type IProductGroupAttributeCollection = Array<IProductGroupAttribute>

export interface IApiProductGroupAttributeCollection extends IApiResource {
    readonly linked_products?: IProductGroupAttributeCollection
}

export interface IProductCustomAttribute {
    readonly label: string
    readonly value: string
}

export type IProductCustomAttributeCollection = Array<IProductCustomAttribute>

/*******************************
 * PRODUCT IMAGES
 * ****************************/
export interface IBaseProductImage {
    readonly placeholder: string
}

export interface IProductListImage extends ICatalogListImage {}
export interface IProductDetailImage extends ICatalogDetailImage {}
export type IProductImageCollection<T extends IBaseCatalogImage> = ICatalogImageCollection<T>

/*******************************
 * PRODUCT
 * ****************************/
export interface IMinimalProduct extends IApiResource {
    readonly id: string
    readonly name: string
    readonly ean13: string
    readonly reference: string
    readonly main_picture?: IProductListImage
    readonly main_pictures?: IProductImageCollection<IProductListImage>
}

export interface IBaseProduct extends IApiResource {
    readonly id: string
    readonly name: string
    readonly ean13: string
    readonly reference: string
    readonly packing: number
    readonly favorite: boolean
    readonly main_picture?: IProductListImage
    readonly main_pictures?: IProductImageCollection<IProductListImage>
    readonly out_of_stock?: boolean
    readonly customer_stock: number | null
    readonly customer_stock_date: string | null
    readonly unit_of_sale: CatalogUnitType
    readonly has_stock_alert?: boolean
    readonly can_add_stock_alert?: boolean
    readonly arrival_date: string | null
    readonly arrival_stock: number | null
    readonly stock: number | null
    readonly eco_tax?: number | null
}

export interface IProductCatalog extends IBaseProduct {
    readonly shipping_location: string
    readonly department: string
    readonly family: string
    readonly sub_family: string
    readonly price?: number
    readonly strike_price?: number
    readonly retail_price?: number
    readonly discount?: boolean
    readonly discount_percent?: number
    readonly orderable: boolean
    readonly general_price: boolean
    readonly already_ordered: boolean
    readonly last_order_date: string | null
    readonly last_order_quantity: number | null
    readonly order_in_progress_quantity: number
    readonly last_order_state: OrderStates | null
    readonly frontend_attributes: IProductAttributeCollection
    readonly best_seller?: boolean
    readonly coming_soon?: boolean
    readonly buying_group_product?: boolean
}

export type IProductList = IProductCatalog

export type IStockAlertProduct = Pick<
    IBaseProduct,
    'id' | '@id' | '@type' | 'name' | 'reference' | 'ean13' | 'main_pictures' | 'has_stock_alert'
>
export type IStoreQuantityProduct = Omit<
    IProductCatalog,
    | 'department'
    | 'family'
    | 'sub_family'
    | 'orderable'
    | 'frontend_attributes'
    | 'already_ordered'
    | 'customer_stock'
    | 'customer_stock_date'
    | 'price'
    | 'last_order_quantity'
    | 'last_order_date'
    | 'last_order_state'
    | 'order_in_progress_quantity'
    | 'general_price'
> & {
    price?: number | undefined
}

export interface IProductDetail extends IProductList {
    readonly description: string | null
    readonly group: string | null
    readonly custom_attributes: IProductCustomAttributeCollection
    readonly pictures: IProductImageCollection<IProductDetailImage>
    readonly orders_in_progress: ProductOrderInProgressCollection
    readonly planograms: Array<IPlanogramList>
}

export interface IProductCollectionImage {
    placeholder: string
    default: string
}

export interface IProductCollection extends IApiResource {
    readonly label: string
    readonly image: IProductCollectionImage
    readonly url: string
    readonly count: number
    readonly products: Array<IProductList>
}

export type ProductCollection<T = IBaseProduct> = Array<T>
export type ProductType<K extends IBaseProduct> = K
export type ProductStockAlertCollection = ProductCollection<IStockAlertProduct>

export interface ISearchByImage extends IApiResource {
    results: Array<IProductList>
}

// SALESMAN GRID
export interface IProductsListGridDefinition extends IApiResource {
    readonly key: string
    readonly label: string
    readonly color: Nullable<string>
    readonly children: Array<IProductsListGridDefinition>
}

export type IProductsListGridDefinitionCollection = Array<IProductsListGridDefinition>
export type IApiProductGrid = IApiResource & {
    readonly label: string
}
export type IProductGridDataItems = Record<string, Array<IProductList>>

export type IApiProductGridCollection = Array<IApiProductGrid>
export type IProductGridDataResponse = IApiResource & {
    readonly rows: IProductsListGridDefinitionCollection
    readonly columns: IProductsListGridDefinitionCollection
    readonly items: IProductGridDataItems
    readonly errors: Array<string>
} & IApiProductListFilterProps

export enum TimeUnit {
    Weekly = 'weekly',
    Monthly = 'monthly',
}

export type IProductStat = IApiResource & {
    readonly year: number
    readonly product: string
    readonly shipped_qty: number
    readonly ordered_qty: number
    readonly current?: boolean
}

export type IProductWeeklyStat = IProductStat & {
    readonly week: number
}

export type IProductMonthlyStat = IProductStat & {
    readonly month: number
}

export function isProductStat(obj: any): obj is IProductStat {
    return typeof obj === 'object' && has(obj, 'product') && has(obj, 'shipped_qty') && has(obj, 'ordered_qty')
}

export function isProductWeeklyStat(obj: any): obj is IProductWeeklyStat {
    return isProductStat(obj) && has(obj, 'week') && has(obj, 'year')
}

export function isProductMonthlyStat(obj: any): obj is IProductMonthlyStat {
    return isProductStat(obj) && has(obj, 'month') && has(obj, 'year')
}

export type ProductWeeklyMonthlyStatCollection = Array<IProductWeeklyStat | IProductMonthlyStat>
export type ProductWeeklyStatCollection = Array<IProductWeeklyStat>
export type ProductMonthlyStatCollection = Array<IProductMonthlyStat>

/*******************************
 * PRODUCT LIST PARAMS & RESPONSE
 * ****************************/
// Liste des paramètres (en dehors des filtres qui sont dynamiques et ceux de la pagination qui sont définis ailleurs) qui font partie des query
export enum ProductListQueryName {
    DisplayMode = 'displayMode',
    ProductGrid = 'productGrid',
}

// Liste des filtres statiques que l'on ajoute en sus de ceux renvoyés par l'API
export enum ProductListStaticFilterCodes {
    Price = 'price',
    ListedOnly = 'listed_only',
    Order = 'order',

    Search = 'search',
    Favorite = 'favorite',
    MyListing = 'buying_group_products',
    BestSellers = 'best_sellers',
    ArrivalStocks = 'arrival_stocks',
    Discount = 'discount',
    New = 'new',
}

// Type de tri dispo
export enum ProductListOrderType {
    Price = 'price',
    Position = 'position',
}

// objet de tri
export interface IProductListOrder extends ICatalogListOrder<ProductListOrderType> {
    direction: ApiListOrderDirection
}

// Type de liste possible
export enum ProductsListDisplayMode {
    // mode grillé par défaut pour les clients
    Default = 'default',
    // faisant référence à la grille des commerciaux
    GridData = 'grid_data',
}

// Mode de liste possible
export enum ProductsListMode {
    Default = 'default',
    Category = 'search',
    Favorite = 'favorite',
    MyListing = 'buying_group_products',
    BestSeller = 'best_seller',
    New = 'new',
    ArrivalStocks = 'arrival_stocks',
    Discount = 'discount',
    // fait référence à la grille des clients
    PublicGridData = 'public_grid_data',
}

export interface IProductListParameters extends ICatalogListParameters {
    mode?: ProductsListMode
    displayMode?: ProductsListDisplayMode
    productGridId?: string
    exclude_product?: Array<string>
}

export type IProductListPersistParameters = {
    listed_only?: IApiBooleanType | undefined
}

export type IProductListFiltersParameters = IProductListParameters

export type IApiProductListFilterProps = CatalogListFilterProps & {
    can_add_bulk?: boolean
}

export type IProductStatCollectionApiResponse<T> = ICollectionApiResponse<T> & {
    previous_date?: null | string
    next_date?: null | string
}

export type IApiProductListPagination = IPaginationApiResponse<Array<IProductList>> & IApiProductListFilterProps
export type IApiProductWeeklyStatCollection = IProductStatCollectionApiResponse<ProductWeeklyStatCollection>
export type IApiProductMonthlyStatCollection = IProductStatCollectionApiResponse<ProductMonthlyStatCollection>

export type IApiProductSearchByImageResponse = AxiosResponse<ISearchByImage>
export type IApiProductListResponse = AxiosResponse<IApiProductListPagination>
export type IApiProductWeeklyStatsResponse = AxiosResponse<IApiProductWeeklyStatCollection>
export type IApiProductMonthlyStatsResponse = AxiosResponse<IApiProductMonthlyStatCollection>
export type IApiProductResponse = AxiosResponse<IProductDetail>
export type IApiProductCollectionResponse = AxiosResponse<IProductCollection>
export type IApiProductGroupResponse = AxiosResponse<IApiProductGroupAttributeCollection>
export type IApiProductListFilterSelectorCollection = ICollectionApiResponse<IApiCatalogListFilterCollection>
export type IApiProductListFilterResponse = AxiosResponse<IApiProductListFilterSelectorCollection>
export type IApiProductGridApiCollection = ICollectionApiResponse<IApiProductGridCollection>

export type IApiViewedProductsPagination = IPaginationApiResponse<Array<IProductList>>
export type IApiViewedProductsResponse = AxiosResponse<IApiViewedProductsPagination>
export type IApiProductGridProductsResponse = AxiosResponse<IProductGridDataResponse>
export type IApiProductGridCollectionResponse = AxiosResponse<IApiProductGridApiCollection>
export type IApiProductListResponseTypes = IApiProductListResponse | IApiProductGridProductsResponse
export type IApiProductListResponseDataTypes =
    | $PropertyType<IApiProductListResponse, 'data'>
    | $PropertyType<IApiProductGridProductsResponse, 'data'>

export interface IProductsService {
    searchByImage(
        data: any,
        onUploadProgress?: (progressEvent: any) => void,
        cancelToken?: CancelToken,
        headers?: any
    ): Promise<IApiProductSearchByImageResponse>
    list(parameters: IProductListParameters, cancelToken?: CancelToken): Promise<IApiProductListResponse>
    filters(
        parameters: IProductListFiltersParameters,
        cancelToken?: CancelToken
    ): Promise<IApiProductListFilterResponse>
    get(productId: string, cancelToken?: CancelToken): Promise<IApiProductResponse>
    getWeeklyStats(
        productId: string,
        queries?: IProductStatsFilters,
        cancelToken?: CancelToken
    ): Promise<IApiProductWeeklyStatsResponse>
    getMonthlyStats(
        productId: string,
        queries?: IProductStatsFilters,
        cancelToken?: CancelToken
    ): Promise<IApiProductMonthlyStatsResponse>
    productGroup(
        productGroupId: string,
        listed_only?: IApiBooleanType,
        cancelToken?: CancelToken
    ): Promise<IApiProductGroupResponse>
    productCollection(
        productCollectionId: string,
        listed_only?: IApiBooleanType,
        cancelToken?: CancelToken
    ): Promise<IApiProductCollectionResponse>
    productTheme(
        productThemeId: string,
        listed_only?: IApiBooleanType,
        cancelToken?: CancelToken
    ): Promise<IApiProductCollectionResponse>
    viewedProducts(): Promise<IApiViewedProductsResponse>
    productCrossSell(productId: string, params: any, cancelToken?: CancelToken): Promise<IApiProductListResponse>
    productGrids(): Promise<IApiProductGridCollectionResponse>
    productGridProducts(
        identifier: string,
        parameters: IProductListParameters,
        cancelToken?: CancelToken
    ): Promise<IApiProductGridProductsResponse>
}
