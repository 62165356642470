/*
 *
 * ProductGridPublic constants
 *
 */

enum ActionTypes {
    RESET_ACTION = 'app/ProductGridPublic/RESET',
    DETAIL_PROCESS_ACTION = 'app/ProductGridPublic/Detail/REQUEST_PROCESS',
    DETAIL_SUCCESS_ACTION = 'app/ProductGridPublic/Detail/REQUEST_SUCCESS',
    DETAIL_FAILURE_ACTION = 'app/ProductGridPublic/Detail/REQUEST_FAILURE',
    DETAIL_RESET_ACTION = 'app/ProductGridPublic/Detail/RESET',
}

export default ActionTypes
