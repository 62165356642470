import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import DOMPurify from 'dompurify'
import { TreeMode } from '../../services/api/service/classification/types'

/**
 * Direct selector to the config state domain
 */

const selectConfigDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.config || initialState
}

const selectConfigRemoteDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.config.remote || initialState.remote
}

const selectConfigSalesmanDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.config.salesman || initialState.salesman
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Config
 */

const makeSelectRemoteConfig = () =>
    createSelector(selectConfigDomain, (substate) => {
        return substate
    })

const makeSelectSalesmanConfig = () =>
    createSelector(selectConfigSalesmanDomain, (substate) => {
        return substate
    })

const makeSelectPhoneNumber = () =>
    createSelector(selectConfigRemoteDomain, (substate) => {
        return substate.phone_number
    })

const makeSelectTreeMode = () =>
    createSelector(selectConfigRemoteDomain, (substate) => {
        return substate.tree_mode || TreeMode.Families
    })

const makeSelectAppName = () =>
    createSelector(selectConfigRemoteDomain, (substate) => {
        return substate.app_name
    })

const makeSelectOpeningHours = () =>
    createSelector(selectConfigRemoteDomain, (substate) => {
        return substate.opening_hours
    })

const makeSelectLoginHelpMessageRaw = () =>
    createSelector(selectConfigRemoteDomain, (substate) => {
        return substate.login_help_message
    })

const makeSelectLoginHelpMessage = () =>
    createSelector(selectConfigRemoteDomain, (substate) => {
        return substate.login_help_message ? DOMPurify.sanitize(substate.login_help_message) : null
    })

export default makeSelectRemoteConfig
export {
    selectConfigDomain,
    selectConfigRemoteDomain,
    makeSelectSalesmanConfig,
    makeSelectPhoneNumber,
    makeSelectAppName,
    makeSelectOpeningHours,
    makeSelectLoginHelpMessage,
    makeSelectLoginHelpMessageRaw,
    makeSelectTreeMode,
}
