import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'
import { findFamilyTreeItemBy, findShippingLocationBy } from './utils'
import { ProductsListMode } from '../../services/api/service/products/types'
import { FamilyTreeKeys } from './types'
import { makeSelectTreeMode } from '../config/selectors'
import { TreeMode } from '../../services/api/service/classification/types'

/**
 * Direct selector to the classification state domain
 */

const selectClassificationDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.classification || initialState
}

const selectClassificationCategoryDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.classification.category || initialState.category
}

const selectClassificationFamilyTreeDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.classification.tree || initialState.tree
}

const selectClassificationCategoryTreeDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.classification.category_tree || initialState.category_tree
}

const selectClassificationFavoriteDepartmentDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.classification.favoriteDepartments || initialState.favoriteDepartments
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Classification
 */

const makeSelectClassificationFamilyTree = () =>
    createSelector(selectClassificationFamilyTreeDomain, (substate) => {
        return substate
    })

const makeSelectClassificationFamilyTreeDefault = () =>
    createSelector(selectClassificationFamilyTreeDomain, (substate) => {
        return substate[ProductsListMode.Default] && substate[ProductsListMode.Default].tree
            ? substate[ProductsListMode.Default].tree
            : undefined
    })

const makeSelectClassificationCategoryTree = () =>
    createSelector(selectClassificationCategoryTreeDomain, (substate) => {
        return substate
    })

const makeSelectClassificationCategoryTreeDefault = () =>
    createSelector(selectClassificationCategoryTreeDomain, (substate) => {
        return substate[ProductsListMode.Default] && substate[ProductsListMode.Default].tree
            ? substate[ProductsListMode.Default].tree
            : undefined
    })

const makeSelectClassificationCategoryTreeByMode = () =>
    createSelector([makeSelectClassificationCategoryTree(), (_: any, mode: FamilyTreeKeys) => mode], (tree, mode) => {
        if (!tree || !mode) {
            return null
        }
        return tree[mode] && tree[mode].tree ? tree[mode].tree : undefined
    })

const makeSelectClassificationMenu = () =>
    createSelector(selectClassificationDomain, (substate) => {
        return substate.menu
    })

const makeSelectClassificationAttributeDefinition = () =>
    createSelector(selectClassificationDomain, (substate) => {
        return substate.attributes
    })

const makeSelectFamilyTreeItemFromId = () =>
    createSelector(
        [makeSelectClassificationFamilyTreeDefault(), (_: any, classificationId?: string | null) => classificationId],
        (familyTreeCollection, classificationId) => {
            if (!familyTreeCollection && !classificationId) {
                return undefined
            }

            return findFamilyTreeItemBy(familyTreeCollection, '@id', classificationId)
        }
    )

const makeSelectShippingLocationById = () =>
    createSelector(
        [
            makeSelectClassificationFamilyTreeDefault(),
            makeSelectClassificationCategoryTreeDefault(),
            makeSelectTreeMode(),
            (_: any, shippingLocationId: string | null | undefined) => shippingLocationId,
        ],
        (familyTreeCollection, categoryTreeCollection, treeMode, shippingLocationId) => {
            const tree = treeMode === TreeMode.Families ? familyTreeCollection : categoryTreeCollection
            if (!tree || !shippingLocationId) {
                return null
            }

            return findShippingLocationBy(tree, '@id', shippingLocationId, treeMode)
        }
    )

const makeSelectClassificationCategoryFetching = () =>
    createSelector(selectClassificationCategoryDomain, (substate) => {
        return substate.fetching
    })

const makeSelectClassificationCategoryError = () =>
    createSelector(selectClassificationCategoryDomain, (substate) => {
        return substate.error
    })

const makeSelectClassificationCategory = () =>
    createSelector(selectClassificationCategoryDomain, (substate) => {
        return substate.detail
    })

const makeSelectClassificationFamilyTreeByMode = () =>
    createSelector([makeSelectClassificationFamilyTree(), (_: any, mode: FamilyTreeKeys) => mode], (tree, mode) => {
        if (!tree || !mode) {
            return null
        }
        return tree[mode] && tree[mode].tree ? tree[mode].tree : undefined
    })

const makeSelectClassificationFamilyTreeFetchingByMode = () =>
    createSelector([makeSelectClassificationFamilyTree(), (_: any, mode: FamilyTreeKeys) => mode], (tree, mode) => {
        if (!tree || !mode) {
            return null
        }
        return tree[mode] && tree[mode].fetching ? tree[mode].fetching : false
    })

const makeSelectClassificationFamilyTreeErrorByMode = () =>
    createSelector([makeSelectClassificationFamilyTree(), (_: any, mode: FamilyTreeKeys) => mode], (tree, mode) => {
        if (!tree || !mode) {
            return null
        }
        return tree[mode] && tree[mode].error ? tree[mode].error : undefined
    })

const makeSelectClassificationFavoriteDepartmentListFetching = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.list.fetching || false
    })
const makeSelectClassificationFavoriteDepartmentListItems = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.list.items || undefined
    })
const makeSelectClassificationFavoriteDepartmentListError = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.list.error || undefined
    })

const makeSelectClassificationFavoriteDepartmentEditFetching = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.edit.fetching || false
    })
const makeSelectClassificationFavoriteDepartmentEditError = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.edit.error || undefined
    })

const makeSelectClassificationFavoriteDepartmentDeleteFetching = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.delete.fetching || false
    })
const makeSelectClassificationFavoriteDepartmentDeleteError = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.delete.error || undefined
    })

const makeSelectClassificationFavoriteDepartmentAssociateFetching = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.associate.fetching || false
    })
const makeSelectClassificationFavoriteDepartmentAssociateError = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.associate.error || undefined
    })

const makeSelectClassificationFavoriteDepartmentAssociateSuccess = () =>
    createSelector(selectClassificationFavoriteDepartmentDomain, (substate) => {
        return substate.associate.success || false
    })

export default selectClassificationDomain
export {
    makeSelectClassificationMenu,
    makeSelectClassificationFamilyTreeDefault,
    makeSelectShippingLocationById,
    makeSelectFamilyTreeItemFromId,
    makeSelectClassificationAttributeDefinition,
    makeSelectClassificationCategoryFetching,
    makeSelectClassificationCategoryError,
    makeSelectClassificationCategory,
    makeSelectClassificationFamilyTreeByMode,
    makeSelectClassificationFamilyTreeFetchingByMode,
    makeSelectClassificationFamilyTreeErrorByMode,
    makeSelectClassificationFamilyTree,
    makeSelectClassificationFavoriteDepartmentListFetching,
    makeSelectClassificationFavoriteDepartmentListItems,
    makeSelectClassificationFavoriteDepartmentListError,
    makeSelectClassificationFavoriteDepartmentEditFetching,
    makeSelectClassificationFavoriteDepartmentEditError,
    makeSelectClassificationFavoriteDepartmentAssociateFetching,
    makeSelectClassificationFavoriteDepartmentAssociateError,
    makeSelectClassificationFavoriteDepartmentAssociateSuccess,
    makeSelectClassificationFavoriteDepartmentDeleteFetching,
    makeSelectClassificationFavoriteDepartmentDeleteError,
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationCategoryTreeByMode,
}
