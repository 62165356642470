import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import ProductGridPublics from '../containers/ProductGridPublics/Loadable'
import classNames from 'classnames'
import { Col, Container, Row } from 'react-bootstrap'
import Breadcrumb from '../containers/Breadcrumb/Breadcrumb'
import { generatePath } from 'react-router-dom'
import { getPath } from '../routes'
// import Sidebar from '../containers/Planograms/Partial/Sidebar'
// import CmsBlock from '../containers/CmsBlock/CmsBlock'
// import { CmsBlockCodeList } from '../services/api/service/cms/types'

export default function ProductGridsPage() {
    const { formatMessage, locale } = useIntl()

    const title = useMemo(() => {
        return formatMessage({ id: 'product_grid_publics.seo_title' })
    }, [formatMessage])

    const items = useMemo(() => {
        return [
            {
                label: 'product_grid_publics.seo_title',
                href: generatePath(getPath('productGridPublics', locale), { lang: locale }),
            },
        ]
    }, [locale])

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Breadcrumb items={items} />
            <div
                className={classNames('catalog-list-page', 'product-grid-publics-page')}
                id={'product-grid-publics-page'}
            >
                <Container fluid={'md'}>
                    <div className="catalog-list-page-container">
                        <Row noGutters className={'catalog-list-page-content'}>
                            {/*<Sidebar as={Col} className="col-sidebar">*/}
                            {/*    <CmsBlock identifier={CmsBlockCodeList.SidebarHighlight} lazy />*/}
                            {/*</Sidebar>*/}
                            <Col className={'col-catalog-list'}>
                                <ProductGridPublics />
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
