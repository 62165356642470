/*
 *
 * Carts
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { ICart, ICartCollection } from '../../services/api/service/carts/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { IApplicationRootState } from '../../store'
import { cartCartLightsResetAction, cartFetchCartsAction } from '../../store/carts/actions'
import { makeSelectMainCartsList, makeSelectMainCartsListIsEmpty } from '../../store/carts/selectors'
import { cartsDefaultActiveKey } from '../../store/carts/utils'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../store/customers/selectors'
import List from './List/List'
import Sidebar from './Sidebar/Sidebar'

import {
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../../store/classification/selectors'
import {
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    TreeMode,
} from '../../services/api/service/classification/types'
import { makeSelectTreeMode } from '../../store/config/selectors'

interface IProps {
    defaultActiveKey?: string
}

const stateSelector = createStructuredSelector<any, any>({
    carts: makeSelectMainCartsList(),
    cartsEmpty: makeSelectMainCartsListIsEmpty(),
    currentStore: makeSelectCustomerStore(),
    customer: makeSelectCustomer(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    treeMode: makeSelectTreeMode(),
})

function Carts(): JSX.Element {
    const dispatch = useDispatch()
    const { formatMessage } = useIntl()

    const { carts, cartsEmpty, currentStore, customer, familyTree, categoryTree, treeMode } = useSelector<
        IApplicationRootState,
        {
            carts: ICartCollection
            cartsEmpty: boolean
            currentStore: ICustomer | undefined
            customer: ICustomer | undefined
            familyTree: IFamilyTreeCollection | undefined
            categoryTree: ICategoryTreeCollection | undefined
            treeMode: TreeMode
        }
    >(stateSelector)

    const [activeCartId, setActiveCartId] = useState<string | undefined>(cartsDefaultActiveKey(carts))
    const handleOnAccordionItemClick = useCallback(
        (cart: ICart) => {
            setActiveCartId((state) => (cart['@id'] !== state ? cart['@id'] : 'nope'))
        },
        [setActiveCartId]
    )

    const currentTree = useMemo(() => {
        if (treeMode === TreeMode.Families) {
            return familyTree
        } else if (treeMode === TreeMode.Categories) {
            return categoryTree
        }
    }, [categoryTree, familyTree, treeMode])

    useEffect(() => {
        if (typeof currentTree === 'undefined') {
            return
        }

        dispatch(cartFetchCartsAction())
        dispatch(cartCartLightsResetAction())
    }, [dispatch, currentTree])

    if (!customer) {
        return <></>
    }

    return (
        <div className={'cart-list-section'}>
            <Row noGutters className={'cart-list-content'}>
                <Col className={'col-cart-list'}>
                    {currentStore && (
                        <Alert variant={'info'}>
                            <strong>
                                {formatMessage(
                                    { id: 'carts.logged_with_store' },
                                    {
                                        business_name: currentStore?.business_name,
                                        total: carts.length,
                                    }
                                )}
                            </strong>
                        </Alert>
                    )}
                    {cartsEmpty && (
                        <Alert variant={'warning'}>
                            {formatMessage(
                                { id: 'carts.empty' },
                                {
                                    total: carts.length,
                                }
                            )}
                        </Alert>
                    )}
                    {!cartsEmpty && carts.length > 0 && typeof currentTree !== 'undefined' && (
                        <List activeCartId={activeCartId} onAccordionItemClick={handleOnAccordionItemClick} />
                    )}
                </Col>
                {!cartsEmpty && carts.length > 0 && typeof currentTree !== 'undefined' && (
                    <Col className={'col-sidebar'}>
                        <Sidebar activeCartId={activeCartId} onCartTitleClick={handleOnAccordionItemClick} />
                    </Col>
                )}
            </Row>
        </div>
    )
}

Carts.defaultProps = {
    defaultActiveKey: undefined,
} as Partial<IProps>

export default Carts
