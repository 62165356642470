/****************************
 * WIDGETS
 ****************************/

import { IProductList } from '../products/types'
import { CollectionMap } from '../../../../types/common'
import { AxiosResponse } from 'axios'
import { IApiResource } from '../../types'
import { $PropertyType } from 'utility-types'

export enum CmsWidgetType {
    LeaderBoard = 'leaderboard',
    InteractiveSlide = 'interactive_slide',
    HighlightCategory = 'highlight_category',
    HighlightCollection = 'highlight_collection',
    Divider = 'divider',
    CategoryInspiration = 'category_inspiration',
    Inspiration = 'inspiration',
    SimpleHighlight = 'simple_highlight',
    Reinsurance = 'reinsurance',
    Wysiwyg = 'wysiwyg',
    Video = 'video',
}

export interface ICmsWidgetLink {
    url: string
    blank: boolean
    internal: boolean
}

export enum CmsWidgetTheme {
    Default = 'default',
    Light = 'light',
    Dark = 'dark',
}

export interface ICmsWidgetImage {
    placeholder: string
    default: string
}

export type IBaseCmsWidgetConfiguration = {}

export interface IBaseCmsWidget<T = IBaseCmsWidgetConfiguration, K = CmsWidgetType> {
    readonly id: string
    readonly widget: K
    readonly widget_theme: CmsWidgetTheme | null
    readonly css_class: string | null
    readonly configuration: T
}

/******* HIGHLIGHT COLLECTION ************/
export type ICmsWidgetHighlightCollectionItem = {
    readonly label: string
    readonly image: ICmsWidgetImage
    readonly url: string
    readonly count: number
    readonly products: Array<IProductList>
}

export type ICmsWidgetHighlightCollectionConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly subtitle: string | null
    readonly items: Array<ICmsWidgetHighlightCollectionItem>
}

export type ICmsWidgetHighlightCollection = IBaseCmsWidget<
    ICmsWidgetHighlightCollectionConfiguration,
    CmsWidgetType.HighlightCollection
>

/******* HIGHLIGHT CATEGORY ************/
export type ICmsWidgetHighlightCategoryItem = {
    readonly name: string
    readonly url: string
    readonly button_label: string | null
    readonly products: Array<IProductList>
}

export type ICmsWidgetHighlightCategoryConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly subtitle: string | null
    readonly categories: Array<ICmsWidgetHighlightCategoryItem>
}

export type ICmsWidgetHighlightCategory = IBaseCmsWidget<
    ICmsWidgetHighlightCategoryConfiguration,
    CmsWidgetType.HighlightCategory
>

/******* LEADERBOARD ************/
export type ICmsWidgetLeaderBoardSlide = {
    readonly title: string
    readonly link: ICmsWidgetLink | null
    readonly banner_background_color: string | null
    readonly banner_text_color: string | null
    readonly image: ICmsWidgetImage
}

export type ICmsWidgetLeaderBoardConfiguration = IBaseCmsWidgetConfiguration & {
    readonly slides: Array<ICmsWidgetLeaderBoardSlide>
}

export type ICmsWidgetLeaderBoard = IBaseCmsWidget<ICmsWidgetLeaderBoardConfiguration, CmsWidgetType.LeaderBoard>

/******* Interactive slider ************/
export type ICmsWidgetInteractiveSlideTag = {
    readonly position_x: number
    readonly position_y: number
    readonly product: IProductList
}

export type ICmsWidgetInteractiveSlideTagCollection = Array<ICmsWidgetInteractiveSlideTag>
export type ICmsWidgetInteractiveSlideProduct = $PropertyType<
    $PropertyType<ICmsWidgetInteractiveSlide, 'tags'>[0],
    'product'
>
export type ICmsWidgetInteractiveSlide = ICmsWidgetLeaderBoardSlide & {
    tags: ICmsWidgetInteractiveSlideTagCollection
}

export type ICmsWidgetInteractiveSliderConfiguration = IBaseCmsWidgetConfiguration & {
    readonly slides: Array<ICmsWidgetInteractiveSlide>
}

export type ICmsWidgetInteractiveSlider = IBaseCmsWidget<
    ICmsWidgetInteractiveSliderConfiguration,
    CmsWidgetType.InteractiveSlide
>

/******* Divider ************/
export enum CmsWidgetDividerType {
    Image = 'image',
    Color = 'color',
}

export type ICmsWidgetDividerConfiguration = IBaseCmsWidgetConfiguration & {
    readonly type: CmsWidgetDividerType
    image: ICmsWidgetImage | null
    color: string | null
    height: number
}

export type ICmsWidgetDivider = IBaseCmsWidget<ICmsWidgetDividerConfiguration, CmsWidgetType.Divider>

/******* Category Inspiration ************/
export interface ICMsWidgetCategoryInspirationItem {
    readonly label: string
    readonly image: ICmsWidgetImage
    readonly url: string | null
}

export type ICmWidgetCategoryInspirationConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly subtitle: string | null
    readonly items: Array<ICMsWidgetCategoryInspirationItem>
}

export type ICmsWidgetCategoryInspiration = IBaseCmsWidget<
    ICmWidgetCategoryInspirationConfiguration,
    CmsWidgetType.CategoryInspiration
>

/******* Inspiration ************/
export interface ICMsWidgetInspirationItem {
    readonly label: string
    readonly image: ICmsWidgetImage
    readonly url: string | null
}

export type ICmWidgetInspirationConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly subtitle: string | null
    readonly items: Array<ICMsWidgetInspirationItem>
}

export type ICmsWidgetInspiration = IBaseCmsWidget<ICmWidgetInspirationConfiguration, CmsWidgetType.Inspiration>

/******* Simple Highlight ************/
export type ICmWidgetSimpleHighlightConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly subtitle: string | null
    readonly link: ICmsWidgetLink | null
    readonly image: ICmsWidgetImage | null
    readonly button_label: string | null
}

export type ICmsWidgetSimpleHighlight = IBaseCmsWidget<
    ICmWidgetSimpleHighlightConfiguration,
    CmsWidgetType.SimpleHighlight
>

/******* Réassurance ************/
export interface ICMsWidgetReinsuranceItem {
    readonly title: string | null
    readonly subtitle: string | null
    readonly icon: string | null
}

export type ICmsWidgetReinsuranceConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly items: Array<ICMsWidgetReinsuranceItem>
}

export type ICmsWidgetReinsurance = IBaseCmsWidget<ICmsWidgetReinsuranceConfiguration, CmsWidgetType.Reinsurance>

/******* Wysiwyg ************/
export type ICmsWidgetWysiwygConfiguration = IBaseCmsWidgetConfiguration & {
    readonly content: string | null
}

export type ICmsWidgetWysiwyg = IBaseCmsWidget<ICmsWidgetWysiwygConfiguration, CmsWidgetType.Wysiwyg>

/******* Wysiwyg ************/
export type ICmsWidgetVideoConfiguration = IBaseCmsWidgetConfiguration & {
    readonly title: string | null
    readonly subtitle: string | null
    readonly button_label: string | null
    readonly link: ICmsWidgetLink | null
    readonly width: number
    readonly height: number
    readonly embed_url: string
    readonly interactive?: boolean
}

export type ICmsWidgetVideo = IBaseCmsWidget<ICmsWidgetVideoConfiguration, CmsWidgetType.Video>

/******* Global ************/
type ICmsWidget =
    | ICmsWidgetLeaderBoard
    | ICmsWidgetHighlightCategory
    | ICmsWidgetDivider
    | ICmsWidgetInspiration
    | ICmsWidgetSimpleHighlight
    | ICmsWidgetReinsurance
    | ICmsWidgetWysiwyg
    | ICmsWidgetVideo

/****************************
 * PAGE
 ****************************/
export enum CmsBlockCodeList {
    SidebarHighlight = 'sidebar-highlight',
    MenuHighlight = 'menu-highlight',
    Footer = 'footer',
}

export enum CmsBlockIdentifierType {
    Code = 'code',
}

export interface ICmsBlock extends IApiResource {
    readonly id: string
    readonly code: string
    readonly parts: Array<ICmsWidget>
}

/****************************
 * PAGE
 ****************************/
// code pour les pages cms que l'on appel en manuel
export enum CmsPageCodeList {
    Home = 'home',
}

export enum CmsPageIdentifierType {
    Slug = 'slug',
    Identifier = 'identifier',
    Code = 'code',
}

export interface ICmsPage {
    readonly name: string
    readonly code: string
    readonly head_title: string | null
    readonly meta_title: string | null
    readonly meta_description: string | null
    readonly parts: Array<ICmsWidget>
    readonly urls: CollectionMap<string>
}

/****************************
 * RESPONSE API
 ****************************/
export type IApiCmsPageResponse = AxiosResponse<ICmsPage>
export type IApiCmsBlockResponse = AxiosResponse<ICmsBlock>

/****************************
 * SERVICE
 ****************************/
export interface ICmsService {
    getPreviewPage(identifier: string): Promise<IApiCmsPageResponse>
    getPageBySlug(slug: string): Promise<IApiCmsPageResponse>
    getPageByCode(code: string): Promise<IApiCmsPageResponse>
    getBlockByCode(code: string): Promise<IApiCmsBlockResponse>
}
