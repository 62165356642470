import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { generatePath, Redirect } from 'react-router-dom'
import Config from '../config'
import CmsPage from '../containers/CmsPage/Loadable'
import { getPath } from '../routes'
import { CmsPageCodeList } from '../services/api/service/cms/types'
import { cartsBannerShowAction } from '../store/carts/actions'
import { useCustomer } from '../utils/hook/useCustomer'

const locales: Array<string> = Config.I18N.LANGUAGES

export default function HomePage(): JSX.Element {
    const { locale, formatMessage } = useIntl()
    const dispatch = useDispatch()
    const { isInternal: isInternalCustomer } = useCustomer()

    useEffect(() => {
        dispatch(cartsBannerShowAction())
    })

    if (isInternalCustomer) {
        return <Redirect to={generatePath(getPath('catalog', locale), { lang: locale })} />
    }

    return (
        <>
            <Helmet>
                <title>{formatMessage({ id: 'seo.home.title' })}</title>
                <meta name="description" content={formatMessage({ id: 'seo.home.description' })} />
                {locales
                    .filter((lcl) => {
                        return lcl !== locale
                    })
                    .map((lcl) => {
                        return (
                            <link
                                rel="alternate"
                                href={generatePath(getPath('home', lcl), {
                                    lang: lcl,
                                })}
                                hrefLang={lcl}
                                key={lcl}
                            />
                        )
                    })}
            </Helmet>
            <CmsPage identifier={CmsPageCodeList.Home} />
        </>
    )
}
