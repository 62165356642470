import React from 'react'
import FlatIcon from '../../../components/Icon/FlatIcon'

function ProductStatsFilters({
    fetching,
    previousDate,
    nextDate,
    onChange,
}: {
    fetching?: boolean
    previousDate?: string | null
    nextDate?: string | null
    onChange: (date?: string | null) => void
}) {
    return (
        <div className="product-stats-filters">
            <button
                disabled={fetching || typeof nextDate !== 'string'}
                className="btn btn-link"
                onClick={() => onChange(nextDate)}
            >
                <FlatIcon icon={'arrow-left'} />
            </button>
            <button
                disabled={fetching || typeof previousDate !== 'string'}
                className="btn btn-link"
                onClick={() => onChange(previousDate)}
            >
                <FlatIcon icon={'arrow-right'} />
            </button>
        </div>
    )
}

export default ProductStatsFilters
