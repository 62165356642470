import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects'
import localforage from 'localforage'
import ActionTypes from './constants'
import AuthActionTypes from './../auth/constants'
import CartActionTypes from './../carts/constants'
import { IApiClient } from '../../services/api/types'
import {
    productListGridFailureAction,
    productListGridResetDefinitionAction,
    productListGridsSuccessAction,
    productsBulkRemoveFavoriteFailureAction,
    productsBulkRemoveFavoriteSuccessAction,
    productsListFailureAction,
    productsListFavoriteAction,
    productsListProcessAction,
    productsListSuccessAction,
    productsPersistedParamsAction,
    productsPersistParamsAction,
    productsSearchFailureAction,
    productsSearchResetAction,
    productsSearchSuccessAction,
} from './actions'
import {
    makeSelectProductsListDisplayMode,
    makeSelectProductsListFilters,
    makeSelectProductsListMode,
    makeSelectProductsListParams,
    makeSelectProductsListParamsPrev,
    makeSelectProductsListPersistSettings,
    makeSelectProductsListSelectionAction,
} from './selectors'
import {
    IApiProductGridCollectionResponse,
    IApiProductListResponse,
    IApiProductListResponseTypes,
    IProductListParameters,
    IProductListPersistParameters,
    ProductsListDisplayMode,
    ProductsListMode,
} from '../../services/api/service/products/types'
import {
    IProductBulkFavoriteProcessAction,
    IProductSearchProcessAction,
    IProductsListProcessAction,
    IProductsPersistSettingsAction,
    IProductsSelectionApplyActionProcessAction,
} from './types'
import { productFiltersParametersEquals } from './utils'
import { formatAppError } from '../app/saga'
import Config from '../../config'
import { makeSelectCustomer, makeSelectCustomerStore } from '../customers/selectors'
import { ICustomer } from '../../services/api/service/customers/types'
import cloneDeep from 'lodash/cloneDeep'

import axios from 'axios'
import isObject from 'lodash/isObject'
import {
    classificationCategoryResetAction,
    classificationCategoryTreeProcessAction,
    classificationFamilyTreeProcessAction,
} from '../classification/actions'

import { getPickerActions } from '../../services/product/picker'
import { productListAppendModeToFilters } from '../classification/utils'
import { makeSelectAuthMe } from '../auth/selectors'
import HttpStatusCode, { HttpAuthErrors } from '../http/codes'
import AppError from '../app/error'
import { makeSelectCartMode } from '../carts/selectors'
import { Undefinable } from 'tsdef'
import { customerShowPrices } from '../customers/utils'
import { IMe } from '../../services/api/service/me/types'
import { makeSelectTreeMode } from '../config/selectors'
import { TreeMode } from '../../services/api/service/classification/types'
import {
    makeSelectClassificationCategoryTreeByMode,
    makeSelectClassificationFamilyTreeByMode,
} from '../classification/selectors'

// TOKEN
const CancelToken = axios.CancelToken

function* processProductsApplyActionProcess(action: IProductsSelectionApplyActionProcessAction) {
    // récupération du mode de liste
    const mode = yield select(makeSelectProductsListMode())
    const displayMode = yield select(makeSelectProductsListDisplayMode())
    const me = yield select(makeSelectAuthMe())
    const customer = yield select(makeSelectCustomer())
    const cartMode = yield select(makeSelectCartMode())

    // récupération de l'action courante et des items
    const pickerActionCode = yield select(makeSelectProductsListSelectionAction())
    const pickerActions = getPickerActions(mode, displayMode, me, customer, cartMode)
    const pickerAction = pickerActions[pickerActionCode]

    if (!pickerAction.redux) {
        return
    }

    // récupération des paramètres souhaités
    const payload: Record<string, any> = {}
    if (pickerAction.redux.paramKeys && isObject(action.payload)) {
        pickerAction.redux.paramKeys.forEach((paramKey) => {
            payload![paramKey] = typeof action.payload[paramKey] === 'undefined' ? undefined : action.payload[paramKey]
        })
    }
    // on dispatch la bonne action !
    yield put({
        type: pickerAction.redux.type,
        payload,
    })
}

// eslint-disable-next-line require-yield
function* processProductsApplyActionFailure() {
    // function* processProductsApplyActionFailure(action: IProductsSelectionApplyActionFailureAction) {
    // const { error, extra } = action.payload
}

// eslint-disable-next-line require-yield
function* processProductsApplyActionSuccess() {
    // function* processProductsApplyActionSuccess(action: IProductsSelectionApplyActionSuccessAction) {
    // const { message, extra } = action.payload
}

function* processBulkRemoveFavoriteProcess(action: IProductBulkFavoriteProcessAction) {
    const api: IApiClient = yield getContext('api')
    const { productIds } = action.payload

    try {
        yield call({ context: api.favorite, fn: 'bulkRemove' }, productIds)
        for (const productId of productIds) {
            yield put(productsListFavoriteAction(productId, false))
        }
        yield put(productsBulkRemoveFavoriteSuccessAction(productIds))
    } catch (e) {
        yield put(productsBulkRemoveFavoriteFailureAction(e))
    }
}

function* processProductsSearchRequest(action: IProductSearchProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const params = cloneDeep(action.payload.params)

    // reset
    yield put(productsSearchResetAction())

    // LISTED ONLY ?
    const settings: IProductListPersistParameters | undefined = yield select(makeSelectProductsListPersistSettings())
    if (settings && settings.listed_only) {
        params.filters = params.filters || {}
        params.filters.listed_only = 1
    }

    try {
        const parameters = cloneDeep(params)
        const response: IApiProductListResponse = yield call(
            { context: api.products, fn: 'list' },
            parameters,
            source.token
        )

        yield put(productsSearchSuccessAction(response.data))
    } catch (e) {
        const error = yield call(formatAppError, e, 'products.unknow_error')
        yield put(productsSearchFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export function* processInitializeProductsSettings() {
    try {
        const storage: typeof localforage = yield getContext('storage')
        const settings: IProductListPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME
        )
        const customer: ICustomer = yield select(makeSelectCustomer())

        if (!settings) {
            yield put(
                productsPersistParamsAction({
                    listed_only: !customer || customer.general_price ? 1 : 0,
                })
            )
        } else {
            yield put(productsPersistParamsAction(settings, true))
        }
    } catch (e) {}
}

function* removeProductsPersistParams() {
    try {
        const storage: typeof localforage = yield getContext('storage')
        yield call({ context: storage, fn: 'removeItem' }, Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME)
    } catch (e) {}
}

function* processProductsPersistParams(action: IProductsPersistSettingsAction) {
    const { params, merge } = action.payload

    try {
        const storage: typeof localforage = yield getContext('storage')
        if (!params) {
            yield call({ context: storage, fn: 'removeItem' }, Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME)
            yield put(productsPersistedParamsAction())
            return
        }
        if (!merge) {
            yield call({ context: storage, fn: 'setItem' }, Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME, params)
            yield put(productsPersistedParamsAction())
            return
        }

        // récupération item
        const settings: IProductListPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME
        )
        const parameters = { ...settings, ...params }
        yield call({ context: storage, fn: 'setItem' }, Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME, parameters)

        yield put(productsPersistedParamsAction())
    } catch (e) {}
}

function* processProductsRefresh() {
    // récupération des paramètres
    const mode = yield select(makeSelectProductsListMode())
    const params = yield select(makeSelectProductsListParams())

    // on apprend le mode
    params.filters = productListAppendModeToFilters(params.filters || {}, mode)

    // dispatch
    yield put(productsListProcessAction(mode, params))
}

function* processProductsRequest(action: IProductsListProcessAction) {
    const api: IApiClient = yield getContext('api')

    const source = CancelToken.source()
    const mode = action.payload.params.mode
    const displayMode = action.payload.params.displayMode
    const productGridId = action.payload.params.productGridId
    const params = cloneDeep(action.payload.params)
    delete params.mode

    const filters = yield select(makeSelectProductsListFilters())
    const me: IMe = yield select(makeSelectAuthMe())
    const customer: Undefinable<ICustomer> = yield select(makeSelectCustomer())
    const store: ICustomer | undefined = yield select(makeSelectCustomerStore())
    const showPrices = customerShowPrices(customer, me, store)

    // récupération des settings
    const settings: IProductListPersistParameters | undefined = yield select(makeSelectProductsListPersistSettings())
    if (settings && settings.listed_only) {
        params.filters = params.filters || {}
        params.filters.listed_only = 1
    }

    // récupération des filtres précédents
    const prevParams: IProductListParameters | undefined = yield select(makeSelectProductsListParamsPrev())
    if (!productFiltersParametersEquals(params, prevParams) || typeof filters === 'undefined') {
        params.with_filters = mode !== ProductsListMode.PublicGridData
    }

    // reset de la catégorie
    if (params.tree && !params.tree.category) {
        yield put(classificationCategoryResetAction())
    }

    try {
        // on va chercher les produits
        const parameters = cloneDeep(params)
        let response: IApiProductListResponseTypes

        if (displayMode === ProductsListDisplayMode.GridData && productGridId) {
            response = yield call(
                { context: api.products, fn: 'productGridProducts' },
                productGridId,
                parameters, // dupliqué dans l'API pas besoin de spread ici
                source.token
            )
        } else {
            response = yield call(
                { context: api.products, fn: 'list' },
                parameters, // dupliqué dans l'API pas besoin de spread ici
                source.token
            )
        }

        let update = cloneDeep(params)
        if (response.data.state) {
            update = {
                ...update,
                filters: {
                    ...response.data.state?.filters,
                    order: response.data.state?.order,
                },
            }
        }

        yield put(productsListSuccessAction(response.data, update, showPrices))
    } catch (e) {
        const err = yield call(formatAppError, e, 'products.unknow_error')
        yield put(productsListFailureAction(err))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

export function* processRefreshFavoriteTree() {
    const treeMode = yield select(makeSelectTreeMode())
    const productsListMode = ProductsListMode.Favorite

    const mode: ProductsListMode = yield select(makeSelectProductsListMode()) || ProductsListMode.Default

    if (treeMode === TreeMode.Families) {
        const currentTree = yield select((state) => {
            //@ts-ignore
            return makeSelectClassificationFamilyTreeByMode()(state, productsListMode)
        })

        if (currentTree) {
            // @ts-ignore
            yield put(classificationFamilyTreeProcessAction(productsListMode))
        }
    } else if (treeMode === TreeMode.Categories) {
        const currentTree = yield select((state) => {
            //@ts-ignore
            return makeSelectClassificationCategoryTreeByMode()(state, productsListMode)
        })
        if (currentTree) {
            // @ts-ignore
            yield put(classificationCategoryTreeProcessAction(productsListMode))
        }
    }
}

export function* processProductGridsRequest() {
    const api: IApiClient = yield getContext('api')

    try {
        // récupération me
        const response: IApiProductGridCollectionResponse = yield call({ context: api.products, fn: 'productGrids' })

        // on stocke le user connecté + info de redirect et origin
        yield put(productListGridsSuccessAction(response.data['hydra:member']))
    } catch (e) {
        // erreur par défaut
        let err = yield call(formatAppError, e, 'login.auth.error')
        if (
            e.isAxiosError &&
            e.response &&
            [HttpStatusCode.BAD_REQUEST, ...HttpAuthErrors].indexOf(e.response.status) > -1
        ) {
            err = new AppError(e.response.data['message'] || e.message, e.response.status, undefined, e)
        }
        yield put(productListGridFailureAction(err))
    }
}

function* processResetProductGridDefinition() {
    yield put(productListGridResetDefinitionAction())
}

export default [
    takeLatest(AuthActionTypes.LOGOUT_ACTION, removeProductsPersistParams),
    takeLatest(ActionTypes.PERSIST_PARAMS_ACTION, processProductsPersistParams),
    takeLatest(ActionTypes.LIST_REFRESH_ACTION, processProductsRefresh),
    takeLatest(ActionTypes.LIST_PROCESS_ACTION, processProductsRequest),
    takeLatest(ActionTypes.SEARCH_PROCESS_ACTION, processProductsSearchRequest),
    takeLatest(ActionTypes.LIST_SELECTION_APPLY_ACTION_PROCESS, processProductsApplyActionProcess),
    takeLatest(ActionTypes.LIST_SELECTION_APPLY_ACTION_SUCCESS, processProductsApplyActionSuccess),
    takeLatest(ActionTypes.LIST_SELECTION_APPLY_ACTION_FAILURE, processProductsApplyActionFailure),
    takeLatest(ActionTypes.BULK_REMOVE_FAVORITE_PROCESS_ACTION, processBulkRemoveFavoriteProcess),
    takeLatest(ActionTypes.LIST_PRODUCT_GRID_PROCESS_ACTION, processProductGridsRequest),
    // takeLatest(ActionTypes.LIST_FAVORITE_PRODUCT_ACTION, processRefreshFavoriteTree),
    takeLatest(CartActionTypes.BULK_ADD_TO_CART_SUCCESS_ACTION, processResetProductGridDefinition),
    takeLatest(CartActionTypes.BULK_ADD_TO_CART_FAILURE_ACTION, processResetProductGridDefinition),
]
