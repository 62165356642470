/**
 *
 * Product Carousel
 *
 */
import classNames from 'classnames'
import React, { memo } from 'react'
import {
    CmsWidgetTheme,
    CmsWidgetType,
    ICmsWidgetCategoryInspiration,
    ICMsWidgetInspirationItem,
} from '../../../../services/api/service/cms/types'
import { CategoryInspirationItemClickCallback } from '../../../../types/categoryInspiration'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import { default as CarouselApp } from '../../../Carousel/Carousel'
import { default as CarouselWrapper } from '../../../Carousel/Wrapper'
import CarouselItem from './CarouselItem'

interface IProps {
    loop: boolean
    autoplay: boolean
    autoplayDelay: number
    widget: ICmsWidgetCategoryInspiration
    onItemClick?: CategoryInspirationItemClickCallback
    onLinkClick?: CmsWidgetLinkClickCallback
}

function Carousel({ widget, loop, autoplay, autoplayDelay, onItemClick, onLinkClick }: IProps): JSX.Element {
    if (!widget) {
        return <></>
    }

    const handleOnItemClick = (item: ICMsWidgetInspirationItem) => {
        if (onItemClick) {
            onItemClick(item)
        }
        if (onLinkClick) {
            onLinkClick({
                widget: CmsWidgetType.CategoryInspiration,
                url: item.url,
                text: item.label,
                internal: true,
                blank: false,
                extra: item,
            })
        }
    }

    return (
        <div
            className={classNames(
                'cms-widget',
                'cms-widget-spacing',
                `cms-widget-${widget.widget}`,
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                <CarouselWrapper
                    className={'cms-widget-inspiration-carousel'}
                    title={widget.configuration.title}
                    subTitle={widget.configuration.subtitle}
                >
                    <CarouselApp
                        preloadImages={true}
                        watchSlidesProgress={true}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        loopedSlides={4}
                        spaceBetween={2}
                        autoplay={autoplay ? { delay: autoplayDelay, disableOnInteraction: true } : undefined}
                        loop={loop}
                        breakpoints={{
                            // when window width is >= 480px
                            720: {
                                slidesPerView: 2,
                                loopedSlides: 6,
                            },
                            // when window width is >= 640px
                            960: {
                                slidesPerView: 4,
                                loopedSlides: 8,
                            },
                        }}
                    >
                        {widget.configuration.items.map((item: ICMsWidgetInspirationItem, index: number) => {
                            return (
                                <div key={`inspiration_carousel_item_${index}`}>
                                    <CarouselItem item={item} onItemClick={handleOnItemClick} />
                                </div>
                            )
                        })}
                    </CarouselApp>
                </CarouselWrapper>
            </div>
        </div>
    )
}

Carousel.defaultProps = {
    loop: true,
    autoplay: false,
    autoplayDelay: 5000,
} as Partial<IProps>

export default memo(Carousel)
