import { $PropertyType } from 'utility-types'
import { IProductGridPublic, IProductGridPublicDetail } from '../services/api/service/product-grid-publics/types'
import { createStructuredSelector } from 'reselect'
import {
    makeSelectProductGridPublicDetail,
    makeSelectProductGridPublicDetailError,
    makeSelectProductGridPublicDetailFetching,
} from '../store/product-grid-public/selectors'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../store'
import { IAppErrorTypes } from '../store/app/types'
import { ICustomer } from '../services/api/service/customers/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    productGridPublicDetailProcessAction,
    productGridPublicDetailResetAction,
} from '../store/product-grid-public/actions'
import { Helmet } from 'react-helmet'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap'
import classNames from 'classnames'
import {
    IProductGridDataResponse,
    IProductList,
    IProductListParameters,
    ProductsListDisplayMode,
    ProductsListMode,
} from '../services/api/service/products/types'
import Logo from '../components/Logo/Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPlaceholder from 'react-placeholder'
import { default as ProductsPlaceholder } from './../containers/Products/Placeholder'
import Config from '../config'
import ProductsGrid from '../containers/Products/DisplayMode/Grid/List'
import { makeSelectCustomer, makeSelectCustomerStore } from '../store/customers/selectors'
import { makeSelectCartBulkCartQuantityFetching, makeSelectCartMode } from '../store/carts/selectors'
import { IMe } from '../services/api/service/me/types'
import { BulkCartQuantityMode, StrictCartMode } from '../store/carts/types'
import { isSalesmanResource } from '../store/salesmens/utils'
import { isQuantitySelectorMultipleEnabled } from '../store/carts/utils'
import {
    productListGridStoreDefinitionAction,
    productsListProcessAction,
    productsListResetAction,
} from '../store/products/actions'
import { cartBulkCartQuantityProcessAction } from '../store/carts/actions'
import { generateProductUrl } from '../utils/productHelper'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import {
    makeSelectProductsListError,
    makeSelectProductsListFetching,
    makeSelectProductsListGridColumns,
    makeSelectProductsListGridErrors,
    makeSelectProductsListGridItems,
    makeSelectProductsListGridRows,
} from '../store/products/selectors'
import { getPath } from '../routes'
import { makeSelectAuthMe } from '../store/auth/selectors'
import get from 'lodash/get'
import NotFoundAlert, { NotFoundReasonModes } from '../components/Products/Content/NotFoundAlert'
import FlatIcon from '../components/Icon/FlatIcon'

type IProps = {
    publicGridId: $PropertyType<IProductGridPublic, 'id'>
}

const stateSelector = createStructuredSelector<any, any>({
    detailFetching: makeSelectProductGridPublicDetailFetching(),
    detailError: makeSelectProductGridPublicDetailError(),
    detailItem: makeSelectProductGridPublicDetail(),
    customer: makeSelectCustomer(),
    me: makeSelectAuthMe(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
    bulkAddToCartFetching: makeSelectCartBulkCartQuantityFetching(),
    listProductGridRows: makeSelectProductsListGridRows(),
    listProductGridColumns: makeSelectProductsListGridColumns(),
    listProductGridItems: makeSelectProductsListGridItems(),
    listProductGridErrors: makeSelectProductsListGridErrors(),
    listFetching: makeSelectProductsListFetching(),
    listError: makeSelectProductsListError(),
})

export default function ProductGridPage({ publicGridId }: IProps) {
    const { locale } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const {
        listFetching,
        listError,
        listProductGridRows,
        listProductGridColumns,
        listProductGridItems,
        listProductGridErrors,
        bulkAddToCartFetching,
        detailItem,
        detailError,
        detailFetching,
        customer,
        me,
        store,
        cartMode,
    } = useSelector<
        IApplicationRootState,
        {
            listError?: IAppErrorTypes | undefined
            detailItem?: IProductGridPublicDetail
            detailError?: IAppErrorTypes | undefined
            detailFetching: boolean
            customer: ICustomer
            store?: ICustomer
            me: IMe
            cartMode: StrictCartMode
            bulkAddToCartFetching: boolean
            listProductGridRows: $PropertyType<IProductGridDataResponse, 'rows'>
            listProductGridColumns: $PropertyType<IProductGridDataResponse, 'columns'>
            listProductGridItems: $PropertyType<IProductGridDataResponse, 'items'>
            listProductGridErrors: $PropertyType<IProductGridDataResponse, 'errors'>
            listFetching: boolean
        }
    >(stateSelector)
    const [initialized, setInitialized] = useState<boolean>(false)

    // est-ce que la personne connectée est un commercial ?
    const isSalesman = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    const isQtySelectorMultiple = useMemo(() => {
        return isQuantitySelectorMultipleEnabled(customer, store, cartMode)
    }, [customer, store, cartMode])

    const handleBack = useCallback(() => {
        history.push(generatePath(getPath('productGridPublics', locale), { lang: locale }))
    }, [history, locale])

    const apiFetching = useMemo(() => {
        return listFetching || detailFetching
    }, [listFetching, detailFetching])

    const apiError = useMemo(() => {
        return listError || detailError
    }, [listError, detailError])

    const handleGridBulkCartQuantityClick = useCallback(
        (action, definition) => {
            // stockage définition
            dispatch(productListGridStoreDefinitionAction(definition))
            // ajout au panier !
            dispatch(
                cartBulkCartQuantityProcessAction({
                    bulkMode: BulkCartQuantityMode.Grid,
                    bulkAction: action,
                    quantity: 1,
                })
            )
        },
        [dispatch]
    )

    const handleOnProductClick = useCallback(
        (product: IProductList) => {
            history.push(generateProductUrl(product.id, locale), { background: location })
        },
        [locale, location, history]
    )

    const parameters = useMemo(() => {
        if (!detailItem || detailFetching) {
            return undefined
        }
        return {
            mode: ProductsListMode.PublicGridData,
            displayMode: ProductsListDisplayMode.GridData,
            filters: detailItem.filters,
            tree: detailItem.tree,
            productGridId: detailItem.product_grid,
        } as IProductListParameters
    }, [detailItem, detailFetching])

    useEffect(() => {
        if ((detailItem || detailError) && !initialized) {
            setInitialized(true)
        }
    }, [detailItem, detailError, initialized, setInitialized])

    useEffect(() => {
        // chargement du produit si necessary
        dispatch(productGridPublicDetailProcessAction(publicGridId))
        return () => {
            dispatch(productGridPublicDetailResetAction())
            dispatch(productsListResetAction())
        }
    }, [publicGridId, dispatch])

    useEffect(() => {
        if (!parameters) {
            return
        }
        dispatch(productsListProcessAction(ProductsListMode.PublicGridData, parameters))
    }, [dispatch, parameters])

    useEffect(() => {
        document.documentElement.classList.add(`display-mode-${ProductsListDisplayMode.GridData}`)
        return () => {
            document.documentElement.className = document.documentElement.className.replace(
                /display-mode-[A-Za-z0-9_-]+/g,
                ''
            )
        }
    }, [])

    useEffect(() => {
        document.documentElement.classList.add(`product-list-member-${me['@type'].toLowerCase()}`)
        return () => {
            document.documentElement.className = document.documentElement.className.replace(
                /product-list-member-[A-Za-z0-9_-]+/g,
                ''
            )
        }
    }, [me])

    return (
        <>
            <Helmet>
                <title>{detailItem?.name}</title>
            </Helmet>
            <div
                className={classNames('catalog-list-page', 'product-grid-public-page')}
                id={'product-grid-publics-page'}
            >
                <Container fluid>
                    <div className={classNames('catalog-list-page-container', 'products-page-container')}>
                        <Row noGutters className={'catalog-list-page-content products-page-content'}>
                            <Col className={'col-catalog-list col-product-list'}>
                                <div
                                    className={classNames(
                                        'catalog-list-section',
                                        'product-list-section',
                                        `product-list-display-mode-${ProductsListDisplayMode.GridData}`
                                    )}
                                    id={'product-list-section'}
                                >
                                    <div className={classNames('product-list-header-grid')}>
                                        <div className={classNames('product-list-header-grid-inner')}>
                                            <h6>
                                                {detailItem ? (
                                                    detailItem.name
                                                ) : (
                                                    <>
                                                        {apiFetching ? (
                                                            <FormattedMessage id="default.loading" />
                                                        ) : undefined}
                                                    </>
                                                )}
                                            </h6>
                                            <Button variant="link" className="btn-logo" onClick={handleBack}>
                                                <Logo />
                                            </Button>
                                            <Button variant="link" className="btn-close" onClick={handleBack}>
                                                <FontAwesomeIcon icon={['fal', 'times']} className={'app-icon'} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('catalog-list-header', 'product-list-header', {
                                            'has-errors': typeof apiError !== 'undefined',
                                        })}
                                    />
                                    <div className={classNames('catalog-list-content', 'product-list-content')}>
                                        <ReactPlaceholder
                                            ready={!apiFetching}
                                            customPlaceholder={
                                                <ProductsPlaceholder
                                                    itemsPerPage={Config.PRODUCT_LIST.ITEMS_PER_PAGE}
                                                />
                                            }
                                        >
                                            <>
                                                {apiError ? (
                                                    <Alert variant="danger" className="mb-0">
                                                        <Alert.Heading>
                                                            <FormattedMessage id="default.error_occurred" />
                                                        </Alert.Heading>
                                                        <p>{get(apiError, 'message')}</p>
                                                    </Alert>
                                                ) : undefined}
                                                {listProductGridErrors.length > 0 && (
                                                    <Alert variant={'danger'}>
                                                        <Alert.Heading>
                                                            <FormattedMessage id="default.warning" />
                                                        </Alert.Heading>
                                                        {listProductGridErrors.map((listProductGridError, index) => (
                                                            <p key={index} className="mb-0">
                                                                {listProductGridError}
                                                            </p>
                                                        ))}
                                                    </Alert>
                                                )}

                                                {!detailFetching && listProductGridRows.length === 0 ? (
                                                    <NotFoundAlert
                                                        title={detailItem?.name}
                                                        mode={ProductsListMode.PublicGridData}
                                                        displayMode={ProductsListDisplayMode.GridData}
                                                        reason={NotFoundReasonModes.NoResults}
                                                    >
                                                        <Button
                                                            variant={'outline-danger'}
                                                            onClick={handleBack}
                                                            className={'btn-back'}
                                                        >
                                                            <FlatIcon icon={'chevron-left'} className="mr-2" />
                                                            <FormattedMessage id={'default.back_to_list'} />
                                                        </Button>
                                                    </NotFoundAlert>
                                                ) : undefined}
                                                {listProductGridItems &&
                                                Object.values(listProductGridItems).length > 0 ? (
                                                    <ProductsGrid
                                                        className={classNames({
                                                            'd-none':
                                                                (typeof listProductGridErrors !== 'undefined' &&
                                                                    listProductGridErrors.length > 0) ||
                                                                Object.keys(listProductGridItems).length === 0,
                                                        })}
                                                        bulkAddingToCart={bulkAddToCartFetching}
                                                        rows={listProductGridRows}
                                                        columns={listProductGridColumns}
                                                        items={listProductGridItems}
                                                        onMassBulkCartQuantityClick={handleGridBulkCartQuantityClick}
                                                        onProductClick={handleOnProductClick}
                                                        quantitySelectorMultiple={isQtySelectorMultiple}
                                                        showProductSalesmanInfo={isSalesman}
                                                        showPrices={!customer.hide_prices}
                                                        showGeneralPriceBadge={customer.general_price}
                                                    />
                                                ) : undefined}
                                            </>
                                        </ReactPlaceholder>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}
