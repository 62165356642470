/**
 *
 * FamilyTreeItem
 * TEST
 */
import classNames from 'classnames'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { useLocation } from 'react-router-dom'
import {
    ICategoryTree,
    ICategoryTreeCollection,
    IMenuItem,
} from '../../../../services/api/service/classification/types'
import { LinkItemCallbacks } from '../../type'
import LinkItem from '../LinkItem'
import ShippingLocationItem from './ShippingLocationItem'
import CategoryTreeContext from './CategoryTreeContext'
import { CmsBlockCodeList } from '../../../../services/api/service/cms/types'
import CmsBlock from '../../../../containers/CmsBlock/CmsBlock'
import { LazyImage } from 'react-lazy-images'

type IProps = LinkItemCallbacks & {
    item: IMenuItem
    categoryTree: ICategoryTreeCollection
    basePath?: string
}

function CategoryTreeItem({ basePath, item, categoryTree, onMouseEnter, onMouseLeave }: IProps): JSX.Element {
    const [active, setActive] = useState<boolean>(false)
    const [entered, setEntered] = useState<boolean>(false)
    const [category, setCategory] = useState<ICategoryTree | undefined>(undefined)
    const location = useLocation()

    const handleMouseClick = useCallback(
        (e: React.MouseEvent, item: any, itemType: any) => {
            setEntered(false)
            if (onMouseLeave) {
                onMouseLeave(e, item, itemType)
            }
        },
        [setEntered, onMouseLeave]
    )

    const handleCmsWidgetClick = useCallback(() => {
        setEntered(false)
    }, [setEntered])

    const handleMouseEnter = useCallback(() => {
        setEntered(true)
    }, [setEntered])

    const handleMouseLeave = useCallback(() => {
        setEntered(false)
    }, [setEntered])

    useEffect(() => {
        setActive(location.pathname.indexOf(item.url) > -1)
    }, [location.pathname, item])

    return (
        <div
            className={classNames('megamenu', 'nav-item', `megamenu-${item.type}`, {
                'has-children': true,
                active,
                open: entered,
            })}
            onMouseLeave={handleMouseLeave}
            onMouseEnter={handleMouseEnter}
        >
            <div className={'megamenu-heading'}>
                <LinkItem
                    item={item}
                    className={'megamenu-nav-item'}
                    onClick={handleMouseClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            </div>
            <div className={'megamenu-content'}>
                <div className={'megamenu-container'}>
                    <CategoryTreeContext.Provider value={{ category, setCategory }}>
                        <Container>
                            <CmsBlock
                                identifier={CmsBlockCodeList.MenuHighlight}
                                onCmsWidgetLinkClick={handleCmsWidgetClick}
                                lazy
                            >
                                {(widgetComponent, widget) => {
                                    return (
                                        <Row>
                                            <Col xs={24} lg={widget ? 19 : 24}>
                                                <div className={'megamenu-content-list'}>
                                                    {categoryTree?.map((familyTreeItem) => {
                                                        return (
                                                            <ShippingLocationItem
                                                                basePath={basePath}
                                                                onClick={handleMouseClick}
                                                                onMouseEnter={onMouseEnter}
                                                                onMouseLeave={onMouseLeave}
                                                                selectedCategorySetter={setCategory}
                                                                key={`category_tree_${item.type}_${
                                                                    item['@id']
                                                                }_shipping_location_${
                                                                    familyTreeItem['@id'] || familyTreeItem.id
                                                                }`}
                                                                item={familyTreeItem}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </Col>
                                            {widget && (
                                                <Col xs={24} lg={5} className={'col-megamenu-preview'}>
                                                    <CategoryTreeContext.Consumer>
                                                        {(value) => (
                                                            <div
                                                                className={classNames('megamenu-content-preview', {
                                                                    'has-department':
                                                                        value.category &&
                                                                        value.category.inspiration_image,
                                                                })}
                                                            >
                                                                {value.category && value.category.inspiration_image && (
                                                                    <div className={'department-preview'}>
                                                                        <div className={'department-preview-inner'}>
                                                                            <LazyImage
                                                                                debounceDurationMs={200}
                                                                                src={
                                                                                    value.category.inspiration_image!
                                                                                        .normal
                                                                                }
                                                                                alt={value.category.label}
                                                                                placeholder={({ imageProps, ref }) => (
                                                                                    <img
                                                                                        {...imageProps}
                                                                                        className={'placeholder'}
                                                                                        ref={ref}
                                                                                        alt={value.category!.label}
                                                                                        src={
                                                                                            value.category!
                                                                                                .inspiration_image!
                                                                                                .placeholder
                                                                                        }
                                                                                    />
                                                                                )}
                                                                                actual={({ imageProps }) => (
                                                                                    <img
                                                                                        {...imageProps}
                                                                                        alt={value.category!.label}
                                                                                        className={'picture'}
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {widgetComponent}
                                                            </div>
                                                        )}
                                                    </CategoryTreeContext.Consumer>
                                                </Col>
                                            )}
                                        </Row>
                                    )
                                }}
                            </CmsBlock>
                        </Container>
                    </CategoryTreeContext.Provider>
                </div>
            </div>
        </div>
    )
}

CategoryTreeItem.defaultProps = {
    basePath: '',
} as Partial<IProps>

export default memo(CategoryTreeItem)
