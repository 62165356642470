/*
 *
 * Confirmation
 *
 */

import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { Alert, Table } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, NavLink, Redirect } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { getPath } from '../../routes'
import { ICartCollection, ICartMode } from '../../services/api/service/carts/types'
import { ICustomer } from '../../services/api/service/customers/types'
import { IOrderDocumentType, IOrderMode } from '../../services/api/service/orders/types'
import { IApplicationRootState } from '../../store'
import { cartSaveToOrdersResetAction } from '../../store/carts/actions'
import {
    makeSelectCartsCreateOrdersErrors,
    makeSelectCartsCreateOrdersItems,
    makeSelectMainCartsList,
} from '../../store/carts/selectors'
import { ICreateOrderErrorsType, ICreateOrderItems, StrictCartMode } from '../../store/carts/types'

import { makeSelectCustomer } from '../../store/customers/selectors'
import { makeSelectOrdersMode } from '../../store/orders/selectors'
import { isSalesmanResource } from '../../store/salesmens/utils'
import { makeSelectAuthMe } from '../../store/auth/selectors'
import { IMe } from '../../services/api/service/me/types'
import Document from '../OrderDetail/Action/Document'
import ViewButton from '../OrderDetail/Action/View'

const stateSelector = createStructuredSelector<any, any>({
    carts: makeSelectMainCartsList(),
    createOrderItems: makeSelectCartsCreateOrdersItems(),
    createOrdersErrors: makeSelectCartsCreateOrdersErrors(),
    customer: makeSelectCustomer(),
    orderMode: makeSelectOrdersMode(),
    me: makeSelectAuthMe(),
})

function Confirmation(): JSX.Element {
    const { locale, formatNumber } = useIntl()
    const dispatch = useDispatch()

    const { carts, createOrderItems, createOrdersErrors, customer, cartMode, orderMode, me } = useSelector<
        IApplicationRootState,
        {
            carts: ICartCollection
            createOrderItems: ICreateOrderItems
            createOrdersErrors: ICreateOrderErrorsType
            customer: ICustomer
            cartMode: StrictCartMode
            orderMode: IOrderMode
            me?: IMe
        }
    >(stateSelector)

    const isGroupedCarts = useMemo(() => {
        return cartMode === ICartMode.Grouped
    }, [cartMode])
    const customerAreaUrl = generatePath(getPath('customerArea', locale), { lang: locale })
    const homepageUrl = generatePath(getPath('home', locale), { lang: locale })
    const cartUrl = generatePath(getPath('cart', locale), { lang: locale })

    useEffect(() => {
        return () => {
            dispatch(cartSaveToOrdersResetAction())
        }
    })

    const orders = useMemo(() => {
        return Object.values(createOrderItems).reduce((a, b) => a.concat(b), [])
    }, [createOrderItems])

    const ordersCount = useMemo(() => {
        return orders.length
    }, [orders])
    const cartsCount = useMemo(() => {
        return carts.length
    }, [carts])
    const orderErrorsCount = useMemo(() => {
        return Object.values(createOrdersErrors).length
    }, [createOrdersErrors])
    const hasErrors = useMemo(() => {
        return orderErrorsCount > 0
    }, [orderErrorsCount])
    const isSalesman = useMemo(() => {
        return me && isSalesmanResource(me)
    }, [me])

    if (ordersCount === 0) {
        return (
            <Redirect
                to={{
                    pathname: generatePath(getPath('cart', locale), { lang: locale }),
                }}
            />
        )
    }

    return (
        <div className={classNames('confirmation-section', { table: isGroupedCarts })}>
            <h1 className={'hv'}>
                <FormattedMessage id={'default.congratulation'} />
            </h1>
            <h2 className={'hv'}>
                {ordersCount > 0 && ordersCount === cartsCount && ordersCount > 1 && (
                    <FormattedMessage id={`checkout.confirmation.congratulation_plural_${orderMode}`} />
                )}
                {ordersCount === 1 && (
                    <FormattedMessage id={`checkout.confirmation.congratulation_single_${orderMode}`} />
                )}
                {ordersCount === 0 && (
                    <FormattedMessage id={`checkout.confirmation.congratulation_plural_${orderMode}`} />
                )}
            </h2>
            <div className={'detail-info'}>
                {isGroupedCarts && ordersCount > 1 && (
                    <div className={'detail-info-plural'}>
                        <h5>
                            <FormattedMessage id={`checkout.confirmation.info_plural_${orderMode}`} />
                        </h5>
                        <Table className={'order-table order-confirmation-table'} hover responsive>
                            <thead>
                                <tr>
                                    <th className={'cell number'}>
                                        <FormattedMessage id={'orders.number'} />
                                    </th>
                                    <th className={'cell shipping-location'}>
                                        <FormattedMessage id={'classification.transporter'} />
                                    </th>
                                    <th className={'cell store'}>
                                        <FormattedMessage id={'default.store'} />
                                    </th>
                                    <th className={'cell messages'} />
                                    <th className={'cell actions'} />
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order) => {
                                    const cs = customer.stores?.find((item) => item['@id'] === order.customer['@id'])
                                    return (
                                        <tr key={order['@id']}>
                                            <td className={'cell number'}>{order.number}</td>
                                            <td className={'cell shipping-location'}>
                                                {order.shipping_location ? order.shipping_location.full_name : '-'}
                                            </td>
                                            <td className={'cell store'}>{cs ? cs!.business_name : '-'}</td>
                                            <td className={'cell messages'}>
                                                {order.minimum_order_amount > order.total && (
                                                    <p className={'mb-0'}>
                                                        <FormattedMessage
                                                            id={'cart.create_order_confirmation_warning_message'}
                                                            values={{
                                                                minimum_amount: formatNumber(
                                                                    order.minimum_order_amount,
                                                                    {
                                                                        currency: 'EUR',
                                                                        currencyDisplay: 'symbol',
                                                                        currencySign: 'standard',
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                        style: 'currency',
                                                                    }
                                                                ),
                                                            }}
                                                        />
                                                    </p>
                                                )}
                                            </td>
                                            <td className="actions">
                                                <Document
                                                    type={IOrderDocumentType.PurchaseOrderPdf}
                                                    orderNumber={order.number}
                                                    orderType={order.type}
                                                    orderId={order.id}
                                                    label={null}
                                                />
                                                <Document
                                                    type={IOrderDocumentType.PurchaseOrderExcel}
                                                    orderNumber={order.number}
                                                    orderType={order.type}
                                                    orderId={order.id}
                                                    label={null}
                                                />
                                                <ViewButton order={order} label={null} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                )}
                {!isGroupedCarts && ordersCount > 0 && (
                    <div className={'detail-info-plural'}>
                        <h5>
                            {ordersCount > 1 && (
                                <FormattedMessage id={`checkout.confirmation.info_plural_${orderMode}`} />
                            )}
                        </h5>
                        <Table hover responsive className="order-table">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="classification.shipping_location" />
                                    </th>
                                    <th>
                                        <FormattedMessage
                                            id={orderMode === IOrderMode.Order ? 'orders.number' : 'assets.number'}
                                        />
                                    </th>
                                    <th className="actions" />
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order) => {
                                    return (
                                        <tr key={order['@id']}>
                                            <td>{order.shipping_location ? order.shipping_location.full_name : '-'}</td>
                                            <td>{order.number}</td>
                                            <td className="actions">
                                                <Document
                                                    type={IOrderDocumentType.PurchaseOrderPdf}
                                                    orderNumber={order.number}
                                                    orderType={order.type}
                                                    orderId={order.id}
                                                    label={null}
                                                />
                                                {isSalesman && (
                                                    <Document
                                                        type={IOrderDocumentType.PurchaseOrderExcel}
                                                        orderNumber={order.number}
                                                        orderType={order.type}
                                                        orderId={order.id}
                                                        label={null}
                                                    />
                                                )}
                                                <ViewButton order={order} label={null} />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                )}
            </div>
            <div className={'confirmation-info'}>
                {hasErrors && (
                    <Alert variant={'warning'}>
                        <FormattedMessage id={'checkout.confirmation.has_errors'} />
                        <NavLink to={cartUrl}>
                            <FormattedMessage id={'default.here'} />
                        </NavLink>
                        .
                    </Alert>
                )}
                {!isSalesmanResource(me) && (
                    <>
                        <p>
                            <FormattedMessage id={'checkout.confirmation.check_email'} />
                        </p>
                        <p>
                            <FormattedMessage id={'checkout.confirmation.follow_orders'} />
                            <NavLink to={customerAreaUrl}>
                                <FormattedMessage id={'default.here'} />
                            </NavLink>
                            .
                        </p>
                        <p>
                            <FormattedMessage id={'checkout.confirmation.contact_us'} />
                        </p>
                    </>
                )}
                <p>
                    <NavLink to={homepageUrl} className={'btn btn-primary btn-lg'}>
                        <FormattedMessage id={'default.back_to_home'} />
                    </NavLink>
                </p>
            </div>
        </div>
    )
}

export default Confirmation
