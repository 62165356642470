/*
 *
 * Products reducer
 *
 */

import ActionTypes from './constants'
import { ContainerActions, ContainerState, IProductGridPublicsListState } from './types'

import cloneDeep from 'lodash/cloneDeep'
import isNumber from 'lodash/isNumber'

export const initialListState: IProductGridPublicsListState = {
    fetching: false,
    params: {
        page: 1,
    },
}

export const initialState: ContainerState = {
    list: initialListState,
}

function productGridPublicsReducer(state: ContainerState = initialState, action: ContainerActions): ContainerState {
    switch (action.type) {
        case ActionTypes.LIST_PROCESS_ACTION:
            return {
                ...state,
                list: {
                    ...state.list,
                    error: undefined,
                    paramsPrev: state.list.params ? cloneDeep(state.list.params) : undefined,
                    params: cloneDeep(action.payload.params),
                },
            }
        case ActionTypes.LIST_SUCCESS_ACTION: {
            const { response } = action.payload

            let listState = {
                ...state.list,
                params: cloneDeep(action.payload.params),
            }

            // pagination
            const totalItems = isNumber(response['hydra:totalItems']) ? response['hydra:totalItems'] : undefined
            const nextPageUrl = response['hydra:view']['hydra:next'] || undefined
            const prevPageUrl = response['hydra:view']['hydra:prev'] || undefined
            const firstPageUrl = response['hydra:view']['hydra:first'] || undefined
            const lastPageUrl = response['hydra:view']['hydra:last'] || undefined
            const items = response['hydra:member'] || []

            listState = {
                ...listState,
                items,
                totalItems,
                nextPageUrl,
                prevPageUrl,
                firstPageUrl,
                lastPageUrl,
            }

            return {
                ...state,
                list: {
                    ...listState,
                },
            }
        }
        case ActionTypes.LIST_RESET_ACTION:
            return {
                ...state,
                list: {
                    ...initialListState,
                },
            }
        case ActionTypes.RESET_ACTION:
            return initialState
        default:
            return state
    }
}

export default productGridPublicsReducer
