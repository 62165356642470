import { action } from 'typesafe-actions'
import ActionTypes from './constants'
import { IAppErrorTypes } from '../app/types'
import {
    IProductGridPublicCollectionResponse,
    IProductGridPublicListParameters,
} from '../../services/api/service/product-grid-publics/types'
import { IProductGridPublicsListProcessPayload, IProductGridPublicsListSuccessPayload } from './types'
import { $PropertyType } from 'utility-types'

export const productGridPublicsResetAction = () => action<ActionTypes.RESET_ACTION>(ActionTypes.RESET_ACTION)

export const productGridPublicsListProcessAction = (params: IProductGridPublicListParameters) =>
    action<ActionTypes.LIST_PROCESS_ACTION, IProductGridPublicsListProcessPayload>(ActionTypes.LIST_PROCESS_ACTION, {
        params,
    })

export const productGridPublicsListRefreshAction = () =>
    action<ActionTypes.LIST_REFRESH_ACTION>(ActionTypes.LIST_REFRESH_ACTION)

export const productGridPublicsListSuccessAction = (
    response: $PropertyType<IProductGridPublicCollectionResponse, 'data'>,
    params: IProductGridPublicListParameters
) =>
    action<ActionTypes.LIST_SUCCESS_ACTION, IProductGridPublicsListSuccessPayload>(ActionTypes.LIST_SUCCESS_ACTION, {
        response,
        params,
    })
export const productGridPublicsListFailureAction = (error: IAppErrorTypes) =>
    action<ActionTypes.LIST_FAILURE_ACTION, { error: IAppErrorTypes }>(ActionTypes.LIST_FAILURE_ACTION, { error })
export const productGridPublicsListResetAction = () =>
    action<ActionTypes.LIST_RESET_ACTION>(ActionTypes.LIST_RESET_ACTION)
