/**
 *
 * Product Card
 *
 */
import classNames from 'classnames'
import React, { memo, useCallback } from 'react'
import { ICategory, ICategoryTree } from '../../../services/api/service/classification/types'
import { ISearchSuggestionCategory } from '../../../services/api/service/search/types'

type ResourceType = ICategory | ICategoryTree | ISearchSuggestionCategory

interface IProps {
    category: ResourceType
    onClick: (category: ResourceType) => void
}

function CategorySuggestion({ category, onClick }: IProps): JSX.Element {
    const handleOnClick = useCallback(() => {
        if (onClick) {
            onClick(category)
        }
    }, [category, onClick])
    return (
        <div onClick={handleOnClick} className={classNames('category', 'category-card', 'category-suggestion')}>
            <span className={classNames('value')}>{category.label}</span>
        </div>
    )
}

CategorySuggestion.defaultProps = {} as Partial<IProps>

export default memo(CategorySuggestion)
