import { call, cancelled, getContext, put, select, takeLatest } from 'redux-saga/effects'
import ProductActionTypes from './constants'
import { IApiBooleanType, IApiClient } from '../../services/api/types'
import {
    IApiProductCollectionResponse,
    IApiProductGroupResponse,
    IApiProductListResponse,
    IApiProductMonthlyStatsResponse,
    IApiProductResponse,
    IApiProductWeeklyStatsResponse,
    IProductListPersistParameters,
    ProductsListMode,
} from '../../services/api/service/products/types'
import {
    productCollectionFailureAction,
    productCollectionResetAction,
    productCollectionSuccessAction,
    productCrossSellingSuccessAction,
    productDetailFailureAction,
    productDetailSuccessAction,
    productGroupAttributeFailureAction,
    productGroupAttributeProcessAction,
    productGroupAttributeSuccessAction,
    productMonthlyStatsResetAction,
    productMonthlyStatsSuccessAction,
    productWeeklyStatsResetAction,
    productWeeklyStatsSuccessAction,
} from './actions'
import { makeSelectProductGroupAttributes } from './selectors'
import {
    IProductCollectionProcessAction,
    IProductCrossSellingProcessAction,
    IProductDetailProcessAction,
    IProductFavoriteAction,
    IProductGroupAttributeState,
    IProductGroupProcessAction,
    IProductMonthlyStatsProcessAction,
    IProductWeeklyStatsProcessAction,
} from './types'
import { formatAppError } from '../app/saga'
import { productsListFavoriteAction, productsListProcessAction } from '../products/actions'
import ReactGA from 'react-ga4'
import Config from '../../config/index'
import axios from 'axios'
import {
    makeSelectProductsListMode,
    makeSelectProductsListParams,
    makeSelectProductsListPersistSettings,
} from '../products/selectors'
import localforage from 'localforage'
import { productListAppendModeToFilters } from '../classification/utils'
import CartActionTypes from '../carts/constants'

// TOKEN
const CancelToken = axios.CancelToken

function* processProductDetail(action: IProductDetailProcessAction) {
    const source = CancelToken.source()
    const productId: string = action.payload!.productId
    const api: IApiClient = yield getContext('api')

    try {
        const currentProductGroup: IProductGroupAttributeState = yield select(makeSelectProductGroupAttributes())

        // yield put(productCrossSellingResetAction())
        yield put(productCollectionResetAction())

        const response: IApiProductResponse = yield call({ context: api.products, fn: 'get' }, productId, source.token)
        yield put(productDetailSuccessAction(response))

        if (
            currentProductGroup &&
            currentProductGroup.data &&
            currentProductGroup.data['@id'] === response.data.group
        ) {
            return
        }

        yield put(productGroupAttributeProcessAction(response.data.group))

        if (Config.GOOGLE.ANALYTICS.ACTIVE) {
            try {
                ReactGA.event('view_item', {
                    currency: Config.I18N.DEFAULT_CURRENCY.CURRENCY,
                    value: response.data.price,
                    items: [
                        {
                            item_id: response.data.reference,
                            item_name: response.data.name,
                            price: response.data.price,
                            quantity: 1,
                        },
                    ],
                })
            } catch (e) {}
        }

        // yield put(productCrossSellingProcessAction(productId))
    } catch (e) {
        const error = yield call(formatAppError, e, 'product.detail.error')
        yield put(productDetailFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processProductCollectionRequest(action: IProductCollectionProcessAction) {
    const source = CancelToken.source()
    const { identifiers } = action.payload
    const api: IApiClient = yield getContext('api')

    try {
        let response: IApiProductCollectionResponse | undefined = undefined

        // LISTED ONLY ?
        const settings: IProductListPersistParameters | undefined = yield select(
            makeSelectProductsListPersistSettings()
        )
        const listedOnly: IApiBooleanType | undefined = settings && settings.listed_only ? 1 : 0

        // check
        if (identifiers.themeId) {
            try {
                response = yield call(
                    { context: api.products, fn: 'productTheme' },
                    identifiers.themeId,
                    listedOnly,
                    source.token
                )
            } catch (e) {
                response = undefined
            }
        }

        if (!response && identifiers.collectionId) {
            response = yield call(
                { context: api.products, fn: 'productCollection' },
                identifiers.collectionId,
                listedOnly,
                source.token
            )
        }

        if (response) {
            yield put(productCollectionSuccessAction(response))
        }
    } catch (e) {
        // console.log(e)
        const error = yield call(formatAppError, e, 'default.error')
        yield put(productCollectionFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processProductGroupAttributes(action: IProductGroupProcessAction) {
    const source = CancelToken.source()
    const api: IApiClient = yield getContext('api')
    const productGroupId = action.payload?.productGroupId
    const currentProductGroup: IProductGroupAttributeState = yield select(makeSelectProductGroupAttributes())
    // LISTED ONLY ?
    const settings: IProductListPersistParameters | undefined = yield select(makeSelectProductsListPersistSettings())
    const listedOnly: IApiBooleanType | undefined = settings && settings.listed_only ? 1 : 0

    // si c'est le même groupe, on ne s'embête pas à refaire une requete HTTP
    if (currentProductGroup && currentProductGroup.data && currentProductGroup.data['@id'] === productGroupId) {
        return
    }

    if (!productGroupId) {
        yield put(productGroupAttributeSuccessAction(null))
        return
    }

    try {
        const response: IApiProductGroupResponse = yield call(
            { context: api.products, fn: 'productGroup' },
            productGroupId,
            listedOnly,
            source.token
        )
        yield put(productGroupAttributeSuccessAction(response))
    } catch (e) {
        const error = yield call(formatAppError, e, 'product.detail_group.error')
        yield put(productGroupAttributeFailureAction(error))
    } finally {
        if (yield cancelled()) {
            source.cancel('cancelled')
        }
    }
}

function* processAddToFavorite(action: IProductFavoriteAction) {
    const api: IApiClient = yield getContext('api')
    const { productId } = action.payload

    try {
        yield call({ context: api.favorite, fn: 'add' }, productId)
        yield put(productsListFavoriteAction(productId, true))
    } catch (e) {}
}

function* processRemoveToFavorite(action: IProductFavoriteAction) {
    const api: IApiClient = yield getContext('api')
    const mode: ProductsListMode = yield select(makeSelectProductsListMode()) || ProductsListMode.Default
    let params = yield select(makeSelectProductsListParams())
    params = productListAppendModeToFilters(params, mode)

    const isFavoriteList = mode === ProductsListMode.Favorite
    const { productId } = action.payload

    try {
        yield call({ context: api.favorite, fn: 'remove' }, productId)
        if (!isFavoriteList) {
            yield put(productsListFavoriteAction(productId, false))
            return
        }

        // et on envoi mamène !
        yield put(productsListProcessAction(mode, params))
    } catch (e) {
        // console.log(e)
    }
}

function* processCrossSellingRequest(action: IProductCrossSellingProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const { productId } = action.payload
    let params = {}

    try {
        // LISTED ONLY ?
        const storage: typeof localforage = yield getContext('storage')
        const settings: IProductListPersistParameters | undefined = yield call(
            { context: storage, fn: 'getItem' },
            Config.PRODUCT_LIST.SETTINGS_STORAGE_NAME
        )
        if (settings && settings.listed_only) {
            params = { ...params, listed_only: 1 }
        }
    } catch (e) {}

    try {
        // productId, queries
        const response: IApiProductListResponse = yield call(
            { context: api.products, fn: 'productCrossSell' },
            productId,
            params,
            source.token
        )
        yield put(productCrossSellingSuccessAction(response.data))
    } catch (e) {
        // console.log(e)
    }
}

function* processResetProductStatsCollection() {
    yield put(productWeeklyStatsResetAction())
    yield put(productMonthlyStatsResetAction())
}

function* processProductWeeklyStatsRequest(action: IProductWeeklyStatsProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const { productId, filters } = action.payload!

    try {
        // productId, queries
        const response: IApiProductWeeklyStatsResponse = yield call(
            { context: api.products, fn: 'getWeeklyStats' },
            productId,
            filters,
            source.token
        )
        yield put(productWeeklyStatsSuccessAction(productId, response.data, filters))
    } catch (e) {
        // console.log(e)
    }
}

function* processProductMonthlyStatsRequest(action: IProductMonthlyStatsProcessAction) {
    const api: IApiClient = yield getContext('api')
    const source = CancelToken.source()
    const { productId, filters } = action.payload!

    try {
        // productId, queries
        const response: IApiProductMonthlyStatsResponse = yield call(
            { context: api.products, fn: 'getMonthlyStats' },
            productId,
            filters,
            source.token
        )
        yield put(productMonthlyStatsSuccessAction(productId, response.data, filters))
    } catch (e) {
        // console.log(e)
    }
}

export default [
    takeLatest(ProductActionTypes.DETAIL_PROCESS_ACTION, processProductDetail),
    takeLatest(ProductActionTypes.GROUP_ATTRIBUTE_PROCESS_ACTION, processProductGroupAttributes),
    takeLatest(ProductActionTypes.ADD_TO_FAVORITE_ACTION, processAddToFavorite),
    takeLatest(ProductActionTypes.REMOVE_TO_FAVORITE_ACTION, processRemoveToFavorite),
    takeLatest(ProductActionTypes.CROSS_SELLING_PROCESS_ACTION, processCrossSellingRequest),
    takeLatest(ProductActionTypes.COLLECTION_PROCESS_ACTION, processProductCollectionRequest),
    takeLatest(ProductActionTypes.WEEKLY_STATS_PROCESS_ACTION, processProductWeeklyStatsRequest),
    takeLatest(ProductActionTypes.MONTHLY_STATS_PROCESS_ACTION, processProductMonthlyStatsRequest),
    takeLatest(CartActionTypes.SAVE_TO_ORDER_SUCCESS_ACTION, processResetProductStatsCollection),
    takeLatest(CartActionTypes.SAVE_TO_ORDERS_SUCCESS_ACTION, processResetProductStatsCollection),
]
