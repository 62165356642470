import classNames, { Value as ClassNames } from 'classnames'
import React, { memo, useRef, useState } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import ProductWeeklyStats from '../Loadable'
import ButtonApp from '../../../../components/Buttons/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApplicationHelper from '../../../../utils/applicationHelper'

interface IProps {
    productId: string
    className?: ClassNames
    enabled?: boolean
}

function ViewButton({ productId, className, enabled = true }: IProps): JSX.Element {
    const buttonWrapperRef = useRef<HTMLDivElement | null>(null)
    const [showPopover, setShowPopover] = useState<boolean>(false)

    return (
        <>
            <Overlay target={buttonWrapperRef.current} show={showPopover}>
                {(props) => (
                    <Popover
                        {...props}
                        id={`${productId ? `${productId}-` : ''}popover-product-stats`}
                        className={classNames('product-stats-popover', className)}
                    >
                        <Popover.Content>
                            <ProductWeeklyStats productId={productId} />
                        </Popover.Content>
                    </Popover>
                )}
            </Overlay>
            <div
                className="product-stats-action"
                ref={buttonWrapperRef}
                onMouseEnter={ApplicationHelper.isTouchScreen() ? undefined : () => setShowPopover(true)}
                onMouseLeave={ApplicationHelper.isTouchScreen() ? undefined : () => setShowPopover(false)}
                onClick={(evt) => {
                    evt.preventDefault()
                    evt.stopPropagation()
                    setShowPopover((st) => !st)
                }}
            >
                <ButtonApp
                    className={'btn-sm btn-mass-add-to-cart'}
                    variant={'link'}
                    disabled={!enabled}
                    style={{ pointerEvents: enabled ? 'initial' : 'none' }}
                >
                    <FontAwesomeIcon icon={['fal', 'chart-bar']} className={'app-icon locked-icon'} />
                </ButtonApp>
            </div>
        </>
    )
}

export default memo(ViewButton)
