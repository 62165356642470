import { createSelector } from 'reselect'
import { IApplicationRootState } from '../index'
import { initialState } from './reducer'

/**
 * Direct selector to the products state domain
 */

const selectProductGridPublicsDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.productGridPublics || initialState
}

const selectProductGridPublicsListDomain = (state: IApplicationRootState) => {
    // Warning: Add your key to RootState in types/common.d.ts file
    return state.productGridPublics.list || initialState.list
}

/**
 * Other specific selectors
 */

/**
 * Default selector used by Products
 */

const makeSelectProductGridPublicsListFetching = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.fetching
    })

const makeSelectProductGridPublicsListParams = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.params
    })

const makeSelectProductGridPublicsListParamsPrev = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.paramsPrev
    })

const makeSelectProductGridPublicsListError = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.error
    })

const makeSelectProductGridPublicsListItems = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.items
    })

const makeSelectProductGridPublicsListFilters = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.filters
    })

const makeSelectProductGridPublicsListTotalItems = () =>
    createSelector(selectProductGridPublicsListDomain, (substate) => {
        return substate.totalItems
    })

export {
    selectProductGridPublicsDomain,
    makeSelectProductGridPublicsListParams,
    makeSelectProductGridPublicsListParamsPrev,
    makeSelectProductGridPublicsListItems,
    makeSelectProductGridPublicsListFetching,
    makeSelectProductGridPublicsListError,
    makeSelectProductGridPublicsListTotalItems,
    makeSelectProductGridPublicsListFilters,
}
