import React, { useCallback, useEffect, useMemo } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import ReactPlaceholder from 'react-placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import NotFoundAlert, { NotFoundReasonModes } from '../components/Products/Content/NotFoundAlert'
import FamilyTree from '../components/Products/Sidebar/Type/FamilyTree'
import Breadcrumb from '../containers/Breadcrumb/Breadcrumb'
import CmsBlock from '../containers/CmsBlock/CmsBlock'
import { default as ProductsPlaceholder } from '../containers/Products/Placeholder'
import { getPath } from '../routes'
import { formatDisplayableCategoryTree, formatDisplayableFamilyTree } from '../store/classification/utils'
import {
    ICategory,
    ICategoryTreeCollection,
    IFamilyTreeCollection,
    TreeMode,
} from '../services/api/service/classification/types'
import { CmsBlockCodeList } from '../services/api/service/cms/types'
import { ProductsListMode } from '../services/api/service/products/types'
import { IApplicationRootState } from '../store'
import { IAppErrorTypes } from '../store/app/types'
import { cartsBannerShowAction } from '../store/carts/actions'
import { classificationCategoryProcessAction, classificationCategoryResetAction } from '../store/classification/actions'
import {
    makeSelectClassificationCategory,
    makeSelectClassificationCategoryError,
    makeSelectClassificationCategoryTreeDefault,
    makeSelectClassificationFamilyTreeDefault,
} from '../store/classification/selectors'
import { ICustomer } from '../services/api/service/customers/types'
import { makeSelectCustomer } from '../store/customers/selectors'
import ProductsPage from './ProductsPage'
import CategoryTree from '../components/Products/Sidebar/Type/CategoryTree'
import classNames from 'classnames'

const stateSelector = createStructuredSelector<any, any>({
    category: makeSelectClassificationCategory(),
    categoryError: makeSelectClassificationCategoryError(),
    familyTree: makeSelectClassificationFamilyTreeDefault(),
    categoryTree: makeSelectClassificationCategoryTreeDefault(),
    customer: makeSelectCustomer(),
})

function ProductsCategoryPage(): JSX.Element {
    const { formatMessage, locale } = useIntl()
    const dispatch = useDispatch()
    const history = useHistory()
    const { categorySlug } = useParams<{
        categorySlug: string
    }>()
    const { category, familyTree, categoryTree, treeMode, categoryError, customer } = useSelector<
        IApplicationRootState,
        {
            categoryError: IAppErrorTypes
            category: ICategory
            familyTree: IFamilyTreeCollection
            categoryTree: ICategoryTreeCollection
            customer?: ICustomer
            treeMode: TreeMode
        }
    >(stateSelector)

    const basePath: string | undefined = useMemo(() => {
        return generatePath(getPath('catalog', locale), { lang: locale })
    }, [locale])

    useEffect(() => {
        dispatch(cartsBannerShowAction())
    })

    useEffect(() => {
        if (categorySlug) {
            dispatch(classificationCategoryProcessAction(categorySlug))
        } else {
            dispatch(classificationCategoryResetAction())
        }
    }, [categorySlug, dispatch])

    let seoPageTitle = formatMessage({ id: 'seo.products.title' })
    if (category) {
        seoPageTitle = category.label
    }

    const items = useMemo(() => {
        if (!category) {
            return []
        }
        return [
            {
                label: category.label,
            },
        ]
    }, [category])

    const handleGoBack = useCallback(() => {
        const productsUrl = generatePath(getPath('catalog', locale), { lang: locale })
        history.push(productsUrl)
    }, [history, locale])

    const currentFamilyTreeDisplayable = useMemo(() => {
        if (treeMode === TreeMode.Families) {
            return formatDisplayableFamilyTree(familyTree || [], customer?.has_virtual_cart)
        }

        return []
    }, [familyTree, customer, treeMode])

    const currentCategoryTreeDisplayable = useMemo(() => {
        if (treeMode === TreeMode.Categories) {
            return formatDisplayableCategoryTree(categoryTree || [], customer?.has_virtual_cart)
        }

        return []
    }, [categoryTree, customer, treeMode])

    if (category && currentFamilyTreeDisplayable) {
        return <ProductsPage category={category} mode={ProductsListMode.Category} />
    }

    return (
        <>
            <Helmet>
                <title>{seoPageTitle}</title>
                <meta
                    name="description"
                    content={formatMessage({ id: 'seo.products.description' }, { name: seoPageTitle })}
                />
            </Helmet>
            <div className={classNames('products-page', 'catalog-list-page')} id={'products-page'}>
                {category && <Breadcrumb items={items} />}
                <Container fluid>
                    <div className="catalog-list-page-container products-page-container">
                        <Row noGutters className={'catalog-list-page-content products-page-content row no-gutters'}>
                            <Col className={'col-sidebar'}>
                                <div className={'product-list-sidebar'}>
                                    {treeMode === TreeMode.Families && currentFamilyTreeDisplayable ? (
                                        <FamilyTree basePath={basePath} tree={currentFamilyTreeDisplayable} />
                                    ) : undefined}
                                    {treeMode === TreeMode.Categories && currentCategoryTreeDisplayable ? (
                                        <CategoryTree basePath={basePath} tree={currentCategoryTreeDisplayable} />
                                    ) : undefined}
                                    <CmsBlock identifier={CmsBlockCodeList.SidebarHighlight} lazy />
                                </div>
                            </Col>
                            <Col className={'col-product-list'}>
                                <ReactPlaceholder
                                    ready={typeof categoryError !== 'undefined' || typeof categorySlug === 'undefined'}
                                    customPlaceholder={<ProductsPlaceholder itemsPerPage={48} />}
                                >
                                    <>
                                        {(!categorySlug || typeof categoryError !== 'undefined') && (
                                            <NotFoundAlert
                                                mode={ProductsListMode.Category}
                                                reason={NotFoundReasonModes.UnknownCategory}
                                                onClick={handleGoBack}
                                            />
                                        )}
                                    </>
                                </ReactPlaceholder>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default ProductsCategoryPage
