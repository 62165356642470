/**
 *
 * app-slider
 *
 */
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import SwiperCore, { A11y, Autoplay, Controller, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavigationOptions } from 'swiper/types/modules/navigation'
import { PaginationOptions } from 'swiper/types/modules/pagination'
// import Swiper core and required components
import SwiperClass from 'swiper/types/swiper-class'
import {
    CmsWidgetTheme,
    CmsWidgetType,
    ICmsWidgetInteractiveSlide,
    ICmsWidgetInteractiveSlideProduct,
    ICmsWidgetInteractiveSlider,
} from '../../../../services/api/service/cms/types'
import { CmsWidgetLinkClickCallback } from '../../../../types/widget'
import isExternalRegexClosure from '../../../../utils/location'
import FlatIcon from '../../../../components/Icon/FlatIcon'
import Slide from './Slide'
import { InteractiveSlideClickCallback } from '../../../../types/interactiveSlider'
import isUndefined from 'lodash/isUndefined'
import { createStructuredSelector } from 'reselect'
import { makeSelectCustomer, makeSelectCustomerStore } from '../../../../store/customers/selectors'
import { makeSelectCartMode } from '../../../../store/carts/selectors'

import { makeSelectAuthMe } from '../../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationRootState } from '../../../../store'

import { ICustomer, ICustomerType } from '../../../../services/api/service/customers/types'
import { IMe } from '../../../../services/api/service/me/types'
import { StrictCartMode } from '../../../../store/carts/types'

import { isSalesmanResource } from '../../../../store/salesmens/utils'
import { isQuantitySelectorMultipleEnabled } from '../../../../store/carts/utils'
import { IProductDetail, IProductList } from '../../../../services/api/service/products/types'
import { productAddToFavoriteAction, productRemoveToFavoriteAction } from '../../../../store/product/actions'
import { useIntl } from 'react-intl'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { generateProductUrl } from '../../../../utils/productHelper'
import { getPath } from '../../../../routes'
import { makeSelectProductDetail } from '../../../../store/product/selectors'
import { isNil } from 'lodash'

SwiperCore.use([Controller, Navigation, Pagination, A11y, Autoplay])

interface IProps {
    widget: ICmsWidgetInteractiveSlider
    onSlideClick?: InteractiveSlideClickCallback
    onLinkClick?: CmsWidgetLinkClickCallback
}

const stateSelector = createStructuredSelector<any, any>({
    me: makeSelectAuthMe(),
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    cartMode: makeSelectCartMode(),
    productDetail: makeSelectProductDetail(),
})

function Slider({ widget, onSlideClick, onLinkClick }: IProps): JSX.Element {
    const dispatch = useDispatch()
    const { locale } = useIntl()
    const history = useHistory()
    const location = useLocation()

    const [previousSlide, setPreviousSlide] = useState<ICmsWidgetInteractiveSlide | null>(null)
    const [nextSlide, setNextSlide] = useState<ICmsWidgetInteractiveSlide | null>(null)
    const currentRef = useRef<SwiperClass | undefined>(undefined)
    const slideCount = widget.configuration.slides.length
    const activeLoop = slideCount > 1
    const showNavigation = slideCount > 1
    const showPagination = false
    const cmsIdentifierKey = `cms-widget-${widget.widget}-${widget.id}`

    // default redirect url
    const defaultUrl: string | undefined = useMemo(() => {
        return generatePath(getPath('catalog', locale), { lang: locale })
    }, [locale])

    // redux
    const { customer, cartMode, store, me, productDetail } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            store?: ICustomer
            me: IMe
            cartMode: StrictCartMode
            productDetail?: IProductDetail
        }
    >(stateSelector)

    // est-ce que la personne connectée est un commercial ?
    const isSalesman = useMemo(() => {
        return isSalesmanResource(me)
    }, [me])

    const isQtySelectorMultiple = useMemo(() => {
        return isQuantitySelectorMultipleEnabled(customer, store, cartMode)
    }, [customer, store, cartMode])

    const handleProductFavorite = useCallback(
        (product: IProductList, productFavorite: boolean) => {
            if (productFavorite) {
                dispatch(productAddToFavoriteAction(product.id))
            } else {
                dispatch(productRemoveToFavoriteAction(product.id))
            }
        },
        [dispatch]
    )

    const handleOnSlideClick = useCallback(
        (slide: ICmsWidgetInteractiveSlide, product?: ICmsWidgetInteractiveSlideProduct) => {
            if (product) {
                history.push(generateProductUrl(product.id, locale), { background: location })
            }

            if (onSlideClick) {
                onSlideClick(slide, product)
            }

            if (onLinkClick && isUndefined(product)) {
                const url = slide.link?.url || defaultUrl

                onLinkClick({
                    widget: CmsWidgetType.InteractiveSlide,
                    url,
                    text: slide.title,
                    internal: slide.link?.internal || isExternalRegexClosure(url),
                    blank: slide.link?.blank || false,
                    extra: slide,
                })
            }
        },
        [onSlideClick, onLinkClick, locale, location, history, defaultUrl]
    )

    const handleSlideInit = useCallback(() => {
        if (slideCount > 1) {
            setPreviousSlide(widget.configuration.slides[slideCount - 1])
            setNextSlide(widget.configuration.slides[1])
        }
    }, [widget, setPreviousSlide, setNextSlide, slideCount])

    const handleSlideChange = useCallback(() => {
        const currentInstance = currentRef.current
        if (!currentInstance) {
            return
        }
        const realIndex = currentInstance.realIndex
        setPreviousSlide(
            widget.configuration.slides[realIndex - 1]
                ? widget.configuration.slides[realIndex - 1]
                : widget.configuration.slides[widget.configuration.slides.length - 1]
        )
        setNextSlide(
            widget.configuration.slides[realIndex + 1]
                ? widget.configuration.slides[realIndex + 1]
                : widget.configuration.slides[0]
        )
    }, [widget, setPreviousSlide, setNextSlide, currentRef])

    const navOptions: NavigationOptions | false = useMemo(() => {
        if (slideCount <= 1 || !showNavigation) {
            return false
        }
        return {
            prevEl: `.swiper-app-slider-prev`,
            nextEl: `.swiper-app-slider-next`,
        }
    }, [slideCount, showNavigation])

    const paginationOptions: PaginationOptions | false = useMemo(() => {
        if (!showNavigation || !showPagination) {
            return false
        }
        return {
            clickable: true,
            el: `.swiper-pagination`,
            type: 'bullets',
        }
    }, [showNavigation, showPagination])

    const autoplayOptions = useMemo(() => {
        return { delay: 5000, disableOnInteraction: true }
    }, [])

    useEffect(() => {
        const currentInstance = currentRef.current
        if (!currentInstance) {
            return
        }

        if (isNil(productDetail)) {
            currentInstance.autoplay.start()
        } else {
            currentInstance.autoplay.stop()
        }
    }, [currentRef, productDetail])

    if (widget.configuration.slides.length === 0) {
        return <></>
    }

    return (
        <div
            id={cmsIdentifierKey}
            className={classNames(
                'cms-widget',
                `cms-widget-${widget.widget}`,
                'cms-widget-app-slider',
                `cms-widget-theme-${widget.widget_theme || CmsWidgetTheme.Default}`,
                { 'app-slider-slide-full-width': slideCount === 1 },
                widget.css_class
            )}
        >
            <div className={'cms-widget-inner'}>
                <Swiper
                    navigation={navOptions}
                    pagination={paginationOptions}
                    onSwiper={(instance) => {
                        currentRef.current = instance
                    }}
                    onInit={handleSlideInit}
                    onSlideChange={handleSlideChange}
                    slidesPerView={'auto'}
                    slidesPerGroup={1}
                    spaceBetween={30}
                    autoplay={autoplayOptions}
                    loop={activeLoop}
                    centeredSlides={slideCount > 1}
                >
                    {widget.configuration.slides.map((item: ICmsWidgetInteractiveSlide, index: number) => {
                        return (
                            <SwiperSlide key={`interactive_slide_${index}`}>
                                <Slide
                                    slide={item}
                                    ready={true}
                                    onClick={handleOnSlideClick}
                                    onProductFavorite={handleProductFavorite}
                                    showPrices={!customer.hide_prices}
                                    showGeneralPriceBadge={customer.general_price}
                                    showProductSalesmanInfo={isSalesman}
                                    quantitySelectorMultiple={isQtySelectorMultiple}
                                    bypassCartLocker={customer.account_type === ICustomerType.MultiStore}
                                />
                            </SwiperSlide>
                        )
                    })}
                    <div slot="container-end">
                        {showNavigation && (
                            <Container className={'app-slider-navigation'}>
                                <button
                                    className={classNames('swiper-button-prev', 'swiper-app-slider-prev', {
                                        disabled: !previousSlide,
                                    })}
                                >
                                    <span>{previousSlide?.title}</span>
                                    <FlatIcon icon={'arrow-left'} />
                                </button>
                                <button
                                    className={classNames('swiper-button-next', 'swiper-app-slider-next', {
                                        disabled: !nextSlide,
                                    })}
                                >
                                    <span>{nextSlide?.title}</span>
                                    <FlatIcon icon={'arrow-right'} />
                                </button>
                                <div className="swiper-pagination" />
                            </Container>
                        )}
                    </div>
                </Swiper>
            </div>
        </div>
    )
}

Slider.defaultProps = {} as Partial<IProps>

export default Slider
