/*
 *
 * CustomerSalesmen
 *
 */

import { Value as ClassValue } from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import Card from '../../components/Salesmen/Card/Card'
import { ICustomer } from '../../services/api/service/customers/types'
import { ICustomerSalesmenCollection } from '../../services/api/service/salesman/types'
import { IApplicationRootState } from '../../store'
import {
    makeSelectCustomerSalesmenItems,
    makeSelectCustomer,
    makeSelectCustomerStore,
} from '../../store/customers/selectors'

interface IProps {
    className?: ClassValue
}

const stateSelector = createStructuredSelector<any, any>({
    customer: makeSelectCustomer(),
    store: makeSelectCustomerStore(),
    salesmen: makeSelectCustomerSalesmenItems(),
})

function CustomerSalesmenCard({ className }: IProps): JSX.Element {
    const { customer, store, salesmen } = useSelector<
        IApplicationRootState,
        {
            customer: ICustomer
            store?: ICustomer
            salesmen?: ICustomerSalesmenCollection
        }
    >(stateSelector)

    if (!salesmen || Object.values(salesmen).length === 0) {
        return <></>
    }

    return <Card className={className} salesmen={salesmen} customer={customer} store={store} />
}

export default CustomerSalesmenCard
